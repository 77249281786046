import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import Cart from "components/client/cart/Cart";
import { useTranslation } from "react-i18next";
import { CartCount, CartData } from "helpers/ClientHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";

const CartIcon = ({
  customer,
  customerPath,
  style,
  className,
  iconStyle,
  iconWrapperStyle,
}) => {
  const { data, refetch } = CartData(customer.id);
  const [showCartModal, setShowCartModal] = useState(false);
  const [cartHover, setCartHover] = useState(false);
  const [modalHover, setModalHover] = useState(false);
  const cartItemCount = CartCount(customer.id);
  const [goto, setGoto] = useState();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const lastUrlSegment = location.pathname.split("/").pop();

  const unitPrice = process.env.REACT_APP_UNIT_PRICE
    ? process.env.REACT_APP_UNIT_PRICE
    : "$";

  useEffect(() => {
    if (goto) navigate(goto);
  }, [goto, navigate]);

  return (
    <>
      <Link
        onClick={() => {
          setShowCartModal(false);
          setCartHover(false);
        }}
        key={`/${customerPath}/cart`}
        to={`/${customerPath}/cart`}
        className={className}
        style={style}
        onMouseEnter={() => {
          setCartHover(true);
          if (!["cart", "order"].includes(lastUrlSegment))
            setTimeout(() => {
              setShowCartModal(true);
            }, 500);
        }}
        onMouseLeave={() => {
          setCartHover(false);
        }}
      >
        <span
          style={iconWrapperStyle}
          className="d-inline-flex align-items-center justify-content-center"
        >
          <FontAwesomeIcon icon={faShoppingCart} style={iconStyle} />
        </span>{" "}
        {typeof cartItemCount === "number" ? cartItemCount : 0}
      </Link>
      <Modal
        size="xl"
        show={showCartModal}
        onHide={() => {
          if (!cartHover && !modalHover) setShowCartModal(false);
        }}
        onMouseEnter={() => {
          setModalHover(true);
          setShowCartModal(true);
        }}
        onMouseLeave={() => {
          setModalHover(false);
          if (!cartHover) setShowCartModal(false);
        }}
      >
        <Modal.Body>
          <Cart
            onClick={() => setGoto("/" + customerPath + "/cart")}
            title={
              <Link to={`/${customerPath}/cart`}>
                <h4 className="text-start">{t("Your shopping cart")}</h4>
              </Link>
            }
            cart={data?.data}
            unitPrice={unitPrice}
            refetch={() => refetch()}
            customer={customer}
            customerPath={customerPath}
            showGoBack={false}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CartIcon;
