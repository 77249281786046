import Joyride from "react-joyride";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ClientView } from "views/client/Client";
import { useEffect, useRef, useState } from "react";
import OrderSelect from "components/client/OrderSelect";
import { Products, UserData } from "helpers/ClientHelper";
import { LoadingSpinner } from "components/LoadingSpinner";
import { joyrideTranslate } from "helpers/functionsHelper";
import { CatalogeList } from "components/client/CatalogeList";
import CatalogeCarousel from "components/carousel/CatalogeCarousel";
import CatalogePaginator from "components/client/CatalogePaginator";
import { apiRequestUri, getCookie, setCookie } from "helpers/helper";

export const Cataloge = ({ customer, customerPath }) => {
  const perPage = 9;

  const [lastPage, setLastPage] = useState(1);
  const [page, setPage] = useState(1);
  const [images, setImages] = useState([]);
  const [orderBy, setOrderBy] = useState("featured");
  const [orderDirection, setOrderDirection] = useState("desc");
  const [search, setSearch] = useState();
  const [runOnboarding, setRunOnboarding] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const { data: currentUser, refetch } = UserData();
  const { t } = useTranslation();
  const toggleRef = useRef();

  let steps = [
    {
      target: ".first-cataloge-step",
      content: t("By clicking this button all products will be displayed."),
      disableBeacon: true,
    },
    {
      target: ".second-cataloge-step",
      content: t(
        "In this text box you can enter the name of the product you want."
      ),
    },
    {
      target: ".third-cataloge-step",
      content: t("From here you can see the products you have chosen to buy."),
    },
    {
      target:
        toggleRef?.current?.getBoundingClientRect()?.top === 0
          ? ".fourth-cataloge-step"
          : ".navbar-toggler",
      content:
        toggleRef?.current?.getBoundingClientRect()?.top === 0
          ? t(
              "Here you can see the product categories and select a particular one."
            )
          : t(
              "Here you will find a menu with products (categories), our About Us section and the option to log in to make inquiries and purchases."
            ),
    },
  ];

  if (toggleRef?.current?.getBoundingClientRect()?.top === 0) {
    steps.push({
      target: ".fifth-cataloge-step",
      content: t("Here you will find information about us."),
    });
    steps.push({
      target: ".sixth-cataloge-step",
      content: loggedIn
        ? t(
            "Here you can see your profile, your purchases, your configuration and log out."
          )
        : t("To purchase you need to log in here."),
    });
  }
  steps.push({
    target: ".seventh-cataloge-step",
    content: t("Here you can select how you want to order the products."),
  });
  steps.push({
    target: ".eighth-cataloge-step",
    content: t("Here you can see the product information and choose to buy."),
  });

  if (toggleRef?.current?.getBoundingClientRect()?.top === 0) {
    steps.push({
      target: ".ninth-cataloge-step-lg",
      content: t("Here you can browse through the different product pages."),
    });
  } else {
    steps.push({
      target: ".ninth-cataloge-step-sm",
      content: t("Here you can browse through the different product pages."),
    });
  }

  const {
    data: products,
    isFetching,
    isLoading,
    isRefetching,
  } = Products(customer.id, page, perPage, orderBy, orderDirection, search);

  useEffect(() => {
    if (products) {
      setLastPage(products.meta.last_page);
      const catalogeOnboardingCookie = getCookie("catalogeOnboarding");
      if (
        !currentUser?.data?.data?.catalogeOnboarding &&
        !catalogeOnboardingCookie
      )
        setRunOnboarding(true);
    }
    if (currentUser && currentUser.data && currentUser.data.name) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
    if (customer && customer.data && customer.data.carousel) {
      setImages(customer.data.carousel);
    }
  }, [products, customer, customerPath, currentUser, toggleRef]);

  const handlePage = (page) => {
    setPage(page);
  };

  const handleSearch = (search) => {
    setPage(1);
    setSearch(search);
  };

  const handleOrder = (e) => {
    setPage(1);
    switch (e.target.value) {
      case "featured":
        setOrderBy("featured");
        setOrderDirection("desc");
        break;
      case "lowerPrice":
        setOrderBy("price");
        setOrderDirection("asc");
        break;
      case "higherPrice":
        setOrderBy("price");
        setOrderDirection("desc");
        break;

      default:
        setOrderBy("featured");
        setOrderDirection("desc");
        break;
    }
  };

  const handleStartTour = () => {
    setRunOnboarding(true);
  };

  const handleSkip = (e) => {
    if (["skip", "reset", "close"].includes(e.action)) {
      setRunOnboarding(false);
      if (currentUser) {
        const target = {
          method: "PUT",
          uri: "user/" + (currentUser ? currentUser.data.id : ""),
        };
        let newData = currentUser.data.data;
        if (!newData) newData = {};
        newData["catalogeOnboarding"] = 1;
        apiRequestUri(target.uri, target.method, { data: newData }, false)
          .then(() => {
            refetch();
          })
          .catch();
      } else {
        setCookie("catalogeOnboarding", 1);
      }
    }
  };

  return (
    <ClientView
      customer={customer}
      customerPath={customerPath}
      handleSearch={handleSearch}
      toggleRef={toggleRef}
    >
      <Joyride
        hideCloseButton
        locale={joyrideTranslate(t)}
        continuous
        run={runOnboarding}
        scrollToFirstStep
        showProgress
        showSkipButton
        steps={steps}
        callback={handleSkip}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      <CatalogeCarousel images={images} />
      <Container className="text-center mt-1 mb-1">
        <OrderSelect
          handleOrder={handleOrder}
          handleStartTour={handleStartTour}
        />
        {isFetching || isLoading || isRefetching ? (
          <LoadingSpinner />
        ) : (
          <CatalogeList
            products={products?.data}
            customer={customer}
            customerPath={customerPath}
          />
        )}
        <CatalogePaginator
          page={page}
          lastPage={lastPage}
          handlePage={handlePage}
          customer={customer}
        />
      </Container>
    </ClientView>
  );
};
