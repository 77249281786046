import {
  faCog,
  faBook,
  faList,
  faTable,
  faUsers,
  faTruck,
  faImages,
  faDollar,
  faSquarePen,
  faMoneyBill,
  faShareNodes,
  faAddressBook,
  faNetworkWired,
  faBorderTopLeft,
  faFileCircleQuestion,
} from "@fortawesome/free-solid-svg-icons";

export const AdminData = {
  sidebarLinks: [
    // { to: "/admin/dashboard", icon: "columns", text: "Dashboard" , target: ""},
    { to: "/admin/product", icon: faTable, text: "Products", target: "" },
    { to: "/admin/category", icon: faList, text: "Categories", target: "" },
    {
      to: "/admin/question",
      icon: faFileCircleQuestion,
      text: "Questions",
      target: "",
    },
    { to: "/admin/order", icon: faBook, text: "Orders", target: "" },
    {
      to: [
        {
          to: "/admin/config/carousel",
          icon: faImages,
          text: "Carousel",
          target: "",
        },
        {
          to: "/admin/config/address",
          icon: faAddressBook,
          text: "Addresses",
          target: "",
        },
        {
          to: "/admin/config/payment_methods",
          icon: faDollar,
          text: "Payment Methods",
          target: "",
        },
        {
          to: "/admin/config/shipment",
          icon: faTruck,
          text: "Shipment",
          target: "",
        },
        {
          to: "/admin/config/contact",
          icon: faNetworkWired,
          text: "Contact & Networks",
          target: "",
        },
        {
          to: "/admin/config/seo",
          icon: faShareNodes,
          text: "SEO Configuration",
          target: "",
        },
        {
          to: "/admin/config/about",
          icon: faUsers,
          text: "About Section",
          target: "",
        },
        {
          to: "/admin/config/style",
          icon: faSquarePen,
          text: "Brand & Styles",
          target: "",
        },
        {
          to: "/admin/config/themes",
          icon: faBorderTopLeft,
          text: "Themes",
          target: "",
        },
      ],
      icon: faCog,
      text: "Configuration",
      target: "",
    },
    // { to: "/admin/hero404", icon: "exclamation-circle", text: "404 page", target: "_blank"},
  ],
  adminSidebarLinks: [
    { to: "/admin/user", icon: faUsers, text: "Users", target: "" },
    { to: "/admin/payment", icon: faMoneyBill, text: "Payments", target: "" },
  ],
  breadLinks: {
    "/admin/profile": [{ text: "Profile", to: "/admin/profile", active: true }],

    "/admin/verify": [{ text: "Verify", to: "/admin/verify", active: true }],

    "/admin/password/change": [
      { text: "Profile", to: "/admin/profile", active: false },
      { text: "Change Password", to: "/admin/password/change", active: true },
    ],

    "/admin/name": [
      { text: "Profile", to: "/admin/profile", active: false },
      { text: "Change Name", to: "/admin/name", active: true },
    ],

    "/admin/config/payment_methods": [
      {
        text: "Payment Methods",
        to: "/admin/config/payment_methods",
        active: true,
      },
    ],
    "/admin/config/payment_methods/create": [
      {
        text: "Payment Methods",
        to: "/admin/config/payment_methods",
        active: false,
      },
      { text: "Add", to: "/admin/config/payment_methods/create", active: true },
    ],
    "/admin/config/payment_methods/edit/:id": [
      {
        text: "Payment Methods",
        to: "/admin/config/payment_methods",
        active: false,
      },
      {
        text: "Edit",
        to: (id) => {
          return "/admin/config/payment_methods/edit/" + id;
        },
        active: true,
      },
    ],

    "/admin/config/shipment": [
      {
        text: "Shipment",
        to: "/admin/config/shipment_methods",
        active: true,
      },
    ],

    "/admin/config/carousel": [
      { text: "Carousel", to: "/admin/config/carousel", active: true },
    ],
    "/admin/config/carousel/create": [
      { text: "Carousel", to: "/admin/config/carousel", active: false },
      { text: "Create", to: "/admin/config/carousel/create", active: true },
    ],
    "/admin/config/carousel/edit/:id": [
      { text: "Carousel", to: "/admin/config/carousel", active: false },
      {
        text: "Edit",
        to: (id) => {
          return "/admin/config/carousel/edit/" + id;
        },
        active: true,
      },
    ],
    "/admin/config/address": [
      { text: "Address", to: "/admin/config/address", active: true },
    ],
    "/admin/config/about": [
      { text: "About", to: "/admin/config/about", active: true },
    ],
    "/admin/config/seo": [
      { text: "SEO", to: "/admin/config/seo", active: true },
    ],
    "/admin/config/address/create": [
      { text: "Address", to: "/admin/config/address", active: false },
      { text: "Create", to: "/admin/config/address/create", active: true },
    ],
    "/admin/config/contact": [
      { text: "Contact", to: "/admin/config/contact", active: true },
    ],
    "/admin/config/contact/create": [
      { text: "Contact", to: "/admin/config/contact", active: false },
      { text: "Create", to: "/admin/config/contact/create", active: true },
    ],
    "/admin/config/contact/edit": [
      { text: "Contact", to: "/admin/config/contact", active: false },
      {
        text: "Edit",
        to: (id) => {
          return "/admin/config/contact/edit/" + id;
        },
        active: true,
      },
    ],
    "/admin/config/style": [
      { text: "Style", to: "/admin/config/style", active: true },
    ],
    "/admin/config/themes": [
      { text: "Themes", to: "/admin/config/themes", active: true },
    ],

    "/admin/product": [
      { text: "Products", to: "/admin/product", active: true },
    ],
    "/admin/product/:id": [
      { text: "Products", to: "/admin/product", active: false },
      {
        text: "Product",
        to: (id) => {
          return "/admin/product/" + id;
        },
        active: true,
      },
      {
        text: "Images",
        to: (id) => {
          return "/admin/product/image/" + id;
        },
        active: false,
      },
      {
        text: "Documents",
        to: (id) => {
          return "/admin/product/document/" + id;
        },
        active: false,
      },
    ],
    "/admin/product/create": [
      { text: "Products", to: "/admin/product", active: false },
      { text: "Create", to: "/admin/product/create", active: true },
    ],
    "/admin/product/edit/:id": [
      { text: "Products", to: "/admin/product", active: false },
      {
        text: "Product",
        to: (id) => {
          return "/admin/product/" + id;
        },
        active: false,
      },
      {
        text: "Edit",
        to: (id) => {
          return "/admin/product/edit/" + id;
        },
        active: true,
      },
    ],
    "/admin/product/image/:id": [
      { text: "Products", to: "/admin/product", active: false },
      {
        text: "Product",
        to: (id) => {
          return "/admin/product/" + id;
        },
        active: false,
      },
      {
        text: "Images",
        to: (id) => {
          return "/admin/product/image/" + id;
        },
        active: true,
      },
      {
        text: "Documents",
        to: (id) => {
          return "/admin/product/document/" + id;
        },
        active: false,
      },
    ],
    "/admin/product/document/:id": [
      { text: "Products", to: "/admin/product", active: false },
      {
        text: "Product",
        to: (id) => {
          return "/admin/product/" + id;
        },
        active: false,
      },
      {
        text: "Images",
        to: (id) => {
          return "/admin/product/image/" + id;
        },
        active: false,
      },
      {
        text: "Documents",
        to: (id) => {
          return "/admin/product/document/" + id;
        },
        active: true,
      },
    ],

    "/admin/category": [
      { text: "Categories", to: "/admin/category", active: true },
    ],
    "/admin/category/:id": [
      { text: "Categories", to: "/admin/category", active: false },
      {
        text: "Category",
        to: (id) => {
          return "/admin/category/" + id;
        },
        active: true,
      },
      {
        text: "Images",
        to: (id) => {
          return "/admin/category/image/" + id;
        },
        active: false,
      },
    ],
    "/admin/category/create": [
      { text: "Categories", to: "/admin/category", active: false },
      { text: "Create", to: "/admin/category/create", active: true },
    ],
    "/admin/category/edit/:id": [
      { text: "Categories", to: "/admin/category", active: false },
      {
        text: "Category",
        to: (id) => {
          return "/admin/category/" + id;
        },
        active: false,
      },
      {
        text: "Edit",
        to: (id) => {
          return "/admin/category/edit/" + id;
        },
        active: true,
      },
    ],
    "/admin/category/image/:id": [
      { text: "Categories", to: "/admin/category", active: false },
      {
        text: "Category",
        to: (id) => {
          return "/admin/category/" + id;
        },
        active: false,
      },
      {
        text: "Images",
        to: (id) => {
          return "/admin/category/image/" + id;
        },
        active: true,
      },
    ],

    "/admin/order": [{ text: "Orders", to: "/admin/order", active: true }],
    "/admin/order/:id": [
      { text: "Orders", to: "/admin/order", active: false },
      {
        text: "Order",
        to: (id) => {
          return "/admin/order/" + id;
        },
        active: true,
      },
    ],
    "/admin/order/edit/:id": [
      { text: "Orders", to: "/admin/order", active: false },
      {
        text: "Order",
        to: (id) => {
          return "/admin/order/" + id;
        },
        active: false,
      },
      {
        text: "Edit",
        to: (id) => {
          return "/admin/order/edit/" + id;
        },
        active: true,
      },
    ],

    "/admin/question": [
      { text: "Questions", to: "/admin/question", active: true },
    ],
    "/admin/question/:id": [
      { text: "Questions", to: "/admin/question", active: false },
      {
        text: "Question",
        to: (id) => {
          return "/admin/question/" + id;
        },
        active: true,
      },
    ],
    "/admin/question/edit/:id": [
      { text: "Questions", to: "/admin/question", active: false },
      {
        text: "Question",
        to: (id) => {
          return "/admin/question/" + id;
        },
        active: false,
      },
      {
        text: "Edit",
        to: (id) => {
          return "/admin/question/edit/" + id;
        },
        active: true,
      },
    ],

    "/admin/user": [{ text: "Users", to: "/admin/user", active: true }],
    "/admin/user/:id": [
      { text: "Users", to: "/admin/user", active: false },
      {
        text: "User",
        to: (id) => {
          return "/admin/user/" + id;
        },
        active: true,
      },
    ],
    "/admin/user/edit/:id": [
      { text: "Users", to: "/admin/user", active: false },
      {
        text: "User",
        to: (id) => {
          return "/admin/user/" + id;
        },
        active: false,
      },
      {
        text: "Edit",
        to: (id) => {
          return "/admin/user/edit/" + id;
        },
        active: true,
      },
    ],

    "/admin/users": [{ text: "Users", to: "/admin/user", active: true }],
    "/admin/user/complain/:id": [
      { text: "Users", to: "/admin/user", active: false },
      {
        text: "Complain",
        to: (id) => {
          return "/admin/user/complain/" + id;
        },
        active: true,
      },
    ],

    "/admin/payment": [
      { text: "Payments", to: "/admin/payment", active: true },
    ],
    "/admin/payment/:id": [
      { text: "Payments", to: "/admin/payment", active: false },
      {
        text: "Payment",
        to: (id) => {
          return "/admin/payment/" + id;
        },
        active: true,
      },
      {
        text: "Documents",
        to: (id) => {
          return "/admin/payment/document/" + id;
        },
        active: false,
      },
    ],
    "/admin/payment/edit/:id": [
      { text: "Payments", to: "/admin/payment", active: false },
      {
        text: "Payment",
        to: (id) => {
          return "/admin/payment/" + id;
        },
        active: false,
      },
      {
        text: "Edit",
        to: (id) => {
          return "/admin/payment/edit/" + id;
        },
        active: true,
      },
    ],
    "/admin/payment/document/:id": [
      { text: "Payments", to: "/admin/payment", active: false },
      {
        text: "Payment",
        to: (id) => {
          return "/admin/payment/" + id;
        },
        active: false,
      },
      {
        text: "Documents",
        to: (id) => {
          return "/admin/payment/document/" + id;
        },
        active: true,
      },
    ],
  },
};
