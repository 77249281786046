import React from "react";
import { Link } from "react-router-dom";
import {
  faWhatsapp,
  faFacebook,
  faInstagram,
  faTiktok,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";
import styles from "./header/header.module.css";
import { isLightColor } from "helpers/functionsHelper";
import { Container, Row, Col, Stack } from "react-bootstrap";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Image } from "components/Image";

const Footer = (props) => {
  const { t } = useTranslation();
  let customer = props.children;
  const customerPath = props.customerPath;
  const textColor = props.textColor;
  const backgroundColor = props.backgroundColor
    ? props.backgroundColor
    : "black";
  let contact;
  if (customer.data && customer.data.contact) {
    contact = customer.data.contact;
  }

  const isTextColorLight = isLightColor(textColor);

  const textShadowStyle = {
    textShadow: isTextColorLight
      ? "1px 1px 1.5px #000000"
      : "1px 1px 1.5px #ffffff",
  };

  const linkStyle = {
    color: textColor,
    backgroundColor: isTextColorLight ? "black" : "white",
    padding: "5px",
    borderRadius: "5px",
    ...textShadowStyle,
  };

  const iconWrapperStyle = {
    backgroundColor: isTextColorLight ? "black" : "white",
    height: "36px",
    padding: "10px",
    borderRadius: "50%",
    display: "inline-block",
  };

  const iconStyle = {
    fontSize: "1.2em",
    color: textColor,
  };

  const brand = customerPath
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  let brandContent = brand || t("Brand");
  if (
    customer.data &&
    customer.data.style &&
    customer.data.style.logo &&
    customer.data.style.logo !== ""
  ) {
    brandContent = (
      <span>
        <Image
          src={customer.data.style.logo}
          alt={brandContent}
          className={`${styles.brandImage}`}
          loading="lazy"
        />
        {customer.data.style.showbrand ? brandContent : ""}
      </span>
    );
  }

  return (
    <footer
      id="footer"
      className="py-0 my-0"
      style={{
        color: textColor,
        backgroundColor: backgroundColor,
        height: "15vh",
        minHeight: "90px",
      }}
    >
      {/* Medium Device Only */}
      <Container
        fluid
        style={{ backgroundColor: backgroundColor }}
        className="d-none d-sm-block pt-1"
      >
        {contact && (
          <>
            <Row style={{ lineHeight: 0 }}>
              <Col className="mb-3">
                <Stack gap={2}>
                  <h2 className="fw-bold" style={textShadowStyle}>
                    {brandContent}
                  </h2>
                  <p style={textShadowStyle}>
                    {contact.footer ? contact.footer : ""}
                  </p>
                </Stack>
              </Col>
              <Col className="mb-3">
                <p style={textShadowStyle}>
                  <a
                    href={`mailto:${contact.email ? contact.email : ""}`}
                    className="link-underline link-underline-opacity-0"
                  >
                    <span style={iconWrapperStyle}>
                      <FontAwesomeIcon icon={faEnvelope} style={iconStyle} />
                    </span>
                    <span style={linkStyle}>
                      {contact.email ? contact.email : ""}
                    </span>
                  </a>
                </p>
              </Col>
            </Row>
            <Row>
              <Col className="mb-3">
                <Row className="list-unstyled">
                  {contact.whatsapp && (
                    <Col>
                      <a
                        href={contact.whatsapp}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={iconWrapperStyle}
                      >
                        <FontAwesomeIcon icon={faWhatsapp} style={iconStyle} />
                      </a>
                    </Col>
                  )}
                  {contact.twitter && (
                    <Col>
                      <a
                        href={contact.twitter}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={iconWrapperStyle}
                      >
                        <FontAwesomeIcon icon={faXTwitter} style={iconStyle} />
                      </a>
                    </Col>
                  )}
                  {contact.facebook && (
                    <Col>
                      <a
                        href={contact.facebook}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={iconWrapperStyle}
                      >
                        <FontAwesomeIcon icon={faFacebook} style={iconStyle} />
                      </a>
                    </Col>
                  )}
                  {contact.instagram && (
                    <Col>
                      <a
                        href={contact.instagram}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={iconWrapperStyle}
                      >
                        <FontAwesomeIcon icon={faInstagram} style={iconStyle} />
                      </a>
                    </Col>
                  )}
                  {contact.tiktok && (
                    <Col>
                      <a
                        href={contact.tiktok}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={iconWrapperStyle}
                      >
                        <FontAwesomeIcon icon={faTiktok} style={iconStyle} />
                      </a>
                    </Col>
                  )}
                </Row>
              </Col>
              <Col className="mt-3 text-end">
                <div className="hstack gap-4">
                  <Link
                    className="ms-auto"
                    to={"/" + customerPath + "/policies"}
                  >
                    {t("privacy policy")}
                  </Link>
                  <Link to={"/" + customerPath + "/terms"}>
                    {t("terms of use")}
                  </Link>
                  <Link to={"/" + customerPath + "/refund"}>
                    {t("refund policy")}
                  </Link>
                </div>
              </Col>
            </Row>
          </>
        )}
      </Container>

      {/* Small Device Only */}
      <Container
        style={{ backgroundColor: backgroundColor }}
        className="d-block d-sm-none pt-1"
      >
        {contact && (
          <>
            <Row style={{ lineHeight: 0 }}>
              <Col xs={12} md={6} className="mb-0">
                <Stack gap={2}>
                  <h4 className="fw-bold" style={textShadowStyle}>
                    {brandContent}
                  </h4>
                  <p style={textShadowStyle}>
                    {contact.footer ? contact.footer : ""}
                  </p>
                </Stack>
              </Col>
              <Col xs={12} md={6} className="mb-0">
                <p style={textShadowStyle} className="mb-0">
                  <a
                    href={`mailto:${contact.email ? contact.email : ""}`}
                    className="link-underline link-underline-opacity-0"
                  >
                    <span style={iconWrapperStyle}>
                      <FontAwesomeIcon icon={faEnvelope} style={iconStyle} />
                    </span>
                    <span style={linkStyle}>
                      {contact.email ? contact.email : ""}
                    </span>
                  </a>
                </p>
              </Col>
            </Row>
            <Row className="list-unstyled">
              {contact.whatsapp && (
                <Col>
                  <a
                    href={contact.whatsapp}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={iconWrapperStyle}
                  >
                    <FontAwesomeIcon icon={faWhatsapp} style={iconStyle} />
                  </a>
                </Col>
              )}
              {contact.twitter && (
                <Col>
                  <a
                    href={contact.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={iconWrapperStyle}
                  >
                    <FontAwesomeIcon icon={faXTwitter} style={iconStyle} />
                  </a>
                </Col>
              )}
              {contact.facebook && (
                <Col>
                  <a
                    href={contact.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={iconWrapperStyle}
                  >
                    <FontAwesomeIcon icon={faFacebook} style={iconStyle} />
                  </a>
                </Col>
              )}
              {contact.instagram && (
                <Col>
                  <a
                    href={contact.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={iconWrapperStyle}
                  >
                    <FontAwesomeIcon icon={faInstagram} style={iconStyle} />
                  </a>
                </Col>
              )}
              {contact.tiktok && (
                <Col>
                  <a
                    href={contact.tiktok}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={iconWrapperStyle}
                  >
                    <FontAwesomeIcon icon={faTiktok} style={iconStyle} />
                  </a>
                </Col>
              )}
            </Row>
            <Row className="mt-3">
              <Col>
                <Link to={"/" + customerPath + "/policies"}>
                  {t("privacy policy")}
                </Link>
              </Col>
              <Col>
                <Link to={"/" + customerPath + "/terms"}>
                  {t("terms of use")}
                </Link>
              </Col>
            </Row>
            <Row>
              <Link to={"/" + customerPath + "/refund"}>
                {t("refund policy")}
              </Link>
            </Row>
          </>
        )}
      </Container>
    </footer>
  );
};

export default Footer;
