import { PageItem, Pagination } from "react-bootstrap";

export default function CatalogePaginator({
  page,
  lastPage,
  handlePage,
  customer,
}) {
  const paginationButtonColor =
    customer.data && customer.data.style
      ? customer.data.style.paginationbuttoncolor
      : null;
  const paginationcolor =
    customer.data && customer.data.style
      ? customer.data.style.paginationcolor
      : null;
  const paginationItems = [];
  paginationItems.push(
    <Pagination.First
      key="first"
      onClick={() => handlePage(1)}
      disabled={page === 1}
    />
  );
  paginationItems.push(
    <Pagination.Prev
      key="prev"
      onClick={() => handlePage(page - 1)}
      disabled={page === 1}
    />
  );
  for (let number = 1; number <= lastPage; number++) {
    if (
      number === 1 ||
      number === lastPage ||
      (number >= page - 2 && number <= page + 2)
    ) {
      let numberColor = number === page ? "white" : "black";
      paginationItems.push(
        <PageItem
          key={number}
          onClick={() => handlePage(number)}
          active={number === page}
          linkStyle={
            paginationButtonColor
              ? { backgroundColor: paginationButtonColor, color: numberColor }
              : {}
          }
        >
          {number}
        </PageItem>
      );
    } else if (
      number === 2 ||
      number === lastPage - 1 ||
      number === page - 3 ||
      number === page + 3
    ) {
      paginationItems.push(<Pagination.Ellipsis key={`ellipsis-${number}`} />);
    }
  }
  paginationItems.push(
    <Pagination.Next
      key="next"
      onClick={() => handlePage(page + 1)}
      disabled={page === lastPage}
      linkStyle={paginationcolor ? { color: paginationcolor } : {}}
    />
  );
  paginationItems.push(
    <Pagination.Last
      key="last"
      onClick={() => handlePage(lastPage)}
      disabled={page === lastPage}
      linkStyle={paginationcolor ? { color: paginationcolor } : {}}
    />
  );

  return (
    <div className="d-flex justify-content-center">
      <Pagination className="d-none d-sm-flex ninth-cataloge-step-lg" size="lg">
        {paginationItems}
      </Pagination>
      <Pagination className="d-flex d-sm-none ninth-cataloge-step-sm" size="sm">
        {paginationItems}
      </Pagination>
    </div>
  );
}
