import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CartItem from "components/client/cart/CartItem";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { LoadingSpinner } from "components/LoadingSpinner";

const Cart = ({
  title,
  cart,
  unitPrice,
  customer,
  customerPath,
  refetch,
  className,
  toAddPrice,
  showGoBack = true,
  showButtons = true,
  handleStartTour,
}) => {
  const { t } = useTranslation();

  const renderItems = () => {
    if (!cart?.cart) {
      return;
    }
    let col1 = [];
    let col2 = [];
    let count = 0;
    if (cart?.cart?.length === 1) {
      return (
        <Row>
          <Col className={`${className} h-100`}>
            <CartItem
              item={cart?.cart[0]}
              key={cart?.cart[0].id}
              refetch={refetch}
              customer={customer}
              customerPath={customerPath}
            />
          </Col>
        </Row>
      );
    } else {
      if (cart && cart.cart)
        for (const lineItem of cart.cart) {
          if (count % 2) {
            col2.push(
              <CartItem
                item={lineItem}
                key={lineItem.id}
                refetch={refetch}
                customer={customer}
                customerPath={customerPath}
              />
            );
          } else {
            col1.push(
              <CartItem
                item={lineItem}
                key={lineItem.id}
                refetch={refetch}
                customer={customer}
                customerPath={customerPath}
              />
            );
          }
          count++;
        }
      return (
        <Row>
          <Col className={`${className} h-100`}>{col1}</Col>
          <Col className={`${className} h-100`}>{col2}</Col>
        </Row>
      );
    }
  };

  let totalPrice = cart && cart.total ? parseFloat(cart.total) : false;

  totalPrice =
    totalPrice && toAddPrice ? totalPrice + parseFloat(toAddPrice) : totalPrice;

  const formattedPrice = totalPrice
    ? totalPrice?.toLocaleString(undefined, { maximumFractionDigits: 2 })
    : false;

  const textStyle = {
    textShadow: "1px 1px 2px white",
  };

  return (
    <div>
      <Row>
        <Col>{title}</Col>
        {handleStartTour && (
          <Col className="text-end">
            <Button variant="secondary" onClick={handleStartTour}>
              {t("Start tour")}
            </Button>
          </Col>
        )}
      </Row>
      {!cart?.total && (
        <p style={textStyle}>
          {t("Your cart is empty, start adding some products!")}
        </p>
      )}
      {renderItems()}
      <div className="text-center">
        {formattedPrice && (
          <div style={{ textShadow: "1px 1px 2px white" }}>
            {toAddPrice && (
              <>
                <div>
                  <span className="fw-bold">Cart:&nbsp;</span>
                  <span>
                    {unitPrice +
                      cart.total?.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })}
                  </span>
                </div>
                <div>
                  <span className="fw-bold">Shipment:&nbsp;</span>
                  <span>
                    {unitPrice +
                      toAddPrice?.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })}
                  </span>
                </div>
              </>
            )}
            <div>
              <span className="fs-5">Total:&nbsp;</span>
              <span
                style={{ textShadow: "1px 1px 2px white" }}
                className="fw-bold fs-5"
              >
                {unitPrice + formattedPrice}
              </span>
            </div>
          </div>
        )}
        {toAddPrice === "loading" && <LoadingSpinner />}
        {toAddPrice === "error" && (
          <>
            {t("Error getting shipment quote.")}
            <p style={{ textShadow: "1px 1px 2px white" }}>
              <div>
                <span className="fs-5">Total:&nbsp;</span>
                <span
                  style={{ textShadow: "1px 1px 2px white" }}
                  className="fw-bold fs-5"
                >
                  {unitPrice +
                    cart.total?.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}
                </span>
              </div>
            </p>
          </>
        )}
        {showButtons && (
          <>
            {!cart?.total ? (
              <>
                {showGoBack && (
                  <ButtonGroup>
                    <Link
                      className="btn btn-primary px-4"
                      to={`/${customerPath}`}
                    >
                      {t("Go Back")}
                    </Link>
                  </ButtonGroup>
                )}
              </>
            ) : (
              <ButtonGroup>
                <Link
                  className="btn btn-success px-4 fourth-cart-step"
                  to={`/${customerPath}/order`}
                >
                  {t("Buy")}
                </Link>
              </ButtonGroup>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Cart;
