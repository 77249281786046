import Swal from "sweetalert2";
import { useState } from "react";
import { themes } from "./themes";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserData } from "helpers/ClientHelper";
import { apiRequestUri, appLogout } from "helpers/helper";
import { LoadingSpinner } from "components/LoadingSpinner";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { Image } from "components/Image";

export const AdminThemes = () => {
  const closeTime = process.env.REACT_APP_CLOSE_TIME
    ? process.env.REACT_APP_CLOSE_TIME
    : 1500;
  const { t } = useTranslation();
  const { data: customer, refetch: refetchCustomer, remove } = UserData();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [goto, setGoto] = useState();

  const handleShow = () => {
    Swal.fire({
      icon: "error",
      title: t("An error has occurred."),
      showConfirmButton: false,
      timer: closeTime,
      timerProgressBar: true,
    }).then(() => {
      remove();
      appLogout().then(() => {
        setGoto("/login");
      });
    });
  };

  const sendDefaultData = (theme) => {
    setLoading(true);
    let newData = {};
    if (customer) {
      const uri = "user/" + customer.data.id;
      newData = { ...customer.data.data, style: theme.defaultStyle };
      apiRequestUri(uri, "PUT", { data: newData }, false)
        .then(() => {
          refetchCustomer().then(() => {
            Swal.fire({
              icon: "success",
              title:
                t("**theme ") + theme.title + t(" theme updated successfully."),
              showConfirmButton: false,
              timer: closeTime,
              timerProgressBar: true,
            });
            setLoading(false);
          });
        })
        .catch((error) => {
          handleShow();
          setLoading(false);
        });
    } else {
      handleShow();
      setLoading(false);
    }
  };

  useEffect(() => {
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [goto, navigate]);

  return (
    <Container fluid className="mt-5 text-center">
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Row className="justify-content-center">
          {themes.map((theme) => (
            <Col md="4" key={theme.id} className="mb-3">
              <Card bg="dark" text="light">
                <Card.Body>
                  <Row className="align-items-center">
                    <Col className="text-start">
                      <h5>{t(theme.title)}</h5>
                    </Col>
                    <Col className="text-end">
                      <Button
                        variant="primary"
                        onClick={() => sendDefaultData(theme)}
                      >
                        {t("Select")}
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
                <Image
                  className="card-img-top"
                  src={theme.imgSrc}
                  alt={theme.title}
                />
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </Container>
  );
};
