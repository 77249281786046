import { apiRequestUri } from "helpers/helper";
import { useTranslation } from "react-i18next";
import { UserData } from "helpers/ClientHelper";
import { Link, useLocation } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { sendOrderEmail } from "helpers/emailOrderHelper";
import { LoadingSpinner } from "components/LoadingSpinner";
import { Button, Card, Col, Form, Row } from "react-bootstrap";

export const PaymentShipment = ({
  customer,
  customerPath,
  successCallback,
  errorCallback,
  addresses,
  className,
  shipmentPriceCallback,
}) => {
  const location = useLocation();
  const hostUrl = window.location.href.replace(location.pathname, "");
  const shipmentProviderId = process.env.REACT_APP_SHIPMENT_PROVIDER_ID
    ? process.env.REACT_APP_SHIPMENT_PROVIDER_ID
    : 1;
  const paymentProviderId = process.env.REACT_APP_PAYMENT_PROVIDER_ID
    ? process.env.REACT_APP_PAYMENT_PROVIDER_ID
    : 1;
  const initialFormState = {
    ship: false,
    noship: true,
    address_id: false,
  };
  const { t } = useTranslation();
  const defaultButton = t("Buy");
  const { data: user } = UserData();
  const [formState, setFormState] = useState(initialFormState);
  const [content, setContent] = useState(<LoadingSpinner />);
  const [button, setButton] = useState(defaultButton);
  const [price, setPrice] = useState();

  const handleSubmit = (event) => {
    event.preventDefault();
    setButton(<LoadingSpinner />);
    const uri = "order";
    let defaultPaymentProvider = paymentProviderId;
    let defaultShipmentProvider = shipmentProviderId;
    if (
      customer &&
      customer.data &&
      customer.data.payment_methods &&
      customer.data.payment_methods.length
    )
      defaultPaymentProvider =
        customer.data.payment_methods[
          Object.keys(customer.data.payment_methods)[0]
        ].provider_id;
    if (
      customer &&
      customer.data &&
      customer.data.shipment_methods &&
      customer.data.shipment_methods.length
    )
      defaultShipmentProvider =
        customer.data.shipment_methods[
          Object.keys(customer.data.shipment_methods)[0]
        ].provider_id;
    let data = {
      payment: {
        payment_provider_id: formState.payment_provider
          ? formState.payment_provider
          : defaultPaymentProvider,
        data: {
          notification_url: `${hostUrl}/${customerPath}/order`,
        },
      },
      customer_id: customer.id,
    };
    if (formState.ship) {
      data["shipment"] = {
        shipment_provider_id: formState.shipment_provider
          ? formState.shipment_provider
          : defaultShipmentProvider,
        address_id: formState.address_id,
      };
    }
    apiRequestUri(uri, "POST", data, false)
      .then((response) => {
        successCallback(response);
        sendOrderEmail(user, customer, hostUrl, response, t);
      })
      .catch((error) => {
        errorCallback();
        setButton(defaultButton);
      });
  };

  const loadContent = useCallback(
    (currentFormState) => {
      const getAddressSelect = () => {
        if (addresses.length === 0) {
          return (
            <Link
              className="btn btn-success"
              to={"/" + customerPath + "/profile"}
            >
              {t("Add Address")}
            </Link>
          );
        }
        let addressOptions = [];
        addressOptions.push(
          <option key="addressSelect" value="">
            {t("Select an address")}
          </option>
        );
        for (const item of addresses) {
          addressOptions.push(
            <option key={item.id} value={item.id}>
              {item.address_line1}
            </option>
          );
        }
        return (
          <Form.Select id="address_id" onChange={handleChange} required>
            {addressOptions}
          </Form.Select>
        );
      };

      const handleChange = (event) => {
        let currentFormState = { ...formState };
        if (
          event.target.value === "on" &&
          ["ship", "noship"].includes(event.target.id)
        ) {
          currentFormState["ship"] = false;
          currentFormState["noship"] = false;
          currentFormState[event.target.id] = true;
        }
        if (currentFormState["ship"] === "on") currentFormState["ship"] = true;
        if (currentFormState["noship"] === "on")
          currentFormState["noship"] = true;
        if (event.target.id === "address_id" && currentFormState["ship"]) {
          const addressId = event.target.value;
          if (addressId) {
            setPrice(<LoadingSpinner />);
            const uri = "shipment/budget";
            let shipmentProviderId = 1;
            if (
              customer.data?.shipment_methods?.length &&
              customer.data.shipment_methods.length > 0
            ) {
              shipmentProviderId =
                customer.data.shipment_methods[0].provider_id;
            }
            const data = {
              shipment_provider_id: shipmentProviderId,
              address_id: addressId,
              customer_id: customer.id,
            };
            shipmentPriceCallback("loading");
            apiRequestUri(uri, "POST", data, false)
              .then((response) => {
                if (response.price.status === "error") {
                  console.log(response.price);
                  setPrice("error");
                  shipmentPriceCallback("error");
                } else if (response.price) {
                  setPrice("$" + response.price);
                  shipmentPriceCallback(response.price);
                } else setPrice(<></>);
              })
              .catch((error) => {
                console.log(error);
                setPrice("error");
                shipmentPriceCallback("error");
              });
          }
        }
        currentFormState[event.target.id] = event.target.value;
        if (
          !currentFormState["ship"] ||
          !currentFormState["address_id"] ||
          !currentFormState["address_id"]?.length === 0
        ) {
          setPrice();
          shipmentPriceCallback(null);
        }
        setButton(defaultButton);
        setFormState(currentFormState);
        loadContent(currentFormState);
      };

      let select = [];
      let bottomText = t("The seller will contact you to coordinate payment.");
      if (currentFormState.ship) {
        select = getAddressSelect();
      } else {
        bottomText = t(
          "The seller will contact you to coordinate shipping and payment."
        );
      }
      setContent(
        <>
          <h6>{t("Shipping Options")}:</h6>
          <Row>
            <Col xs={12} md={6} className="first-order-step">
              <Form.Check
                id="ship"
                label={t("Home delivery")}
                name="ship"
                type="radio"
                onChange={handleChange}
                checked={currentFormState.ship}
              />
              <Form.Check
                id="noship"
                label={t("Agree later")}
                name="noship"
                type="radio"
                onChange={handleChange}
                checked={currentFormState.noship}
              />
            </Col>
            <Col xs={12} md={6} className="text-end">
              {select}
            </Col>
          </Row>
          <Row className="my-3">
            <Col>
              <Card.Text>{bottomText}</Card.Text>
            </Col>
          </Row>
          {customer &&
            customer.data &&
            customer.data.payment_methods &&
            customer.data.payment_methods.length && (
              <>
                <hr />
                <h6>{t("Payment Options")}:</h6>
                <Form.Select
                  id="payment_provider"
                  className="mb-3 fourth-order-step"
                  onChange={handleChange}
                >
                  {customer.data.payment_methods.map((item, index) => (
                    <option
                      key={`Payment Option ${index}`}
                      value={item.provider_id}
                    >
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </>
            )}
        </>
      );
    },
    [
      addresses,
      customerPath,
      formState,
      defaultButton,
      t,
      customer,
      shipmentPriceCallback,
    ]
  );

  useEffect(() => {
    loadContent(formState);
  }, [formState, loadContent, price]);

  return (
    <Card className={className}>
      <Card.Body>
        <Card.Title>{t("Order Options")}</Card.Title>
        <hr />
        <Form onSubmit={handleSubmit}>
          <div className="mb-3 mt-3 text-start">
            {content}
            <Button
              className="my-2 third-order-step"
              variant="primary"
              type="submit"
            >
              {button}
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};
