import Joyride from "react-joyride";
import Cart from "components/client/cart/Cart";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ClientView } from "views/client/Client";
import { Container, Row } from "react-bootstrap";
import { useCallback, useEffect, useState } from "react";
import { CartData, UserData } from "helpers/ClientHelper";
import { joyrideTranslate } from "helpers/functionsHelper";
import { apiRequestUri } from "helpers/helper";

const ViewCart = ({ customer, customerPath }) => {
  const unitPrice = process.env.REACT_APP_UNIT_PRICE
    ? process.env.REACT_APP_UNIT_PRICE
    : "$";
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [goto, setGoto] = useState();
  const { data, refetch } = CartData(customer.id);
  const [runOnboarding, setRunOnboarding] = useState(false);
  const { data: currentUser, refetch: refetchUser } = UserData();

  const steps = [
    {
      target: ".first-cart-step",
      content: t(
        "With this button you can share the product on your social networks."
      ),
      disableBeacon: true,
    },
    {
      target: ".second-cart-step",
      content: t(
        "With these buttons you can choose the quantity you want to buy."
      ),
    },
    {
      target: ".third-cart-step",
      content: t(
        "With this button, you can remove a product from your shopping cart."
      ),
    },
    {
      target: ".fourth-cart-step",
      content: t(
        "By clicking on this button you can select the payment and shipping method to complete the purchase."
      ),
    },
  ];

  const updateCart = useCallback(() => {
    if (customer) refetch();
  }, [customer, refetch]);

  const handleStartTour = () => {
    setRunOnboarding(true);
  };

  const handleSkip = (e) => {
    if (["skip", "close"].includes(e.action)) {
      setRunOnboarding(false);
      if (currentUser) {
        const target = {
          method: "PUT",
          uri: "user/" + (currentUser ? currentUser.data.id : ""),
        };
        let newData = currentUser.data.data;
        if (!newData) newData = {};
        newData["cartOnboarding"] = 1;
        apiRequestUri(target.uri, target.method, { data: newData }, false)
          .then(() => {
            refetchUser();
          })
          .catch();
      }
    }
  };

  useEffect(() => {
    if (data) {
      if (!currentUser?.data?.data?.cartOnboarding) setRunOnboarding(true);
    }
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [goto, navigate, data, currentUser]);

  return (
    <ClientView customerPath={customerPath} customer={customer}>
      <Joyride
        hideCloseButton
        locale={joyrideTranslate(t)}
        continuous
        run={runOnboarding}
        scrollToFirstStep
        showProgress
        showSkipButton
        steps={steps}
        callback={handleSkip}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      <Container className=" text-center">
        <Row className="my-3">
          <Cart
            title={<h4 className="text-start">{t("Your shopping cart")}</h4>}
            cart={data?.data}
            unitPrice={unitPrice}
            refetch={updateCart}
            customer={customer}
            customerPath={customerPath}
            handleStartTour={handleStartTour}
          />
        </Row>
      </Container>
    </ClientView>
  );
};

export default ViewCart;
