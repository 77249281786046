import axios from "axios";
import Cookies from "universal-cookie";

const cookieConfig = {
  path: "/",
  sameSite: "None",
  secure: true,
};

export const getUser = async () => {
  const uri = "user?info=all";
  const method = "GET";
  const data = {};
  return await apiRequestUri(uri, method, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const apiRequestUri = async (uri, method, data = {}, config = {}) => {
  const url = process.env.REACT_APP_API_URL + "/api/" + uri;
  return await apiRequestAccess(url, method, data, true, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getAccessToken = async () => {
  const grantType = "refresh_token";
  const cookies = new Cookies();
  const refreshToken = cookies.get("refresh_token");
  if (!refreshToken || refreshToken === "") {
    throw Object.assign(new Error("There is no refresh_token in cookies"), {
      code: 404,
    });
  }
  const data = {
    refresh_token: refreshToken,
    client_secret: process.env.REACT_APP_CLIENT_SECRET,
    client_id: process.env.REACT_APP_CLIENT_ID,
    grant_type: grantType,
  };
  return await apiPasswordAuth(data);
};

export const apiRequestAccess = async (
  url,
  method,
  data,
  tryWithRefresh = true,
  config = {}
) => {
  const cookies = new Cookies();
  const accessToken = cookies.get("access_token");
  const request = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Access-Control-Allow-Origin": "*",
    },
    method: method,
    url: url,
    data: data,
  };
  if (
    config &&
    Object.keys(config).length !== 0 &&
    config.headers &&
    Object.keys(config.headers).length !== 0
  ) {
    for (const key in config.headers) {
      const element = config.headers[key];
      request.headers[key] = element;
    }
  }
  return await axios
    .request(request)
    .then((response) => {
      return {
        status: response.status,
        data: response.data,
      };
    })
    .catch((error) => {
      if (tryWithRefresh) {
        return apiRequestRefresh(url, method, data, config)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            throw error;
          });
      } else {
        throw error;
      }
    });
};

export const apiRequestRefresh = async (url, method, data, config) => {
  return await getAccessToken()
    .then((response) => {
      return apiRequestAccess(url, method, data, false, config);
    })
    .catch((error) => {
      throw error;
    });
};

export const apiPasswordAuth = async (data) => {
  return await axios
    .post(process.env.REACT_APP_API_URL + "/oauth/token", data)
    .then((response) => {
      const cookies = new Cookies();
      cookies.set("refresh_token", response.data.refresh_token, cookieConfig);
      cookies.set("access_token", response.data.access_token, cookieConfig);
      return true;
    })
    .catch((error) => {
      throw error;
    });
};

export const appLogout = async () => {
  return await clearCookie("access_token").then(() => {
    clearCookie("refresh_token").then(() => {
      return true;
    });
  });
};

export const clearCookie = async (name) => {
  const cookies = new Cookies();
  cookies.set(name, "", cookieConfig);
  return name + " cleared";
};

export const setCookie = async (name, data) => {
  const cookies = new Cookies();
  cookies.set(name, data, cookieConfig);
  return name + " setted";
};

export const getCookie = (name) => {
  const cookies = new Cookies();
  return cookies.get(name);
};
