import { Image } from "./Image";
import Upscaler from "upscaler";
import { useEffect, useState } from "react";
import * as tf from "@tensorflow/tfjs";
import Resizer from "react-image-file-resizer";
import { getImageSize } from "react-image-size";
import { LoadingSpinner } from "./LoadingSpinner";
import * as models from "@upscalerjs/esrgan-slim";
import { Button, Col, Row } from "react-bootstrap";
import { getFileFromUrl } from "helpers/functionsHelper";
import { setWasmPaths } from "@tensorflow/tfjs-backend-wasm";

export const UpscaledImage = ({
  scale = 4,
  src = null,
  patchSize = 64,
  padding = 2,
  showEnhance = true,
  resize = false,
  progressCallback = null,
  resultCallback = null,
  errorCallback = null,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [resizedUrl, setResizedUrl] = useState(null);
  const [enhancing, setEnhancing] = useState(false);
  const [enhancedImageUrl, setEnhancedImageUrl] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [hoverScale, setHoverScale] = useState(2);

  const handleImageResize = () => {
    if (!resize) {
      setResizedUrl(enhancedImageUrl);
      resultCallback && resultCallback(enhancedImageUrl);
    } else {
      if (!resizedUrl) {
        const file = getFileFromUrl(enhancedImageUrl);
        getImageSize(enhancedImageUrl).then((dimensions) => {
          Resizer.imageFileResizer(
            file,
            dimensions.width / scale,
            dimensions.height / scale,
            "WEBP",
            100,
            0,
            (uri) => {
              setResizedUrl(uri);
              resultCallback && resultCallback(uri);
            },
            "base64"
          );
        });
      }
    }
    setEnhancing(false);
  };

  const handleEnhance = () => {
    if (enhancing) {
      return;
    }
    progressCallback && progressCallback(0);
    setEnhancing(true);
    setResizedUrl(null);
    setEnhancedImageUrl(null);
    try {
      setWasmPaths("/");
    } catch {}
    tf.setBackend("wasm").then(() => {
      processEnhance();
    });
  };

  const processEnhance = () => {
    let upscaleModel = models.x4;
    switch (scale) {
      case 2:
        upscaleModel = models.x2;
        break;
      case 3:
        upscaleModel = models.x3;
        break;
      case 4:
        upscaleModel = models.x4;
        break;
      case 8:
        upscaleModel = models.x8;
        break;

      default:
        upscaleModel = models.x4;
        break;
    }
    const upscaler = new Upscaler({
      model: upscaleModel,
    });
    const config = {
      patchSize: patchSize,
      padding: padding,
      progress: (progress) => {
        progressCallback && progressCallback(parseInt(progress * 100));
      },
    };
    try {
      fetch(src)
        .then((r) => {
          upscaler.warmup([config]).then(() => {
            upscaler.upscale(src, config).then((upscaledSrc) => {
              setEnhancedImageUrl(upscaledSrc);
              upscaler.abort();
              upscaler.dispose();
            });
          });
        })
        .catch((error) => {
          if (errorCallback) errorCallback(error);
          setEnhancing(false);
        });
    } catch (error) {
      if (errorCallback) errorCallback(error);
      setEnhancing(false);
    }
  };

  const handleCancel = () => {
    setIsHovered(false);
    setResizedUrl(null);
    setEnhancing(false);
    setEnhancedImageUrl(null);
    resultCallback(src);
  };

  const handleMouseMove = (e) => {
    const imgPosX = e.clientX - e.target.x;
    const imgPosY = e.clientY - e.target.y;

    const width = e.target.width;
    const height = e.target.height;

    let newXPos = width / 2 - imgPosX;
    let newYPos = height / 2 - imgPosY;

    if (newXPos > width / 3) newXPos = width / 3;
    if (newXPos < (-1 * width) / 3) newXPos = (-1 * width) / 3;

    if (newYPos > height / 3) newYPos = height / 3;
    if (newYPos < (-1 * height) / 3) newYPos = (-1 * height) / 3;

    setPosition({
      x: newXPos,
      y: newYPos,
    });
  };

  const handleScroll = (e) => {
    const delta = e.deltaY * -0.001;
    const newScale = hoverScale + delta;
    if (newScale >= 2) setHoverScale(newScale);
  };

  useEffect(() => {
    // console.log(src);
  }, [src]);

  return (
    <>
      {src && (
        <>
          {showEnhance && (
            <>
              <Button className="mb-2 me-2" onClick={handleEnhance}>
                Enhance
              </Button>
              {resizedUrl && (
                <Button
                  variant="danger"
                  className="mb-2"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              )}
              <Row>
                <Col>BEFORE</Col>
                <Col>AFTER</Col>
              </Row>
            </>
          )}
          <Row
            className="align-items-center position-relative z-3"
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
            }}
          >
            <Col className="text-center overflow-hidden">
              <Image
                style={{
                  position: "relative",
                  transform: isHovered
                    ? `scale(${hoverScale}) translate(${position.x}px, ${position.y}px)`
                    : "",
                }}
                className={`border ${isHovered ? "z-1" : ""}`}
                src={src}
                alt="Selected"
                width="100%"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onMouseMove={handleMouseMove}
                onWheelCapture={handleScroll}
              />
            </Col>
            {showEnhance && (
              <Col className="text-center overflow-hidden">
                {enhancing ? (
                  <>
                    <div className="">Processing ...</div>
                    <LoadingSpinner />
                  </>
                ) : (
                  resizedUrl && (
                    <Image
                      style={{
                        position: "relative",
                        transform: isHovered
                          ? `scale(${hoverScale}) translate(${position.x}px, ${position.y}px)`
                          : "",
                      }}
                      className="border z-2"
                      src={resizedUrl}
                      alt="Enhanced"
                      width="100%"
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                      onMouseMove={handleMouseMove}
                      onWheelCapture={handleScroll}
                    />
                  )
                )}
              </Col>
            )}
          </Row>
          <>
            {enhancedImageUrl && (
              <Image
                className="d-none"
                src={enhancedImageUrl}
                alt="Enhanced"
                width="100%"
                onLoad={handleImageResize}
              />
            )}
          </>
        </>
      )}
    </>
  );
};
