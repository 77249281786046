import Swal from "sweetalert2";
import Joyride from "react-joyride";
import { apiRequestUri, getCookie, setCookie } from "helpers/helper";
import { useTranslation } from "react-i18next";
import { ClientView } from "views/client/Client";
import { useCallback, useEffect, useState } from "react";
import { CardDetail } from "components/client/CardDetail";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingSpinner } from "components/LoadingSpinner";
import { joyrideTranslate } from "helpers/functionsHelper";
import { CartData, Product, UserData } from "helpers/ClientHelper";
import { Container, Button, Row, ButtonGroup, Col } from "react-bootstrap";
import { ClientQuestions } from "components/client/question/ClientQuestions";

export const ProductDetail = ({ customer, customerPath }) => {
  let { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [runOnboarding, setRunOnboarding] = useState(false);
  const [stopOnboarding, setStopOnboarding] = useState(false);
  const { data: currentUser, refetch: refetchCurrentUser } = UserData();
  const [quantity, setQuantity] = useState(1);
  const [content, setContent] = useState(
    <Col className="text-center">
      <LoadingSpinner />
    </Col>
  );
  const [adding, setAdding] = useState(false);
  const [goto, setGoto] = useState();

  const product = Product(id);
  const { isRefetching, isLoading, isFetching, refetch } = CartData(
    customer.id
  );

  const closeTime = process.env.REACT_APP_CLOSE_TIME
    ? process.env.REACT_APP_CLOSE_TIME
    : 1500;

  const steps = () => {
    let data = [
      {
        target: ".first-product-step",
        content: t(
          "Here you can ask questions about the product and its details."
        ),
        disableBeacon: true,
      },
      {
        target: ".second-product-step",
        content: t("Here the questions that are asked will be answered."),
      },
      {
        target: ".fifth-product-step",
        content: t(
          "With this button you can share the product on your social networks."
        ),
      },
    ];
    if (product?.stock) {
      data.push({
        target: ".third-product-step",
        content: t(
          "With these buttons you can choose the quantity you want to buy."
        ),
      });
      data.push({
        target: ".fourth-product-step",
        content: t(
          "With this button you can add the product with the chosen quantity to the shopping cart to proceed with the purchase."
        ),
      });
    } else {
      data.push({
        target: ".third-product-step",
        content: t(
          "If you enter a product with stock you will be able to see more options."
        ),
      });
    }
    return data;
  };

  const handleAddToCart = useCallback(() => {
    const answerLogin = () => {
      Swal.fire({
        icon: "warning",
        title: t("To add products to the shopping cart you must log in first."),
        showConfirmButton: true,
        confirmButtonText: t("Go to Login"),
      }).then((result) => {
        if (result.isConfirmed) {
          setGoto("/" + customerPath + "/login");
          return;
        }
      });
    };

    const showNotStock = () => {
      Swal.fire({
        icon: "warning",
        title: t("The product has not stock to add."),
        showConfirmButton: false,
        timer: closeTime * 2,
        timerProgressBar: true,
      });
    };

    let uri = "cart";
    let data = {
      product_id: id,
      quantity: quantity,
      customer_id: customer.id,
    };
    setAdding(true);
    apiRequestUri(uri, "POST", data)
      .then((response) => {
        refetch();
        setAdding(false);
      })
      .catch((error) => {
        if (
          error?.response?.data?.error &&
          error.response.data.error === "The product has not stock to add."
        ) {
          showNotStock();
        } else answerLogin();
        setAdding(false);
      });
  }, [
    id,
    quantity,
    setAdding,
    refetch,
    customerPath,
    t,
    closeTime,
    customer.id,
  ]);

  const incrementQuantity = useCallback(() => {
    if (quantity < product.stock) {
      setQuantity(quantity + 1);
    }
  }, [quantity, product, setQuantity]);

  const decrementQuantity = useCallback(() => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  }, [quantity, setQuantity]);

  const handleStartTour = () => {
    setStopOnboarding(false);
    setRunOnboarding(true);
  };

  const handleSkip = (e) => {
    if (["skip", "reset", "close", "stop"].includes(e.action)) {
      if (!currentUser) {
        setRunOnboarding(false);
        setStopOnboarding(true);
        setCookie("productOnboarding", 1);
      } else {
        const target = {
          method: "PUT",
          uri: "user/" + (currentUser ? currentUser.data.id : ""),
        };
        let newData = currentUser.data.data;
        if (!newData) newData = {};
        newData["productOnboarding"] = 1;
        apiRequestUri(target.uri, target.method, { data: newData }, false)
          .then(() => {
            refetchCurrentUser().then(() => {
              setRunOnboarding(false);
              setStopOnboarding(true);
            });
          })
          .catch(() => {
            setRunOnboarding(false);
            setStopOnboarding(true);
          });
      }
    }
  };

  useEffect(() => {
    if (product) {
      refetchCurrentUser().then((response) => {
        const productOnboardingCookie = getCookie("productOnboarding");
        if (
          !response?.data?.data?.data?.productOnboarding &&
          !stopOnboarding &&
          !productOnboardingCookie
        )
          setTimeout(() => {
            setRunOnboarding(true);
          }, 200);
      });
      setContent(
        <>
          <Row className="text-center w-100">
            <Col>
              <CardDetail
                product={product}
                className="text-start mb-3"
                customerPath={customerPath}
                rightData={
                  product.stock && (
                    <div className="shadow h-100 d-flex">
                      <div className="my-1 mx-auto">
                        <ButtonGroup className="third-product-step">
                          <Button onClick={decrementQuantity}>-</Button>
                          <div className="border border-primary d-flex">
                            <p className="my-auto mx-1">{quantity}</p>
                          </div>
                          <Button onClick={incrementQuantity}>+</Button>
                        </ButtonGroup>
                        <br />
                        <ButtonGroup className="mt-3 fourth-product-step">
                          <Button onClick={handleAddToCart} variant="success">
                            {isRefetching ||
                            isLoading ||
                            isFetching ||
                            adding ? (
                              <LoadingSpinner />
                            ) : (
                              t("Add to cart")
                            )}
                          </Button>
                        </ButtonGroup>
                      </div>
                    </div>
                  )
                }
              />
            </Col>
            <Col className="text-start shadow">
              {product && (
                <>
                  <Button variant="secondary" onClick={handleStartTour}>
                    {t("Start tour")}
                  </Button>
                  <ClientQuestions
                    className="mx-5"
                    productTitle={product.title}
                    productId={id}
                    customerPath={customerPath}
                    customer={customer}
                  />
                </>
              )}
            </Col>
          </Row>
        </>
      );
    }
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [
    currentUser,
    quantity,
    isRefetching,
    isLoading,
    isFetching,
    adding,
    customer,
    customerPath,
    decrementQuantity,
    handleAddToCart,
    id,
    incrementQuantity,
    product,
    goto,
    navigate,
    t,
    refetchCurrentUser,
    stopOnboarding,
  ]);

  return (
    <ClientView customer={customer} customerPath={customerPath}>
      <Container fluid className="mt-3 text-center">
        <Joyride
          scrollDuration={1000}
          hideCloseButton
          locale={joyrideTranslate(t)}
          continuous
          run={runOnboarding}
          scrollToFirstStep
          showProgress
          showSkipButton
          steps={steps()}
          callback={handleSkip}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />
        {content}
      </Container>
    </ClientView>
  );
};
