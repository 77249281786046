const ImageUploader = ({
  selectedImageCallback,
  className,
  onChange,
  id,
  name,
}) => {
  const handleImageUpload = (event) => {
    const image = event.target.files[0];
    selectedImageCallback(URL.createObjectURL(image));
    if (onChange) {
      onChange(event);
    }
  };
  return (
    <input
      id={id}
      name={name}
      type="file"
      accept="image/*"
      onChange={handleImageUpload}
      className={"form-control mb-3 " + className}
    />
  );
};
export default ImageUploader;
