import { useEffect, useState } from "react";
import Footer from "components/client/Footer";
import { useTranslation } from "react-i18next";
import { Categories } from "helpers/ClientHelper";
import Header from "components/client/header/Header";
import { getTopbarLinks } from "helpers/getTopbarLinks";

export const ClientView = ({
  children,
  customer,
  customerPath,
  className,
  handleSearch,
  toggleRef,
}) => {
  const topbarColor = customer.data?.style?.topbarcolor || "#ffffff";
  const footerColor = customer.data?.style?.footercolor || "#000000";
  const topbarTextColor = customer.data?.style?.topbartextcolor || "#000000";
  const backgroundColor = customer.data?.style?.backgroundcolor || "#ffffff";
  const footerTextColor = customer.data?.style?.footertextcolor || "#ffffff";

  const categories = Categories(customer.id);
  const { t } = useTranslation();
  const initialTopbar = getTopbarLinks([], customerPath, t("Products"));
  const [topbarLinks, setTopbarLinks] = useState(initialTopbar);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded && categories) {
      setLoaded(true);
      const currentTopbar = getTopbarLinks(
        categories.data,
        customerPath,
        t("Products")
      );
      setTopbarLinks(currentTopbar);
    }
  }, [loaded, categories, customerPath, t]);

  const styles = `
    .nav-link:hover {
      background-color: rgba(0,0,0,0.025);
    }
  `;

  return (
    <div
      className={className}
      style={{
        backgroundColor: backgroundColor,
        minHeight: "100vh",
        height: "100%",
      }}
    >
      <style>{styles}</style>
      <Header
        links={topbarLinks}
        customer={customer}
        customerPath={customerPath}
        backgroundColor={topbarColor}
        textColor={topbarTextColor}
        handleSearch={handleSearch}
        toggleRef={toggleRef}
      />
      <div
        style={{
          minHeight: "75vh",
          height: "100%",
        }}
      >
        {children}
      </div>
      <Footer
        backgroundColor={footerColor}
        textColor={footerTextColor}
        customerPath={customerPath}
      >
        {customer}
      </Footer>
    </div>
  );
};
