export const es = {
  Products: "Productos",
  Brand: "Marca",
  verified: "verificado",
  "not verified": "no verificado",
  Profile: "Perfil",
  Logout: "Cerrar Sesion",
  Login: "Iniciar Sesion",
  "Transform your Business": "Transforma tu Negocio",
  "Join the digital revolution. Create your online store and start selling today.":
    "Unete a la revolucion digital. Crea tu tienda online y comienza a vender hoy mismo.",
  Start: "Empezar",
  "Online Store!": "¡Tienda Virtual!",
  "At WebComprehensive, we offer you an ":
    "En WebComprehensive, te ofrecemos una ",
  "online store": "tienda virtual",
  " that will allow you to take your business to the next level without complications. Why choose us? Here we explain:":
    " que te permitirá llevar tu negocio al siguiente nivel sin complicaciones. ¿Por qué elegirnos? Aquí te lo explicamos:",
  "First 30 days FREE": "Primeros 30 días GRATIS",
  "Try our virtual store without any commitment. Start selling online and experience the potential of your business at no cost!":
    "Prueba nuestra tienda virtual sin ningún compromiso. ¡Empieza a vender en línea y experimenta el potencial de tu negocio sin costo alguno!",
  "Quick Creation": "Rápida Creación",
  "Launch your store in a matter of hours! No programming knowledge required. We take care of everything so you can focus on what you do best: selling.":
    "¡Lanza tu tienda en cuestión de horas! No necesitas conocimientos de programación. Nosotros nos encargamos de todo para que puedas enfocarte en lo que mejor sabes hacer: vender.",
  "Easy to Use": "Fácil de Usar",
  "We design your virtual store with an intuitive and easy-to-use interface, so you can manage products, orders and customers without stress.":
    "Diseñamos tu tienda virtual con una interfaz intuitiva y fácil de manejar, para que puedas gestionar productos, pedidos y clientes sin estrés.",
  "Cost of Service": "Costo del Servicio",
  "After the 30-day trial, the service will cost $3,500 per month. A competitive price for all the tools and support that we offer you.":
    "Después de los 30 días de prueba, el servicio tendrá un costo de $3,500 mensuales. Un precio competitivo por todas las herramientas y el soporte que te ofrecemos.",
  "Technical Support": "Soporte Técnico",
  "We will always be at your side to resolve any questions or problems that may arise, ensuring that your store is always operational.":
    "Siempre estaremos a tu lado para resolver cualquier duda o problema que pueda surgir, asegurando que tu tienda esté siempre operativa.",
  "Don't wait any longer, start today and discover how a virtual store can transform your business. Register at":
    "No esperes más, comienza hoy y descubre cómo una tienda virtual puede transformar tu negocio. Registrate en",
  "and start selling online without complications.":
    "y empieza a vender en línea sin complicaciones.",
  "At WebComprehensive, our purpose is to take your business into the digital world with innovative tools and personalized service. We create tailor-made solutions to help you achieve online success.":
    "En WebComprehensive, nuestro propósito es llevar tu negocio al mundo digital con herramientas innovadoras y un servicio personalizado. Creamos soluciones a medida para que alcances el éxito en línea.",
  "https://www.instagram.com/web__comprehensive/":
    "https://www.instagram.com/webcomprehensive/",
  Home: "Inicio",
  Information: "Informacion",
  Contact: "Contacto",
  Register: "Registrarse",
  "The user has registered successfully.":
    "El usuario se ha registrado correctamente.",
  "Invalid password or email.": "Contraseña o correo electrónico no válido.",
  "Sign up for": "Inscribirse en",
  "Have already an account?": "¿Ya tienes una cuenta?",
  "Login here": "Inicie sesión aquí",
  "By registering, you agree to our": "Al registrarse, usted acepta nuestros",
  "Terms of Use": "Términos de Uso",
  "and acknowledge that you have read our": "y reconoce que has leído nuestra",
  "Privacy Policy": "Política de Privacidad",
  Name: "Nombre",
  Password: "Contraseña",
  "Confirm Password": "Confirmar Contraseña",
  "Don't have an account?": "¿No tienes una cuenta?",
  "Welcome to": "Bienvenido a",
  "Welcome to the Privacy Policy of Store - WebComprehensive":
    "Bienvenidos a la Política de Privacidad de Store - WebComprehensive",
  "Privacy Policy of": "Política de Privacidad de",
  "In force from 30 August 2024": "En vigor desde el 30 de agosto de 2024",
  "Please read carefully": "Por favor, lea atentamente",
  "WebComprehensive is deeply concerned about the privacy of its visitors and users. Therefore, in this Privacy Policy":
    "A WebComprehensive le preocupa profundamente la privacidad de sus visitantes y usuarios. En tal virtud, en la presente Política de Privacidad",
  "privacy policy": "política de privacidad",
  "describes how WebComprehensive, together with its affiliated companies around the world":
    "se describe de qué manera WebComprehensive, junto con sus empresas afiliadas de todo el mundo",
  we: "nosotros",
  "our(s)": "nuestro(a)(s)",
  or: "o",
  us: "nos",
  "collects, uses, and shares your Personal Information and explains the data rights you may have over that Personal Information. This Privacy Policy applies to all users of WebComprehensive, including visitors, registered users, and premium users (collectively":
    "recopila, utiliza y comparte su Información Personal; asimismo, se explican los derechos que en materia de datos usted puede tener sobre dicha Información Personal. Esta Política de Privacidad se aplica a todos los usuarios de WebComprehensive, incluidos los visitantes, los usuarios registrados y los usuarios premium (conjuntamente",
  "User(s)": "Usuario(s)",
  you: "usted",
  your: "su(s)",
  "as well as all WebComprehensive services, including our websites (including www.webcomprehensive.com and any of its subdomains, the":
    "así como a todos los servicios de WebComprehensive, entre ellos nuestros sitios web (incluidos www.webcomprehensive.com y cualquiera de sus subdominios, el",
  "Web Site": "Sitio Web",
  "web applications": "las aplicaciones web",
  "WebComprehensive Applications": "Aplicaciones de WebComprehensive",
  "mobile applications": "las aplicaciones móviles",
  "Mobile Applications": "Aplicaciones Móviles",
  "and related services (collectively, the":
    "y los servicios relacionados (conjuntamente, los",
  Services: "Servicios",
  "This Privacy Policy is not intended to replace the terms of any contract you have with us, or any rights you may have under other applicable data protection laws.":
    "La presente Política de Privacidad no tiene por objeto reemplazar los términos de ningún contrato que tenga con nosotros, ni ninguno de los derechos que pueda tener con arreglo a otras leyes aplicables en materia de protección de datos.",
  "Before accessing or using our Services, please read this policy and make sure you fully understand our practices regarding your Personal Information. If after you have read and fully understood this Privacy Policy, you still do not agree with our practices, you should immediately leave the Website and discontinue use of any of our Services. If you have any questions or concerns about this policy, please contact us":
    "Antes de acceder a nuestros servicios o utilizarlos, sírvase leer esta política y asegurarse de entender plenamente nuestras prácticas en lo que respecta a su Información Personal. Si después de haber leído y entendido completamente la presente Política de Privacidad, sigue sin estar de acuerdo con nuestras prácticas, debe abandonar el sitio web de inmediato y suspender totalmente el uso de cualquiera de nuestros Servicios. Si tiene alguna pregunta o duda sobre esta política, comuníquese con nosotros",
  here: "aquí",
  "#ItsThatEasy": "#Esasídefácil",
  "This policy describes our privacy practices: what Personal Information we collect about our Users, what we do with it, how we share it, and your rights regarding that Personal Information.":
    "En esta política se describen nuestras prácticas de privacidad: qué Información Personal recopilamos sobre nuestros Usuarios, qué hacemos con ella y cómo la compartimos, así como sus derechos con respecto a dicha Información Personal.",
  "By accessing or using any of our services, you acknowledge that you have read this Privacy Policy.":
    "Al acceder a cualquiera de nuestros servicios o utilizarlos, usted reconoce que ha leído la presente Política de Privacidad.",
  "What Personal Information do we collect?":
    "¿Qué Información Personal recopilamos?",
  "In order to provide our services, we collect Personal Information about our Users.":
    "Para ofrecer nuestros servicios, recopilamos Información Personal sobre nuestros Usuarios.",
  "Personal Information that comes from you when you visit or use our services, Personal Information that we collect automatically, and Personal Information that we collect from other sources.":
    "La Información Personal que proviene de usted cuando visita o utiliza nuestros servicios, la Información Personal que recopilamos automáticamente y la Información Personal que recopilamos de otras fuentes.",
  "Information about Users": "Información sobre los Usuarios",
  "In order to provide you with the Services, we must collect Personal Information relating to an identified or identifiable natural person":
    "Para prestarle los Servicios, debemos recopilar Información Personal relacionada con una persona física identificada o identificable",
  "Personal Information": "Información Personal",
  "We collect Personal Information that you provide to us while using the Services, as well as from other sources. These are the types of Personal Information we collect about you":
    "Recopilamos la Información Personal que usted nos proporciona mientras usa los Servicios, así como de otras fuentes. Estos son los tipos de Información Personal que recopilamos sobre usted",
  "Information you provide to us": "Información que usted nos proporciona",
  "When you register for our Services, purchase and/or register domain names, use any of our Services, and/or when you contact us directly through any communication channels (e.g., WebComprehensive support tickets, emails), you may provide us with Personal Information, such as your name, email address, phone number, Personal Payment Information (for Users with paid Services), Personal Information included in your communications with us, and Personal Information contained on scanned identification documents (such as a national ID card, driver's license, passport, or official business registration documents)":
    "Cuando se registra para obtener nuestros Servicios, compra y/o registra nombres de dominio, utiliza cualquiera de nuestros Servicios y/o cuando se pone en contacto con nosotros directamente mediante cualquier canal de comunicación (p. ej., solicitudes (“tickets”) de asistencia técnica de WebComprehensive, correos electrónicos), podría proporcionarnos Información Personal, como su nombre, dirección de correo electrónico, número de teléfono, Información Personal de pago (en el caso de los Usuarios con Servicios pagados), Información Personal incluida en sus comunicaciones con nosotros e Información Personal que figura en los documentos de identificación escaneados (como un documento o cédula nacional de identidad, licencia de conducir, pasaporte o documentos oficiales de registro empresarial)",
  "Information we collect when you use the Services":
    "Información que recopilamos al utilizar los Servicios",
  "When you visit, download and/or use any of our Services, we may collect aggregated (anonymous) Personal Information about usage, including browsing activity and click-stream activity, heatmaps and scrolling patterns of Visitors and Users; non-personally identifiable information regarding device, operating system, internet browser, screen resolution, language and keyboard settings, internet service provider, referring and exit pages, date and time stamps, etc., regarding Visitors and Users":
    "Cuando usted visita, descarga y/o utiliza cualquiera de nuestros Servicios, podemos recopilar Información Personal agregada (anónima) sobre el uso, a saber, actividad de navegación y actividad de tráfico (click-stream), mapas de calor (heatmaps) y de desplazamiento de los Visitantes y Usuarios; Información Personal no identificable relativa al dispositivo, sistema operativo, navegador de internet, resolución de pantalla, configuración de idioma y teclado, proveedor de servicios de internet, páginas de remisión y salida, sellos de fecha y hora, etcétera, en relación con los Visitantes y Usuarios",
  "Information we collect from other sources":
    "Información que recopilamos de otras fuentes",
  "We may receive Personal Information about you from third party sources, including: i) security providers, fraud detection and prevention providers, for example to help us screen out users associated with fraud offences; ii) social media platforms, where when you log in or register using your social media account, we may receive Personal Information from that service (e.g. your username, basic Personal Information from your profile) and in some cases we may collect Personal Information from prospecting companies, who help us improve our service offering; iii) advertising and marketing partners, in order to monitor, manage and measure our advertising campaigns":
    "Podemos recibir Información Personal acerca de usted de fuentes de terceros, entre ellas las siguientes: i) proveedores de seguridad, proveedores de detección y prevención de fraudes, por ejemplo para que nos ayuden a descartar a usuarios asociados con delitos de fraude; ii) plataformas de redes sociales, ya que, cuando usted inicia sesión o se registra mediante su cuenta de red social, podemos recibir Información Personal de dicho servicio (p. ej., su nombre de usuario, Información Personal básica de su perfil) y, en algunos casos, podemos recopilar Información Personal de empresas de mejora de la prospección de posibles clientes, que nos ayudan a mejorar nuestra oferta de servicios; iii) socios de publicidad y comercialización, con el fin de supervisar, gestionar y medir nuestras campañas publicitarias",
  "Information about Users of Users":
    "Información sobre Usuarios de los Usuarios",
  "We may also collect Personal Information regarding visitors and users of our websites or services from Users":
    "Asimismo, podemos recopilar Información Personal relativa a los visitantes y usuarios de nuestros sitios web o servicios de Usuarios",
  "Users of Users": "Usuarios de los Usuarios",
  "exclusively for and on behalf of our Users (as described below in Section 6)":
    "exclusivamente para nuestros Usuarios y en nombre de ellos (como se describe adelante en la Sección 6)",
  "How do we use such Personal Information?":
    "¿Cómo utilizamos dicha Información Personal?",
  "We use your Personal Information for the following purposes":
    "Utilizamos su Información Personal para los siguientes fines",
  "to provide and operate the Services": "para prestar y operar los Servicios",
  "to develop, personalize, expand and improve our Services based on the common or personal preferences, experiences and difficulties of Users":
    "para desarrollar, personalizar, ampliar y mejorar nuestros Servicios con base en las preferencias comunes o personales, las experiencias y las dificultades de los Usuarios",
  "to provide our Users with ongoing customer support and technical assistance":
    "para prestarles a nuestros Usuarios una asistencia al cliente y una asistencia técnica permanentes",
  "to contact our Users and send them general or personalized service-related notices and promotional messages (as further detailed in Section 8 below)":
    "para ponernos en contacto con nuestros Usuarios y enviarles avisos y mensajes promocionales generales o personalizados relacionados con el servicio (como se detalla adelante en la Sección 8)",
  "to facilitate the updating, expansion and analysis of our records in order to identify new customers":
    "para facilitarnos la actualización, ampliación y análisis de nuestros registros con el fin de identificar a nuevos clientes",
  "to facilitate, sponsor and offer certain contests, events and promotions, determine the eligibility of participants, monitor performance, contact winners and award prizes and benefits":
    "para facilitar, patrocinar y ofrecer determinados concursos, eventos y promociones, determinar la elegibilidad de los participantes, supervisar el rendimiento, ponernos en contacto con los ganadores y otorgar premios y beneficios",
  "to analyse our performance and marketing activities":
    "para analizar nuestro rendimiento y las actividades de marketing",
  "to create aggregated (anonymous) statistical data and other types of aggregated and/or inferred Personal Information, which we or our business partners may use to provide and improve our respective services":
    "para crear datos estadísticos agregados (anónimos) y otro tipo de Información Personal agregada y/o inferida, que nosotros o nuestros socios de negocios pueden utilizar para prestar y mejorar nuestros respectivos servicios",
  "to provide you with professional assistance, only upon your request to strengthen the security of our data and our fraud prevention capabilities":
    "para proporcionarle asistencia profesional, únicamente previa solicitud de su parte para fortalecer la seguridad de nuestros datos y nuestras capacidades de prevención de fraudes",
  and: "y",
  "to comply with applicable laws and regulations":
    "para cumplir con las leyes y reglamentos aplicables",
  "We may use techniques such as “machine learning” (in European law, this is called “automated decision making”) to help us improve our services. When we use machine learning: (i) there is still a human involved in the process (and therefore it is not fully automated); or (ii) we use machine learning in ways that do not have significant privacy implications (for example, reordering how apps appear when you visit the site). We may also use machine learning to help us monitor, identify, and suspend accounts that send unwanted messages or engage in other abusive or fraudulent activities":
    "Podemos usar técnicas como el “aprendizaje automático” (en la ley europea, se denomina como “toma de decisiones automatizada”) para ayudarnos a mejorar nuestros servicios. Cuando usamos el aprendizaje automático: (i) igual hay una persona involucrada en el proceso (y, por lo tanto, no está plenamente automatizado); o (ii) usamos el aprendizaje automático de formas que no tengan consecuencias de importancia para la privacidad (por ejemplo, reordenar cómo aparecen las aplicaciones cuando visita el sitio). También podemos usar el aprendizaje automático para ayudarnos a monitorear, identificar y suspender cuentas que envían mensajes no deseados o que hacen otras actividades abusivas o fraudulentas",
  "We use your Personal Information for the purposes set out in Section 3":
    "Utilizamos su Información Personal para los fines que se establecen en la Sección 3",
  "where it is necessary for us to use your Personal Information to perform a contract or take steps to enter into a contract with you (e.g. to provide you with a website builder, to provide you with our customer support and technical assistance)":
    "cuando sea necesario que utilicemos su Información Personal para ejecutar un contrato o tomar medidas para celebrar un contrato con usted (p. ej., para proporcionarle un constructor de sitio web, para prestarle nuestra asistencia al cliente y asistencia técnica)",
  "where it is necessary for us to use your Personal Information to support legitimate interests and business purposes (for example, to maintain and improve our Services and the effectiveness of WebComprehensive by identifying technical issues), provided we do so in a proportionate manner and respect your privacy rights":
    "cuando sea necesario que utilicemos su Información Personal para respaldar los legítimos intereses y los fines empresariales (por ejemplo, para mantener y mejorar nuestros Servicios y la eficacia de WebComprehensive mediante la identificación de problemas técnicos), siempre que lo hagamos de manera proporcionada y respetando sus derechos de privacidad",
  "We use the Personal Information we collect about you to provide, improve, and make our Services more secure. We also collect and use Personal Information to contact Users and comply with laws to which we are subject":
    "Utilizamos la Información Personal que recopilamos acerca de usted para prestar nuestros Servicios, mejorarlos y hacer que sean más seguros. Asimismo, recopilamos y utilizamos la Información Personal para ponernos en contacto con los Usuarios y cumplir con las leyes a las que estamos sujetos",
  "How do we share your Personal Information?":
    "¿Cómo compartimos su Información Personal?",
  "We may share your Personal Information with service providers and others (or allow them to access it) in the following ways and in the following cases":
    "Podremos compartir su Información Personal con prestadores de servicios y otros (o permitirles que accedan a ella) de las siguientes maneras y en los siguientes casos",
  "Third Party Service Providers": "Proveedores de Servicios de Terceros",
  "WebComprehensive has partnered with a number of selected service providers whose services and solutions complement, facilitate and enhance our own. These include server hosting and collocation services, communications and content delivery networks (CDN), data and cybersecurity services, payment processing and billing services, domain name registrars, fraud prevention and detection services, web analytics, email monitoring and distribution services, session recording and remote access services, performance measurement, data optimization and marketing services, content providers, as well as our legal and financial advisors (collectively,":
    "WebComprehensive se ha asociado con un determinado número de prestadores de servicios seleccionados, cuyos servicios y soluciones complementan, facilitan y mejoran los nuestros. Estos incluyen servicios de hospedaje y coubicación de servidores, comunicaciones y redes de entrega de contenido (CDN), servicios de datos y ciberseguridad, servicios de procesamiento de pagos y facturación, registradores de nombres de dominio, servicios de prevención y detección de fraudes, analítica digital (web analytics), servicios de monitoreo y distribución de correos electrónicos, servicios de grabación de sesiones y acceso remoto, servicios de comercialización, proveedores de contenido, así como nuestros asesores jurídicos y financieros (conjuntamente,",
  "Third Party Service Provider(s)": "Proveedor(es) de Servicios de Terceros",
  "WebComprehensive may share the following categories of Personal Information with Third Party Service Providers for business purposes":
    "WebComprehensive podrá compartir las siguientes categorías de Información Personal con Proveedores de Servicios de Terceros con fines comerciales",
  "identifiers, namely, name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, or other similar identifiers":
    "identificadores, a saber, nombre, alias, dirección postal, identificador personal único, identificador en línea, dirección de protocolo de internet, dirección de correo electrónico, nombre de cuenta u otros identificadores similares",
  "Commercial Personal Information, for example, Personal Information regarding products or services purchased, obtained or considered":
    "Información Personal comercial, por ejemplo, Información Personal con respecto a los productos o servicios adquiridos, obtenidos o considerados",
  "WebComprehensive is responsible for Personal Information that it receives under the Safeguards Framework and that it subsequently transfers to a third party, as described in the Safeguards Framework Principles. In particular, WebComprehensive is responsible and liable under the Safeguards Framework Principles if certain Third Party Service Providers process Personal Information on WebComprehensive's behalf in a manner inconsistent with the Safeguards Framework Principles, unless WebComprehensive proves that it is not responsible for the event giving rise to the damage":
    "WebComprehensive es responsable de la Información Personal que recibe conforme al Marco de Salvaguardia y que posteriormente transfiere a un tercero, como se describe en los Principios del Marco de Salvaguardia. En particular, WebComprehensive es responsable y queda vinculada conforme a los Principios del Marco de Salvaguardia si determinados Proveedores de Servicios de Terceros procesan Información Personal en nombre de WebComprehensive de forma incompatible con dichos Principios, a menos WebComprehensive demuestre que no es responsable del evento que originó el daño",
  "Application of the law, requests and legal duties":
    "Aplicación de la ley, peticiones y deberes legales",
  "WebComprehensive may disclose or otherwise permit access to any of the categories of your Personal Information described in this Privacy Policy pursuant to a legal request, such as a subpoena, legal process, search warrant, or court order, or under applicable law if we believe in good faith that the law requires it, with or without prior notice to you":
    "WebComprehensive puede divulgar o permitir acceso de otra forma a cualquiera de las categorías de su Información Personal que se describen en esta Política de Privacidad de conformidad con una solicitud legal, como una citación, un proceso legal, una orden de allanamiento o una orden judicial, o en virtud de las leyes aplicables si consideramos de buena fe que la ley lo exige, con o sin aviso previo para usted",
  "Protection of rights and security": "Protección de derechos y seguridad",
  "WebComprehensive may share any category of your Personal Information described in this Privacy Policy if we believe in good faith that it will help protect the rights, property, or personal safety of WebComprehensive or any of our Users, Users of Users, or the general public, with or without notice to you":
    "WebComprehensive puede compartir cualquier categoría de su Información Personal descrita en esta Política de Privacidad si consideramos de buena fe que ayudará a proteger los derechos, la propiedad o la seguridad personal de WebComprehensive o de cualquiera de nuestros Usuarios, Usuarios de los Usuarios o el público en general, con o sin aviso previo para usted",
  "WebComprehensive Affiliates and Subsidiaries":
    "Empresas Afiliadas y Subsidiarias de WebComprehensive",
  "We may share your Personal Information internally with our family of companies for the purposes described in this Privacy Policy. For example, we may share your Personal Information with WebComprehensive, our subsidiary, to facilitate and provide Services to you (and your Users of Users). Sharing of Personal Information by WebComprehensive's affiliated companies and subsidiaries in the European Union, the United Kingdom, and Switzerland with WebComprehensive subsidiaries located outside of these regions will only occur pursuant to an approved transfer mechanism, such as the applicable Standard Contractual Clauses":
    "Podemos compartir su Información Personal internamente con nuestra familia de empresas para los fines descritos en esta Política de Privacidad. Por ejemplo, podemos compartir su Información Personal con WebComprehensive, nuestra subsidiaria, para facilitarle y prestarle Servicios a usted (y a sus Usuarios de los Usuarios). El intercambio de Información Personal por las empresas afiliadas y subsidiarias de WebComprehensive en la Unión Europea, el Reino Unido y Suiza con las subsidiarias de WebComprehensive ubicadas afuera de estas regiones solo ocurrirá en virtud de un mecanismo aprobado de transferencia, como las Cláusulas Contractuales Tipo correspondientes",
  "About a change of corporate control":
    "Acerca de un cambio de control corporativo",
  "In addition, in the event of a change in control of WebComprehensive or any of its affiliates, including through a merger, acquisition, or purchase of a majority of its assets, your Personal Information may be shared with the parties involved in such event":
    "Además, en caso de que hubiera cambios en el control de WebComprehensive o cualquiera de sus afiliadas, incluso por medio de fusión, adquisición o compra de una parte mayoritaria de sus activos, su Información Personal podría compartirse con las partes involucradas en dicho evento",
  "According to your instructions": "Según sus instrucciones",
  "WebComprehensive Services allow you, through various techniques, to learn about and engage various third-party services, products, and tools to enhance your websites or mobile sites, including, but not limited to, applications and widgets offered to you by third parties through the WebComprehensive Website, e-commerce payment providers, third-party designers to assist you with your website, etc. (collectively, the “Third Party Services”). If you choose to engage such Third Party Services, they may have access to and process your Users' Personal Information that is collected through their websites or mobile sites. For example":
    "Los Servicios de WebComprehensive le permiten, a través de distintas técnicas, conocer y contratar distintos servicios, productos y herramientas de terceros para mejorar sus sitios web o móviles, lo que incluye, entre otras cosas, las aplicaciones y los widgets que le ofrecen terceros a través del Sitio Web de WebComprehensive, proveedores de pagos de comercios electrónicos, diseñadores externos que lo ayuden con su sitio web, etc. (en conjunto, los “Servicios de Terceros”). Si decide contratar dichos Servicios de Terceros, pueden tener acceso a la Información Personal de sus Usuarios de los Usuarios que se recopila a través de sus sitios web o móviles y procesarla. Por ejemplo",
  "Framed Pages": "Páginas Enmarcadas",
  "our Services may allow you to embed Third Party Services directly into your websites or mobile sites, such as through page framing techniques, to deliver content to or from Third Party Services or others (“Frames”). In these cases, Third Party Services may collect Personal Information from their Users":
    "nuestros Servicios pueden permitirle integrar Servicios de Terceros directamente en sus sitios web o móviles, como a través de técnicas de enmarcado de páginas, para entregar contenido hacia o desde Servicios de Terceros u otros (“Marcos”). En estos casos, los Servicios de Terceros pueden recopilar Información Personal de sus Usuarios de los Usuarios",
  "Characteristics of social networks": "Características de las redes sociales",
  "Characteristics of Social Networks": "Características de las Redes Sociales",
  "our Services may allow you to integrate certain social networking features, widgets, and single sign-on features, such as “Facebook Connect” or “Google Sign-in”":
    "nuestros servicios pueden permitirle que integre determinadas funciones de las redes sociales, widgets y funciones de inicio de sesión único, como “Facebook Connect” o “Google Sign-in”",
  "on their websites or mobile devices. These Social Media Features may collect certain Personal Information from Users, such as identifiers (name, alias, unique personal identifier, online identifier, Internet Protocol address, email address, etc.) or other similar information. Social Media Features are either hosted by third parties or hosted directly on our Services":
    "en sus sitios web o móviles. Estas Características de las Redes Sociales pueden recopilar determinada Información Personal de los Usuarios de los Usuarios, como identificadores (nombre, alias, identificador personal único, identificador en línea, dirección de protocolo de Internet, dirección de correo electrónico, etc.) u otros similares. Las Características de las Redes Sociales están alojadas por terceros o directamente en nuestros Servicios",
  "Please note that in the examples mentioned in Section 4.6, WebComprehensive is only acting as an intermediary platform and allows you to engage such Third Party Services (including, but not limited to, Third Party Developers, Third Party Applications, and Social Media Features) with which you interact directly and at your discretion. In this regard, WebComprehensive acts as a service provider to you and discloses information to the Third Party Services on your behalf. WebComprehensive will share your Users' Personal Information only in accordance with your instructions or with your permission and has and will not have any responsibility or obligation in connection with the processing of such Personal Information by such Third Party Services":
    "Tenga en cuenta que, en los ejemplos mencionados en la Sección 4.6, WebComprehensive solo actúa como plataforma intermediaria y le permite contratar dichos Servicios de Terceros (incluidos, entre otros, Desarrolladores Terceros, Aplicaciones de Terceros y Características de las Redes Sociales) con los que usted interactúe de manera directa y a criterio suyo. En este sentido, WebComprehensive actúa como un proveedor de servicios en relación con usted y divulga información para los Servicios de Terceros en nombre suyo. WebComprehensive compartirá la Información Personal de sus Usuarios de los Usuarios solo de acuerdo con sus instrucciones o con su permiso y no tiene ni tendrá responsabilidad u obligación alguna en relación con el procesamiento de dicha Información Personal por dichos Servicios de Terceros",
  "WebComprehensive does not control or have responsibility for the actions or policies of any Third Party Service, and your use of any Third Party Service is at your own risk. We encourage you to review any privacy policies related to a Third Party Service and to ask any Third Party Service for any clarifications you may need before deciding to install or use its services":
    "WebComprehensive no controla las acciones ni las políticas de ningún Servicio de Terceros ni tiene responsabilidad por ellas, y el uso que haga de los Servicios de Terceros es a su propio riesgo. Le recomendamos que revise todas las políticas de privacidad relacionadas con un Servicio de Terceros y que pida a dicho Servicio de Terceros las aclaraciones que sean necesarias antes de decidirse por la instalación o el uso de sus servicios",
  "We may share the Personal Information of our Users and Users of Users with various third parties, including certain service providers and law enforcement officials. Personal Information may be shared only in accordance with this policy":
    "Podemos compartir la Información Personal de nuestros Usuarios y de los Usuarios de los Usuarios con distintos terceros, incluidos determinados proveedores de servicios y oficiales de aplicación de la ley. La Información Personal podrá compartirse únicamente de conformidad con esta política",
  "Where do we process and store your “personal information”?":
    "¿Dónde procesamos y almacenamos su “información personal“?",
  "WebComprehensive processes data about individuals from all over the world and may transfer your Personal Information outside of your country. Your security, privacy and confidentiality are our priority and that is why WebComprehensive conducts a verification process that includes assessing the privacy laws in such countries to confirm that they have robust data protection laws":
    "WebComprehensive procesa datos sobre personas de todo el mundo y puede transferir su Información Personal fuera de su país. Su seguridad, privacidad y confidencialidad son nuestra prioridad y es por eso que WebComprehensive hace un proceso de verificación que incluye la evaluación de las leyes de privacidad en dichos países para confirmar que tengan leyes de protección de datos robustas",
  "User personal information is managed by WebComprehensive in Argentina, which the European Commission considers to be a country that offers an adequate level of protection of personal information of residents of EU Member States.":
    "La información personal del usuario es supervisada por WebComprehensive en Argentina, que la Comisión Europea considera como un país que ofrece un nivel adecuado de protección de la información personal de los residentes de los Estados miembros de la U.E.",
  see: "ver",
  "If you are located in Europe, the United Kingdom or Switzerland, when we transfer your Personal Information to a location outside Europe, we will ensure that either (i) there is a level of protection that the European Commission considers adequate or (ii) that the relevant Standard Contractual Clauses are in place (i.e. the relevant module of the Standard Contractual Clauses for the transfer of personal data to other countries in accordance with Regulation (EU) 2016/679 of the European Parliament and of the Council of 4 June 2021, available":
    "Si usted está en Europa, el Reino Unido o Suiza, cuando transfiramos su Información Personal a una ubicación fuera de Europa, nos aseguraremos (i) de que haya un nivel de protección que la Comisión Europea considere adecuado o (ii) de que las Cláusulas Contractuales Tipo correspondientes estén vigentes (es decir, el módulo correspondiente de las Cláusulas Contractuales Tipo para la transferencia de datos personales a otros países de conformidad con el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 4 de junio de 2021, disponible",
  "and the Information Commissioner's Office (ICO) EU Standard Contractual Clauses Addendum on International Transfer of Data, version B1.0, effective from 21 March 2022, as revised pursuant to Article 18 of the Mandatory Clauses). Where WebComprehensive transfers Personal Information from the EU to another country that, in the European Commission's opinion, does not adequately protect your information, WebComprehensive ensures that additional measures have been taken to comply with European data protection laws":
    "y el Apéndice sobre la Transferencia Internacional de Datos de las Cláusulas Contractuales Tipo de la Comisión de la UE de la Oficina del Comisario de Información (ICO), versión B1.0, vigente desde el 21 de marzo de 2022, según las revisiones en virtud del artículo 18 de las Cláusulas Obligatorias). Cuando WebComprehensive transfiera Información Personal de la UE a otro país que, a criterio de la Comisión Europea, no proteja adecuadamente su información, WebComprehensive garantiza que se han tomado medidas adicionales para cumplir con las leyes europeas de protección de datos",
  "Privacy Shield Certification: WebComprehensive and its affiliates participate in and certify compliance with the EU-US Privacy Shield Framework and the Swiss-US Privacy Shield Framework. For more information about the Privacy Shield Framework, visit the U.S. Department of Commerce's Privacy Shield List, available":
    "Certificación del escudo de privacidad: WebComprehensive y sus afiliadas, participan en y certifican el cumplimiento del Marco del Escudo de Privacidad UE-EE. UU. y del Marco del Escudo de Privacidad Suiza-EE. UU. Para obtener más información sobre el Marco del Escudo de Privacidad, visite la Lista del Escudo de Privacidad del Departamento de Comercio de los EE. UU., disponible",
  "With respect to Personal Information received or transferred under the Privacy Shield Framework, WebComprehensive is subject to the regulatory enforcement authority of the U.S. Federal Trade Commission (FTC). In compliance with the Privacy Shield Principles, WebComprehensive commits to resolve complaints about our collection or use of your personal information. EU and Swiss individuals with inquiries or complaints about our Privacy Shield policy should first contact us at: business@webcomprehensive.com":
    "En relación con la Información Personal que se reciba o transfiera dentro del Marco del Escudo de Privacidad, WebComprehensive está sujeta a la autoridad de aplicación reglamentaria de la Comisión Federal de Comercio (FTC) de los EE. UU. En cumplimiento con los Principios del Escudo de Privacidad, WebComprehensive se compromete a resolver las quejas sobre nuestra recopilación o uso de su información personal. Las personas de la UE y de Suiza que tengan consultas o quejas sobre nuestra política en relación con el Escudo de Privacidad primero deben contactarnos en: business@webcomprehensive.com",
  "WebComprehensive has further committed to refer unresolved Privacy Shield complaints to an alternative dispute resolution provider located in the United States. If you do not receive timely acknowledgment of your complaint from us, or if we do not resolve your complaint to your satisfaction, you may (under certain conditions described on the Privacy Shield website) invoke binding arbitration when other dispute resolution procedures have been exhausted. If you have an unresolved privacy or data use concern that we have not addressed satisfactorily, please contact our third-party dispute resolution provider in the U.S. at https://feedback-form.truste.com/watchdog/request for more information or to file a complaint. This service is provided at no cost to you":
    "WebComprehensive se ha comprometido además a derivar las quejas no resueltas relacionadas con el Escudo de Privacidad a un proveedor alternativo de resolución de conflictos ubicado en los Estados Unidos. Si no recibe de nosotros un acuse de recibo oportuno sobre su queja o si no resolvimos su queja de manera satisfactoria, puede (según determinadas condiciones que se describen en el sitio web sobre el Escudo de Privacidad) solicitar el arbitraje vinculante cuando los otros procedimientos de resolución de conflictos se hayan agotado. Si tiene una inquietud sin resolver sobre la privacidad o el uso de los datos que no hayamos respondido de manera satisfactoria, comuníquese con nuestro proveedor externo de resolución de conflictos en los EE. UU. en https://feedback-form.truste.com/watchdog/request para obtener más información o presentar una queja. Este servicio se brinda sin costo para usted",
  "For the avoidance of doubt, WebComprehensive does not rely on the Privacy Shield as a mechanism for the transfer of personal data protected by the GDPR. Currently, WebComprehensive only uses other transfer mechanisms, as detailed above, including, but not limited to, standard contractual clauses or adequacy decisions":
    "Para evitar dudas, WebComprehensive no depende del Escudo de Privacidad como mecanismo para la transferencia de datos personales protegidos por el RGPD. Actualmente, WebComprehensive solo usa otros mecanismos de transferencia, como se detalla más arriba, entre otros, las cláusulas contractuales tipo o las decisiones de adecuación",
  "We may store and process Personal Information in the United States, Europe, Israel, or other jurisdictions, either ourselves or with the help of our affiliates and our service providers. WebComprehensive's data storage providers are contractually obligated to protect and secure your data":
    "Podemos almacenar y tratar Información Personal en los Estados Unidos, Europa, Israel u otras jurisdicciones, ya sea nosotros mismos o con ayuda de nuestras afiliadas y nuestros proveedores de servicios. Los proveedores de almacenamiento de datos de WebComprehensive tienen la obligación contractual de proteger y garantizar la seguridad de sus datos",
  "Among other things, WebComprehensive will ensure that there is an adequate level of protection or that the Standard Contractual Clauses apply for the international transfer of our EU users' data":
    "Entre otras cosas, WebComprehensive se asegurará de que haya un nivel adecuado de protección o de que se apliquen las Cláusulas Contractuales Estándar para la transferencia internacional de los datos de nuestros usuarios de la UE",
  "Personal Information of Users of Users":
    "Información Personal de los Usuarios de los Usuarios",
  "WebComprehensive may collect, store and process certain Personal Information of Users of the Users":
    "WebComprehensive podrá recopilar, almacenar y tratar determinada Información Personal de los Usuarios de los Usuarios",
  "User Information of Users": "Información de los Usuarios de los Usuarios",
  "solely on behalf of and at the instruction of our Users. For example, each of our Users may import their email contacts from third party services such as Gmail or collect and manage contacts through their User Website. Such contacts are then stored with WebComprehensive, on behalf of the User":
    "únicamente en nombre de nuestros Usuarios y previa instrucción de éstos. Por ejemplo, cada uno de nuestros Usuarios podrá importar sus contactos de correo electrónico desde servicios de terceros como Gmail o recolectar y gestionar contactos a través de su Sitio Web de Usuario. Dichos contactos se almacenan entonces con WebComprehensive, en nombre del Usuario",
  "For such purposes, WebComprehensive shall act and be deemed to be a “Data Processor” and not the “Data Controller” (as such capitalized terms are defined in the European Union General Data Protection Regulation [“GDPR”]) of such User Information":
    "Para tales fines, WebComprehensive actuará y se considerará como un “Encargado del Tratamiento” y no como el “Responsable del Tratamiento” (según se definen dichos términos con mayúscula inicial en el Reglamento General de Protección de Datos de la Unión Europea [“RGPD”]) de dicha Información de los Usuarios de los Usuarios",
  "Users who control and operate such User Websites shall be deemed to be “Data Controllers“ of such User Information and are responsible for complying with all laws and regulations applicable to the collection and control of such User Information, including all privacy and data protection laws of all applicable jurisdictions":
    "Los Usuarios que controlan y operan dichos Sitios Web de Usuario se considerarán “Responsables del Tratamiento” de dicha Información de los Usuarios de los Usuarios, compitiéndoles cumplir con todas las leyes y reglamentos aplicables a la recopilación y el control de dicha Información de los Usuarios de los Usuarios, entre ellas todas las leyes en materia de privacidad y protección de datos de todas las jurisdicciones correspondientes",
  "The Processing of Users' Personal Information will take place within the territory of the European Union, Israel or a third country, territory or one or more specified sectors within such third country, for which the European Commission has decided that it ensures an adequate level of protection and that, therefore, the processing and transfer will be carried out in accordance with the Data Processing Addendum - Users (“DPA“). Any transfer and processing to a country that is outside the European Union and that does not guarantee an adequate level of protection according to the European Commission will be done according to an approved transfer mechanism, as detailed in the data protection agreement":
    "El Tratamiento de la Información Personal de los Usuarios de los Usuarios tendrá lugar dentro del territorio de la Unión Europea, Israel o un país tercero, territorio o uno o más sectores especificados dentro de dicho país tercero, para el cual la Comisión Europea haya decidido que garantiza un nivel adecuado de protección y que, por lo tanto, el tratamiento y la transferencia se realizarán conforme al Anexo sobre Tratamiento de Datos - Usuarios (“DPA”). Toda transferencia y procesamiento a un país que esté fuera de la Unión Europea y que no garantice un nivel adecuado de protección según la Comisión Europea se hará según un mecanismo aprobado de transferencia, como se detalla en el acuerdo de protección de datos",
  "You are responsible for the security, integrity, and authorized use of Users' Personal Information, as well as for obtaining consents and permissions, and providing data subjects with notices regarding their rights and fair processing necessary for the collection and use of such Personal Information":
    "Usted es responsable de la seguridad, integridad y uso autorizado de la Información Personal acerca de los Usuarios de los Usuarios, así como de obtener consentimientos y permisos, además de proporcionar a los interesados avisos relativos a sus derechos y al tratamiento justo necesarios para la recopilación y el uso de dicha Información Personal",
  "WebComprehensive cannot provide legal advice to Users or Users of Users; however, we recommend that all Users post and maintain clear and comprehensive privacy policies on their User Websites in compliance with applicable laws and regulations, and that all Users of Users carefully read such policies and ensure that they understand them and, to the extent required by applicable law, give their consent thereto":
    "WebComprehensive no puede proporcionar asesoramiento legal a los Usuarios o a los Usuarios de los Usuarios; sin embargo, recomendamos que todos los Usuarios publiquen y mantengan políticas de privacidad claras y completas en sus Sitios Web de Usuario de conformidad con las leyes y reglamentos aplicables, y que todos los Usuarios de los Usuarios lean atentamente esas políticas y se aseguren de que las entienden y, en la medida en que lo exija la ley aplicable, otorguen su consentimiento al respecto",
  "For more information about how WebComprehensive may handle Users' Users' Personal Information (which may be relevant to any specific notices you provide to Users' Users) and/or the consent you obtain from them, please see the sections":
    "Para obtener más información sobre la forma en que WebComprehensive puede gestionar la Información Personal de los Usuarios de los Usuarios (que podría ser pertinente para cualquier aviso específico que usted proporcione a los Usuarios de los Usuarios) y/o para el consentimiento que obtenga de ellos, consulte las secciones",
  "If you are a visitor, user or customer of any of our Users, please read the following":
    "Si usted es un visitante, usuario o cliente de cualquiera de nuestros Usuarios, lea lo siguiente",
  "WebComprehensive does not have a direct relationship with the Users of Users whose Personal Information it processes. If you are a visitor, user, or customer of any of our Users and would like to make a request or inquiry regarding your Personal Information, please contact such User(s) directly. For example, if you would like to make a request to access, correct, amend, or delete inaccurate Personal Information that WebComprehensive processes on behalf of its Users, please direct your inquiry to the appropriate User (i.e., whoever is the “Data Controller” of such data). If our Users request WebComprehensive to delete any Users of Users' Personal Information, we will respond to such requests in a timely manner, upon verification, and in accordance with applicable law (for example, thirty (30) days under the GDPR). Unless our Users indicate otherwise, we will retain Users' Personal Information for the period set out below in":
    "WebComprehensive no tiene una relación directa con los Usuarios de los Usuarios cuya Información Personal somete a tratamiento. Si usted es un visitante, usuario o cliente de cualquiera de nuestros Usuarios y quisiera hacer alguna solicitud o consulta relacionada con su Información Personal, póngase en contacto directamente con dicho(s) Usuario(s). Por ejemplo, si usted desea hacer una solicitud para acceder, corregir, modificar o suprimir Información Personal inexacta que WebComprehensive trate en nombre de sus Usuarios, dirija su consulta al Usuario que corresponda (es decir, a quien sea el “Responsable del Tratamiento” de tales datos). Si nuestros Usuarios le solicitan a WebComprehensive que suprima alguna Información Personal de los Usuarios de los Usuarios, responderemos a dichas solicitudes de manera oportuna, previa verificación y de conformidad con las leyes aplicables (por ejemplo, treinta (30) días según el RGPD). A menos que nuestro Usuario indique lo contrario, conservaremos la Información Personal de los Usuarios de los Usuarios durante el período que se establece adelante en la",
  Section: "Sección",
  "WebComprehensive may collect and process Personal Information about Users of Users":
    "WebComprehensive podrá recopilar y tratar Información Personal sobre los Usuarios de los Usuarios",
  "We do this only on behalf of our Users, and at their instructions":
    "Hacemos esto únicamente en nombre de nuestros Usuarios, y previa instrucciones de estos",
  "Our Users are solely responsible for the Personal Information of Users of Users, including its legality, security and integrity. WebComprehensive has no direct relationship with any of its Users' Users. If you are a user of a user, please contact that user directly":
    "Nuestros Usuarios son los únicos responsables de la Información Personal de los Usuarios de los Usuarios, inclusive de su legalidad, seguridad e integridad. WebComprehensive no tiene relación directa con ninguno de los Usuarios de sus Usuarios. Si usted es un usuario de un usuario, por favor póngase en contacto con dicho usuario directamente",
  "Use of cookies and other third-party technologies":
    "Uso de cookies y otras tecnologías de terceros",
  "We and our Third Party Service Providers use cookies and other similar technologies":
    "Nosotros y nuestros Proveedores de Servicios de Terceros utilizamos cookies y otras tecnologías similares",
  "to provide our Service and ensure that it functions correctly, to analyse our performance and marketing activities, as well as to personalize your experience":
    "para ofrecer nuestro Servicio y asegurarnos de que funcione correctamente, para analizar nuestro rendimiento y actividades de marketing, así como para personalizar su experiencia",
  "You can find out more about how we use cookies and how you can exercise control over them in our Cookie Policy":
    "Puede obtener más información sobre cómo utilizamos las cookies y cómo puede ejercer control sobre ellas en nuestra Política de Cookies",
  "Please note that we do not change our practices in response to a “Do Not Track” signal in the HTTP header from a browser or mobile application; however, most browsers allow you to control cookies, including whether or not to accept them and how to delete them. You can set most browsers to notify you if you receive a cookie, or you can choose to block cookies with your browser":
    "Tenga en cuenta que no cambiamos nuestras prácticas en respuesta a una señal de “No rastrear” en el encabezado HTTP de un navegador o aplicación móvil; sin embargo, la mayoría de los navegadores le permiten controlar las cookies, lo cual incluye si las acepta o no y cómo eliminarlas. Puede configurar la mayoría de los navegadores para que le avise si recibe una cookie, o puede optar por bloquear las cookies con su navegador",
  "We and certain third-party services may use cookies and similar tracking technologies while providing our services":
    "Nosotros y determinados servicios de terceros podemos utilizar cookies y tecnologías de rastreo similares mientras brindamos nuestros servicios",
  "These technologies are primarily used for stability, security, functionality, performance, and advertising purposes":
    "Estas tecnologías se utilizan principalmente con fines de estabilidad, seguridad, funcionalidad, rendimiento y publicidad",
  "WebComprehensive Communications": "Comunicaciones de WebComprehensive",
  "Promotional messages": "Mensajes promocionales",
  "We may use your Personal Information to send you promotional content and messages via email, text messages, notices within our platform, marketing calls, and other similar forms of communication from WebComprehensive or our partners (acting on behalf of WebComprehensive) through these means":
    "Podemos utilizar su Información Personal para enviarle contenido y mensajes promocionales por correo electrónico, mensajes de texto, avisos dentro de nuestra plataforma, llamadas de marketing y otras formas similares de comunicación de WebComprehensive o de nuestros socios (que actúen en nombre de WebComprehensive) a través de esos medios",
  "If you do not wish to receive such promotional messages or calls, you may notify WebComprehensive at any time or follow the instructions contained in the promotional communications you receive to “unsubscribe” or “STOP” them":
    "Si no desea recibir dichos mensajes o llamadas promocionales, puede notificar a WebComprehensive en cualquier momento o seguir las instrucciones contenidas en las comunicaciones promocionales que reciba para “darse de baja” (unsubscribe) o detenerlos (STOP)",
  "Service and Billing Messages": "Mensajes de Servicio y Facturación",
  "Similarly, WebComprehensive may contact you to let you know important information about our Services or your use of them. For example, we may send you a notice (by any means available to us) if a particular Service is temporarily suspended for maintenance; respond to a support request or email; send you reminders or notices regarding upcoming or late payments on your current or future subscriptions; forward abuse complaints regarding your User Website; or notify you of material changes to our Services":
    "De igual modo, WebComprehensive puede ponerse en contacto con usted para darle a conocer Información importante acerca de nuestros Servicios o sobre su utilización. Por ejemplo, podemos enviarle un aviso (por cualquiera de los medios que tenemos a nuestra disposición) si un determinado Servicio está suspendido temporalmente por mantenimiento; responder a una solicitud o correo electrónico de asistencia técnica; enviarle recordatorios o avisos con respecto a pagos futuros o tardíos en sus suscripciones actuales o futuras; reenviar quejas de abuso con respecto a su Sitio Web de Usuario; o notificarle de cambios sustanciales en nuestros Servicios",
  "It is important that you are able to receive such messages at all times. For this reason, you will not be able to opt out of receiving these types of Service and Billing messages unless you are no longer a WebComprehensive User (which you can do by deactivating your account)":
    "Es importante que pueda recibir dichos mensajes en todo momento. Por esta razón, usted no podrá optar por no recibir este tipo de mensajes de Servicio y Facturación a menos que haya dejado de ser Usuario de WebComprehensive (lo que se puede hacer desactivando su cuenta)",
  "We may send you promotional messages and content. You can easily opt out of receiving promotional messages by contacting us or by unsubscribing":
    "Podemos enviarle mensajes promocionales y contenido. Puede darse de baja de recibir mensajes promocionales con facilidad, poniéndose en contacto con nosotros o cancelando su suscripción",
  "We may also contact you to send you service-related and billing-related messages and content. You may not opt out of receiving these types of messages":
    "También podemos ponernos en contacto con usted para enviarle mensajes y contenidos relacionados con el servicio y la facturación. No podrá darse de baja de la recepción de este tipo de mensajes",
  "Your rights in relation to your Personal Information":
    "Sus derechos en relación con su Información Personal",
  "WebComprehensive believes it is imperative that all WebComprehensive users have control over their Personal Information. Therefore, depending on how you use the WebComprehensive Services, you may have the right to request access to, receive copies of, update, correct or delete, port certain Personal Information to another service, restrict or reject certain uses of your Personal Information (for example, for direct marketing purposes). In addition, where we ask for your consent to process your Personal Information (for example, for direct marketing), you may withdraw your consent at any time, with such withdrawal taking effect from the date of your withdrawal":
    "WebComprehensive considera que es imperativo que todos los usuarios de WebComprehensive tengan control de su Información Personal. Por eso, según cómo use los Servicios de WebComprehensive, puede tener derecho a solicitar acceso, recibir copias, actualizar, corregir o eliminar, hacer la portabilidad de determinada Información Personal a otro servicio, restringir o rechazar determinados usos de su Información Personal (por ejemplo, para fines de marketing directo). Además, cuando solicitemos su consentimiento para procesar su Información Personal (por ejemplo, para marketing directo), puede retirar su consentimiento en cualquier momento, y dicha suspensión tendrá efecto en adelante",
  "WebComprehensive will not charge you more if you exercise any of these rights and will continue to provide you with the same level of service":
    "WebComprehensive no le cobrará más si ejerce cualquiera de esos derechos y le seguirá proporcionando el mismo nivel de servicio",
  "If you are a WebComprehensive user, you can access and correct much of your Personal Information directly through your account, or by using the online forms that WebComprehensive makes available to you (as detailed below). You can also exercise your rights by sending your request to business@webcomprehensive, or by contacting our customer service department. When we receive your request to exercise your rights, we may take steps to verify your identity before complying with the request to protect your privacy and security":
    "Si usted es un usuario de WebComprehensive, puede acceder a gran parte de su Información Personal y corregirla directamente a través de su cuenta, o mediante los formularios en línea que WebComprehensive pone a su disposición (como se detalla más abajo). También puede ejercer sus derechos enviando su solicitud a business@webcomprehensive, o bien contactando con nuestro departamento de atención al cliente. Cuando recibamos su solicitud para ejercer sus derechos, podemos tomar los pasos necesarios para verificar su identidad antes de cumplir con la solicitud para proteger su privacidad y seguridad",
  "Before complying with your request, we may ask you for additional information to confirm your identity and for security purposes. We reserve the right to charge a fee where permitted by law (for example, if your request is unfounded or excessive)":
    "Antes de cumplir con su solicitud, podemos pedirle información adicional para confirmar su identidad y con fines de seguridad. Nos reservamos el derecho de cobrar una tarifa siempre y cuando la legislación lo permita (por ejemplo, si su solicitud carece de fundamento o es excesiva)",
  "You have the right to lodge a complaint with your local data protection supervisory authority (but we recommend that you contact us first)":
    "Tiene derecho a presentar una queja ante la autoridad local de supervisión de la protección de datos (pero le recomendamos que se ponga en contacto con nosotros primero)",
  "If you are a WebComprehensive User and would like to receive a copy of, access, and/or request corrections to your Personal Information (whether to your own or to the Users of Users) that you have stored with us, or would like to request a list of the Personal Information (if any) relating to you that we have disclosed to third parties for their direct marketing purposes, please follow the instructions in these Help Center articles: “Recovering Your WebComprehensive Account Data” or “Permanently Deleting Your WebComprehensive Account”":
    "Si usted es un Usuario de WebComprehensive y desea recibir una copia de la Información Personal que tiene almacenada con nosotros, tener acceso a ella y/o solicitarnos que le hagamos correcciones (ya sea a la suya o a la de los Usuarios de los Usuarios) o desea solicitar una lista de la Información Personal (si es el caso) relativa a usted que hayamos divulgado a terceros con fines de marketing directo, siga las instrucciones que se indican en estos artículos del Centro de Ayuda: “Recuperar los datos de su cuenta de WebComprehensive” o “Suprimir permanentemente su cuenta de WebComprehensive”",
  "You may also send your request to business@webcomprehensive. We will take all reasonable steps to comply with your request promptly (except where we require additional information from you in order to comply), subject to applicable legal and other considerations":
    "También puede enviar su solicitud a business@webcomprehensive. Tomaremos todas las medidas razonables para cumplir con su solicitud con prontitud (salvo si necesitásemos más información suya para cumplir con ella), quedando sujeto a consideraciones jurídicas y otras que sean procedentes",
  "Please note that permanently deleting your WebComprehensive account will delete all of your Personal Information from WebComprehensive's databases. After completing this process, you will not be able to use any of your WebComprehensive Services and your account and all of your data will be permanently deleted, so WebComprehensive will not be able to restore your account or retrieve your data in the future. If you wish to contact our support channels in the future, your account will not be recognized by the system and support agents will not be able to locate the deleted account":
    "Tenga en cuenta que, al suprimirse de forma permanente su cuenta de WebComprehensive, se borra toda su Información Personal de las bases de datos de WebComprehensive. Tras completar este proceso, usted no podrá utilizar ninguno de sus Servicios de WebComprehensive y su cuenta y todos sus datos se eliminarán de modo permanente, por lo que WebComprehensive no podrá restaurar su cuenta ni recuperar sus datos en el futuro. Si desea ponerse en contacto con nuestros canales de asistencia técnica en el futuro, el sistema no reconocerá su cuenta y los agentes de asistencia técnica no podrán localizar la cuenta suprimida",
  "With respect to your Personal Information that you have stored with us, you have the right to: access your Personal Information, receive a copy, update, modify or delete it, exercise data portability (in certain cases), restrict or limit certain uses of your Personal Information, as well as withdraw the consent you gave us prior to processing":
    "Con respecto a su Información Personal que tenga almacenada con nosotros, usted tiene derecho a: acceder a su Información Personal, recibir una copia, actualizarla, modificarla o suprimirla, ejercer la portabilidad de datos (en determinados casos), restringir o limitar determinados usos de su Información Personal, así como retirar el consentimiento que nos dió antes del tratamiento",
  "Simply contact us by email, through our customer service team or, where available, by filling out our dedicated form":
    "Solo tiene que contactar con nosotros por correo electrónico, a través de nuestro equipo de atención al cliente o, cuando esté disponible, rellenando nuestro formulario específico",
  "You may also correct and/or update your Personal Information through your user account, if you have one. You may also delete your Personal Information by deleting your account entirely. We will respond to your requests within a reasonable time":
    "También puede corregir y/o actualizar su Información Personal a través de su cuenta de usuario, si dispone de una. También puede suprimir su Información Personal eliminando su cuenta por completo. Responderemos a sus solicitudes en un plazo razonable",
  "Questions and complaints": "Preguntas y quejas",
  "If you have questions or concerns about how we collect, use or disclose Personal Information, or if you believe that we have not complied with this Privacy Policy or applicable data protection laws, please contact us. Our contact information is listed at the end of this Privacy Policy":
    "Si tiene preguntas o inquietudes acerca de cómo recopilamos, utilizamos o revelamos la Información Personal, o si cree que no hemos cumplido con la presente Política de Privacidad o con la legislación aplicable en materia de protección de datos, comuníquese con nosotros. Nuestra información de contacto se indica al final de esta Política de Privacidad",
  "Our Data Protection Officer team will investigate the complaint and determine whether there has been a breach and what action to take if applicable. We take every privacy complaint very seriously and will take all reasonable steps to resolve your complaint promptly and in accordance with applicable law":
    "El equipo de nuestro Delegado de Protección de Datos investigará la queja y determinará si ha habido un incumplimiento y qué medida tomar en su caso. Tomamos cada queja sobre privacidad con la mayor seriedad y tomaremos todas las medidas razonables para resolver su queja con prontitud y de conformidad con la legislación aplicable",
  "You may lodge a complaint with your local data protection supervisory authority at any time; however, we recommend that you first contact us so that we can try to resolve it":
    "Puede presentar una queja ante la autoridad local de supervisión de la protección de datos en cualquier momento; sin embargo, le recomendamos que primero se ponga en contacto con nosotros para que podamos intentar resolverlo",
  "You may lodge a complaint with your local data protection supervisory authority at any time. Please contact us first so that we can try to resolve your concerns":
    "Puede presentar una queja ante la autoridad local de supervisión de la protección de datos en cualquier momento. Le rogamos que se ponga en contacto con nosotros primero para que podamos tratar de resolver sus inquietudes",
  "Data retention": "Retención de datos",
  "We may retain your Personal Information (as well as the Personal Information of Users of Users) for as long as your User Account is active, as set forth in this Privacy Policy or as necessary to provide you with our Services":
    "Podemos retener su Información Personal (así como la Información Personal de los Usuarios de los Usuarios) todo el tiempo que su Cuenta de Usuario esté activa, según se indica en la presente Política de Privacidad o según sea necesario para prestarle nuestros Servicios",
  "We may continue to retain your Personal Information after you deactivate your User Account and/or cease using any particular Services, as reasonably necessary to comply with our legal obligations, resolve disputes regarding our Users or Users' Users, prevent fraud and abuse, enforce our agreements, and/or protect our legitimate interests":
    "Podemos seguir reteniendo su Información Personal después de que usted desactive su Cuenta de Usuario y/o deje de utilizar cualesquiera Servicios en particular, como sea razonablemente necesario para cumplir con nuestras obligaciones legales, resolver controversias relativas a nuestros Usuarios o a los Usuarios de los Usuarios, prevenir fraudes y abusos, hacer cumplir nuestros acuerdos y/o proteger nuestros intereses legítimos",
  "To determine the appropriate retention period for Personal Information, we consider the amount, nature, and sensitivity of the data, the potential risk of harm from unauthorized use or disclosure of the data, the purposes for which we process it, and the applicable legal requirements":
    "Para determinar el periodo adecuado de retención de la Información Personal, consideramos la cantidad, la naturaleza y la sensibilidad de dichos datos, el potencial de riesgo de daño por el uso o la divulgación no autorizados de dichos datos, los fines para los que la procesamos y los requisitos legales aplicables",
  "We may retain your Personal Information for as long as your account is active, and for longer as necessary (for example, if we are legally required to retain it longer, or if it is needed to protect our interests)":
    "Podemos conservar su Información Personal todo el tiempo que su cuenta esté activa, y durante más tiempo según sea necesario (por ejemplo, si estamos legalmente obligados a conservarla por más tiempo, o si se necesita para proteger nuestros intereses)",
  Security: "Seguridad",
  "WebComprehensive has implemented security measures designed to protect the Personal Information you share with us, including physical, electronic, and procedural measures. Among other things, we offer secure HTTPS access to most areas of our Services, and the transmission of sensitive payment information (such as a credit card number) through our designated purchase forms is protected by an industry-standard SSL/TLS encrypted connection. We also regularly monitor our systems for potential vulnerabilities and attacks, and we regularly seek out new ways and new Third Party Services to further improve the security of our Services as well as the protection of the privacy of our Visitors and Users":
    "WebComprehensive ha puesto en marcha medidas de seguridad diseñadas para proteger la Información Personal que comparte con nosotros, entre ellas medidas físicas, electrónicas y de procedimientos. Entre otras cosas, ofrecemos acceso HTTPS seguro a la mayoría de las áreas de nuestros Servicios y la transmisión de información sensible de pagos (como un número de tarjeta de crédito) a través de nuestros formularios de compra designados está protegida por una conexión con cifrado SSL/TLS estándar de la industria. También monitoreamos con regularidad nuestros sistemas en busca de posibles vulnerabilidades y ataques, y regularmente buscamos nuevas formas y nuevos Servicios de Terceros para mejorar aún más la seguridad de nuestros Servicios, así como la protección de la privacidad de nuestros Visitantes y Usuarios",
  "Regardless of the measures and efforts undertaken by WebComprehensive, we cannot and do not guarantee the absolute protection and security of your Personal Information, the Personal Information of Users of Users, or any other information you upload, post or share with WebComprehensive or anyone else. We encourage you to establish strong passwords for your User Account and the User Website and to avoid providing us or anyone else with any sensitive Personal Information the disclosure of which may, in your judgment, cause you substantial or irreparable harm":
    "Independientemente de las medidas y los esfuerzos que lleva a cabo WebComprehensive, no podemos garantizar —y en efecto no garantizamos— la protección y la seguridad absolutas de su Información Personal, de la Información Personal de los Usuarios de los Usuarios o de cualquier otro tipo de información que usted suba, publique o comparta con WebComprehensive o cualquier otra persona. Le exhortamos a que establezca contraseñas seguras para su Cuenta de Usuario y el Sitio Web de Usuario y que evite proporcionar, a nosotros o a cualquier otra persona, cualquier Información Personal sensible cuya divulgación pueda causarle, a su juicio, un daño sustancial o irreparable",
  "In addition, because certain areas of our Services are less secure than others (for example, if you set your Support forum so that your requests are “public” rather than “private” or if you visit a non-SSL page) and because email and instant messaging are not recognized as secure forms of communication, we request and recommend that you do not share any Personal Information in any of these areas using any of these methods":
    "Además, debido a que determinadas áreas de nuestros Servicios son menos seguras que otras (por ejemplo, si configura su foro de Asistencia Técnica para que sus solicitudes sean “públicas” en lugar de “privadas” o si visita una página que no sea SSL) y dado que el correo electrónico y la mensajería instantánea no se reconocen como formas seguras de comunicación, le solicitamos y recomendamos que no comparta ninguna Información Personal en ninguna de estas áreas mediante alguno de estos métodos",
  "If you have questions about the security of our Services, we invite you to contact us":
    "Si tiene preguntas acerca de la seguridad de nuestros Servicios, le invitamos a ponerse en contacto con nosotros",
  "WebComprehensive values the security of our customers' Personal Information and we do everything in our power to protect it":
    "WebComprehensive valora la seguridad de la Información Personal de nuestros clientes y hacemos todo lo que está a nuestro alcance para protegerla",
  "However, since we cannot guarantee absolute protection, we urge you to exercise caution, set a strong password for your account, and avoid submitting any sensitive information that, if exposed, could cause significant harm":
    "Sin embargo, dado que no podemos garantizar una protección absoluta, le exhortamos a actuar con cautela, a establecer una contraseña segura para su cuenta y a evitar el envío de toda información sensible que, de exponerse, pueda causar un daño considerable",
  "Third party websites": "Sitios web de terceros",
  "We are not responsible for the privacy practices of such websites or services. We encourage you to be aware of when you leave our Services and to read the privacy statements of each website and service you visit before providing your Personal Information. This Privacy Policy does not apply to such linked websites and third-party services":
    "No somos responsables de las prácticas de privacidad de dichos sitios web o servicios. Le exhortamos a que esté consciente del momento en que sale de nuestros Servicios, así como a leer las declaraciones de privacidad de cada sitio web y servicio que visite antes de proporcionar su Información Personal. La presente Política de Privacidad no se aplica a dichos sitios web vinculados y servicios de terceros",
  "Our Services may contain links to other websites or services":
    "Nuestros servicios pueden contener vínculos a otros sitios web o servicios",
  "Public Forums and User Content": "Foros públicos y contenido de usuarios",
  "Our Services offer publicly accessible blogs, communities, and support forums. Please be aware that any Personal Information you provide in any of these areas can be read, collected, and used by others who access them. To request removal of your Personal Information from our blogs, communities, or forums, please feel free to contact us":
    "Nuestros Servicios ofrecen blogs, comunidades y foros de asistencia técnica accesibles públicamente. Tenga en cuenta que cualquier Información Personal que usted proporcione en cualquiera de dichas áreas la pueden leer, recopilar y utilizar otras personas que tengan acceso a ella. Para solicitar la supresión de su Información Personal de nuestros blogs, comunidades o foros, no dude en ponerse en contacto con nosotros",
  "In some cases, we may not be able to remove your Personal Information from those areas. For example, if you use a third-party application to post a comment (e.g., Facebook's social plugin application) while logged in to your profile associated with that third party, you must log in to that application or contact its provider if you wish to remove Personal Information that you have posted on that platform":
    "En algunos casos, es posible que no podamos suprimir su Información Personal de esas áreas. Por ejemplo, si utiliza una aplicación de un tercero para publicar un comentario (p. ej., la aplicación de plugin social de Facebook) mientras está conectado a su perfil relacionado con dicho tercero, debe iniciar sesión en dicha aplicación o ponerse en contacto con su proveedor si desea suprimir Información Personal que haya publicado en esa plataforma",
  "In any case, we recommend that you do not publish any Personal Information (by any means) that you do not wish to make known":
    "En cualquier caso, le recomendamos no publicar ninguna Información Personal (por ningún medio) que no desee dar a conocer",
  "If you upload any User Content to your User Account or post it on your User Website and/or otherwise provide it as part of your use of any Service, you do so at your own risk. We have put in place appropriate security measures to protect your Personal Information. However, we cannot control the actions of other Users or members of the public who may have access to your User Content, and we are not responsible for the circumvention of any privacy settings or security measures you or we have put in place on your User Website (including, for example, password-protected areas of your User Website). You understand and acknowledge that, even after you or we have removed it, copies of User Content may remain viewable in cached and archived pages or if third parties (including any of the Users of the Users) have copied or stored such User Content":
    "Si sube algún contenido de usuario a su Cuenta de Usuario o lo publica en su Sitio Web de Usuario y/o lo proporciona de alguna otra forma como parte del uso de algún Servicio, lo hace bajo su cuenta y riesgo. Hemos establecido medidas de seguridad adecuadas para proteger su Información Personal. Sin embargo, no podemos controlar las acciones de otros Usuarios o integrantes del público que puedan tener acceso a su Contenido de Usuario, y no somos responsables de la elusión de alguna configuración de privacidad o de medidas de seguridad que usted o nosotros hayamos establecido en su Sitio Web de Usuario (entre ellas, por ejemplo, áreas protegidas con contraseña en su Sitio Web de Usuario). Usted entiende y reconoce que, incluso después de que usted o nosotros o hayamos procedido a su eliminación, copias del Contenido de Usuario pueden permanecer visibles en páginas en caché y archivadas o si terceros (entre ellos cualquiera de los Usuarios de los Usuarios) han copiado o almacenado dicho Contenido de Usuario",
  "For clarity, we recommend that you do not upload or post any information that you do not wish to make public":
    "Para mayor claridad, le recomendamos no subir ni publicar ninguna información que no desee hacer pública",
  "Please avoid posting any Personal Information in any of the public areas of our Services or on your own website if you do not want it to be publicly available":
    "Evite publicar alguna Información Personal en alguna de las áreas públicas de nuestros Servicios o en su propio sitio web si no desea que esté disponible públicamente",
  "Updates and interpretation": "Actualizaciones e interpretación",
  "We may update this Privacy Policy as required by applicable law, as well as to reflect changes we make in our collection, use, and storage practices of Personal Information. If we make any changes that we consider to be “material” (in our sole, good faith discretion), we will notify you (using one of the notification methods set forth in Section 15.3 of the Terms of Use) prior to the change taking effect. With respect to any updates to this Privacy Policy, we will notify you, seek your consent, and/or take such other actions as may be required by applicable law. We encourage you to periodically review this page for the latest information on our privacy practices. Unless otherwise stated, our most current Privacy Policy applies to all information we have about you and Users of Users, with respect to our Website, WebComprehensive Applications, Mobile Applications, and other Services":
    "Podemos actualizar la presente Política de Privacidad según lo exija la ley aplicable, así como para reflejar cambios que realicemos en cuanto a la recopilación, el uso y las prácticas de almacenamiento de la Información Personal. Si realizamos algún cambio que consideremos “sustancial” (a nuestra entera discreción de buena fe), se lo notificaremos (mediante uno de los métodos de notificación que se establecen en la sección 15.3 de los Términos de Uso) antes de que el cambio surta efecto. En lo que respecta a cualquier actualización de la Política de Privacidad, se la notificaremos, solicitaremos su consentimiento y/o tomaremos cualesquiera otras medidas, según lo exija la ley aplicable. Le exhortamos a que consulte periódicamente esta página para obtener la Información más reciente sobre nuestras prácticas de privacidad. Salvo indicación en contrario, nuestra Política de Privacidad más actualizada se aplica a toda la información que tenemos sobre usted y sobre los Usuarios de los Usuarios, con respecto a nuestro Sitio Web, Aplicaciones de WebComprehensive, Aplicaciones Móviles y otros Servicios",
  "Any heading, legend, or section title appearing in this document, as well as any explanations or summaries included under the column":
    "Todo encabezado, leyenda o título de sección que figura en el presente documento, así como las explicaciones o resúmenes que se incluyen bajo la columna",
  "on the right, are provided for convenience only and do not in any way define or explain any section or provision hereof, nor are they legally binding on any of us in any way":
    "de la derecha, se proporcionan con el único fin de facilitar la consulta y de manera alguna definen o explican ninguna sección o disposición del presente, ni obligan legalmente a ninguno de nosotros en absoluto",
  "This Privacy Policy was written in English and may be translated into other languages for your convenience. To access and view other language versions, please change the language setting of your WebComprehensive Website. If a translated (non-English) version of this Privacy Policy conflicts in any way with your English version, the provisions of the latter will take precedence":
    "La presente Política de Privacidad fue escrita en inglés y podrá traducirse a otros idiomas para su comodidad. Para acceder a versiones en otros idiomas y verlas, cambie el ajuste de idioma de su Sitio Web de WebComprehensive. Si una versión traducida (distinta del inglés) de esta Política de Privacidad entra de alguna forma en conflicto con su versión en inglés, tendrán primacía las disposiciones de esta última",
  "We may modify this policy at any time. We will notify you of modifications as required by applicable law":
    "Podemos modificar esta política en cualquier momento. Le notificaremos acerca de las modificaciones según lo exija la legislación aplicable",
  "Only the left column is legally binding (as this column is for clarity only). Translated (non-English) versions of these Terms are provided for convenience only":
    "Únicamente la columna de la izquierda es jurídicamente vinculante (ya que esta columna es solo para mayor claridad). Se presentan versiones traducidas (distintas del inglés) de estos Términos solo para mayor comodidad",
  "Contact us": "Contáctenos",
  "If you have any questions about this Privacy Policy or would like to exercise any of your rights described in Sections 9 or 10, please refer to those sections or contact the data protection team at business@webcomprehensive.com. We will attempt to resolve all complaints regarding the use of your Personal Information in accordance with this Privacy Policy":
    "Si tiene alguna pregunta sobre esta Política de Privacidad o desea ejercer alguno de sus derechos descritos en las Secciones 9 o 10, consulte dichas secciones o póngase en contacto con el equipo responsable de protección de datos a través del correo electrónico business@webcomprehensive.com. Intentaremos resolver todas las quejas relativas al uso de su Información Personal de conformidad con esta Política de Privacidad",
  "For the purposes of the GDPR (Article 27), you may contact our EU representative at business@webcomprehensive.com":
    "A los efectos del RGPD (Artículo 27), puede ponerse en contacto con nuestro representante en la UE, en business@webcomprehensive.com",
  "For the purposes of the UK GDPR (Article 27), you may contact our WebComprehensive (UK) representative at business@webcomprehensive.com":
    "Para los fines del RGPD del Reino Unido (Artículo 27), puede comunicarse con nuestro representante de WebComprehensive (UK) en business@webcomprehensive.com",
  "Additional information for residents of the United States":
    "Información adicional para residentes en Estados Unidos",
  "This section of our Privacy Policy details what Personal Information we collect about you under applicable U.S. privacy laws and regulations, the sources of the Personal Information, the purposes of use and disclosure, and the categories of third parties that may receive that Personal Information. The information in this section applies to you if you are a U.S. resident in a state with an applicable privacy law or regulation":
    "Esta sección de nuestra Política de Privacidad detalla qué Información Personal recopilamos sobre usted bajo las leyes y regulaciones de privacidad aplicables en EE. UU., las fuentes de la Información Personal, los propósitos de uso y divulgación, y las categorías de terceros que pueden recibir esa Información Personal. La información de esta sección se aplica a usted si es residente de EE. UU. en un estado con una ley o reglamento de privacidad aplicable",
  "Please read this section in conjunction with the other sections of this Privacy Policy for a complete understanding of how we collect, use and disclose your personal information":
    "Por favor, lea esta sección en combinación con las otras secciones de esta Política de Privacidad para una comprensión completa de cómo recopilamos, utilizamos y divulgamos su información personal",
  "Your rights and how to exercise them": "Sus derechos y cómo ejercerlos",
  "Here is a brief summary of your rights":
    "He aquí un breve resumen de sus derechos",
  "Right to delete your data: At your request, we will delete the personal information we have collected about you, subject to certain legally permitted exceptions":
    "Derecho a eliminar sus datos: A petición suya, eliminaremos la información personal que hayamos recopilado sobre usted, salvo determinadas excepciones legalmente permitidas",
  "Please follow the instructions provided in this Help Center article, “Permanently Deleting Your WebComprehensive Account,” which explains how to delete your Personal Information. You may also choose to request deletion through this web form (in English) or by contacting us as described in this Privacy Policy":
    "Por favor, siga las instrucciones proporcionadas en este artículo del Centro de Ayuda: “Eliminación permanente de su cuenta de WebComprehensive“, en el que se explica cómo eliminar su Información Personal. También puede optar por solicitar la eliminación a través de este formulario web (en inglés) o poniéndose en contacto con nosotros tal y como se describe en esta Política de Privacidad",
  "Right to know and access your personal information: You have the right to ask us to confirm whether we process your personal information and a copy of the personal information we hold about you":
    "Derecho a conocer y acceder a su información personal: Tiene derecho a pedirnos que le confirmemos si procesamos su información personal y una copia de la información personal que conservamos sobre usted",
  "Please follow the instructions provided in this Help Center article, “Recovering Your WebComprehensive Account Data,” which explains how you can recover your Personal Information. You may also submit a request to access your Personal Information through this web form (in English) or by contacting us as described in this Privacy Policy":
    "Por favor, siga las instrucciones proporcionadas en este artículo del Centro de Ayuda: “Recuperación de los Datos de su Cuenta WebComprehensive“, en el que se explica cómo puede recuperar su Información Personal. También puede presentar una solicitud para acceder a su Información Personal a través de este formulario web (en inglés) o poniéndose en contacto con nosotros tal y como se describe en esta Política de Privacidad",
  "Right to correct inaccurate personal information: You have the right to request that we correct inaccurate personal information about you. Some personal information can be modified or corrected directly by you through our applications and websites":
    "Derecho a corregir información personal inexacta: Tiene derecho a solicitar que corrijamos la información personal inexacta sobre usted. Alguna información personal puede ser modificada o corregida directamente por usted a través de nuestras aplicaciones y sitios web",
  "If you have any questions, you may contact us using the method described in this Privacy Policy":
    "Si tiene alguna pregunta, puede ponerse en contacto con nosotros utilizando el método descrito en esta Política de Privacidad",
  "Right to Opt Out of the Sale of Your Personal Information: You may tell us not to “sell” your personal information in accordance with applicable law, even though we do not technically sell your information in the traditional sense. We may share certain categories of Personal Information with our Third Party Service Providers. However, current law uses a very broad (and somewhat scary) definition of “sale”":
    "Derecho a oponerse a la venta de su información personal: Puede decirnos que no ”vendamos” su información personal de acuerdo con la legislación aplicable, aunque técnicamente no vendamos su información en el sentido tradicional. Podemos compartir ciertas categorías de Información Personal con nuestros Proveedores de Servicios Terceros. Sin embargo, la normativa actual utiliza una definición muy amplia (y un tanto aterradora) de ”Venta”",
  "Right to limit the use of your personal information for targeted advertising (defined in California as “Sharing”): You may ask us not to use or disclose your personal information for targeted advertising. Please visit our Do Not Sell or Share My Personal Information page. You may also use the Global Privacy Control to indicate your opt-out preference. For more information about the GPC, please visit https://globalprivacycontrol.org":
    "Derecho a limitar el uso de su información personal para publicidad dirigida (definida en California como “Compartir“): Puede pedirnos que no utilicemos ni divulguemos su información personal para publicidad dirigida. Visite nuestra página No vendo ni comparto mi información personal (en inglés). También puede utilizar el Control Global de Privacidad para indicar su preferencia de exclusión. Para más información sobre el GPC, visite https://globalprivacycontrol.org",
  "You may exercise any of the rights described above, you may also send a request by email to business@webcomprehensive.com":
    "Puede ejercer cualquiera de los derechos descritos anteriormente, también puede enviar una solicitud por correo electrónico a business@webcomprehensive.com",
  "When you submit a request, we will usually ask you to provide an email address to verify your identity and prevent fraud":
    "Cuando envíe una solicitud, normalmente le pediremos que facilite una dirección de correo electrónico para verificar su identidad y evitar fraudes",
  "You may also designate an authorized agent to submit a request on your behalf":
    "También puede designar a un agente autorizado para que presente una solicitud en su nombre",
  "Depending on the nature of the request, we may authenticate the request by requesting additional information or documentation, such as account-related information, billing information used to purchase Paid Services, and the submission of specific documents":
    "Dependiendo de la naturaleza de la solicitud, podemos autenticar la solicitud solicitando información o documentación adicional, como información relacionada con la cuenta, información de facturación utilizada para adquirir Servicios de pago y la presentación de documentos específicos",
  "No discrimination": "No discriminación",
  "WebComprehensive does not discriminate against any person for exercising their rights under this section":
    "WebComprehensive no discrimina a ninguna persona por ejercer sus derechos en virtud de esta sección",
  "If we deny your request or are unable to fulfill it, you may appeal our decision by contacting us as described in this Privacy Policy to ask us to review your request again. Your communication must include “Appeal” in the subject line":
    "Si denegamos su solicitud o no podemos satisfacerla, puede apelar nuestra decisión poniéndose en contacto con nosotros tal como se describe en esta Política de Privacidad para pedirnos que revisemos de nuevo su solicitud. Su comunicación debe incluir “Apelación“ en el asunto",
  "Information categories": "Categorías de información",
  "In the past 12 months, we have collected the following categories of personal information (the same categories we collect from all users globally)":
    "En los últimos 12 meses, recopilamos las siguientes categorías de información personal (las mismas categorías que recopilamos de todos los usuarios a nivel mundial)",
  "Personal and online identifiers": "Identificadores personales y en línea",
  "Business and transaction information":
    "Información comercial y sobre transacciones",
  "Log information": "Información sobre registros",
  "Internet or other electronic network activity information":
    "Información sobre la actividad en Internet u otras redes electrónicas",
  "Inaccurate geolocation data (IP address only)":
    "Datos de geolocalización no precisos (sólo dirección IP)",
  "Audible, electronic, visual or similar information":
    "Información sonora, electrónica, visual o similar",
  "Inferences drawn from the above information":
    "Inferencias extraídas de la información anterior",
  "Sources of information": "Fuentes de información",
  "We collect personal information": "Recopilamos información personal",
  "You provide it to us when you register for our Services, sign up for any WebComprehensive event, subscribe to our blog(s) or newsletter(s), purchase and/or register domain names, use any of our Services; and/or when you contact us directly through any communication channel":
    "Nos la proporciona cuando se registra en nuestros Servicios, se inscribe en cualquier evento de WebComprehensive, se suscribe a nuestro(s) blog(s) o newsletter(s), compra y/o registra nombres de dominio, utiliza cualquiera de nuestros Servicios; y/o cuando se pone en contacto con nosotros directamente por cualquier canal de comunicación",
  "When you use the Services by visiting, downloading and/or using any of our Services":
    "Cuando utiliza los Servicios visitando, descargando y/o utilizando cualquiera de nuestros Servicios",
  "From third-party sources, such as security providers, fraud detection services, social media platforms, lead enhancement companies, and advertising and marketing partners":
    "De fuentes de terceros, como proveedores de seguridad, servicios de detección de fraudes, plataformas de redes sociales, empresas de mejora de prospectos y socios de publicidad y marketing",
  "Disclosure of information for commercial purposes":
    "Divulgación de información con fines comerciales",
  "In the preceding 12 months, we may have disclosed each of the categories of personal information listed above to our affiliates, third-party service providers, social media platforms, advertising partners, analytics partners, and promotional partners":
    "En los últimos 12 meses, es posible que hayamos revelado cada una de las categorías de información personal enumeradas anteriormente a nuestras filiales, terceros proveedores de servicios, plataformas de redes sociales, socios publicitarios, socios analíticos y socios promocionales",
  "Selling or sharing your information":
    "Venta o intercambio de su información",
  "Note: Of course, we are not auctioning off your data to the highest bidder. We may have “sold” (using that rather broad and dramatic term as defined by U.S. privacy laws and regulations) or shared the categories of Personal Information listed above to our affiliates, Third Party Service Providers, promotional partners, advertising partners, analytics partners, and social media platforms in the past 12 months":
    "Nota: por supuesto, no estamos subastando sus datos al mejor postor. Es posible que en los últimos 12 meses hayamos “vendido“ (utilizando ese término bastante amplio y dramático, tal y como lo definen las leyes y normativas estadounidenses sobre privacidad) o compartido las categorías de Información Personal enumeradas anteriormente a nuestras filiales, Terceros Proveedores de Servicios, socios promocionales, socios publicitarios, socios analíticos y plataformas de redes sociales",
  "WebComprehensive does not knowingly collect, use, sell or share Personal Information from children under the age of 16":
    "WebComprehensive no recopila, utiliza, vende o comparte conscientemente la Información Personal de niños menores de 16 años",
  "Sensitive personal information": "Información personal sensible",
  "We may process Sensitive Personal Information in accordance with applicable laws and do not use or disclose Sensitive Personal Information in any manner that would require us to provide an opt-out opportunity under applicable laws":
    "Podemos procesar Información Personal Sensible de acuerdo con las leyes aplicables y no usamos ni revelamos Información Personal Sensible de ninguna manera que nos obligue a proporcionar una oportunidad de exclusión voluntaria bajo las leyes aplicables",
  "Information retention": "Conservación de la información",
  "We retain your Personal Information for the period of time reasonably necessary to achieve the purposes outlined in this Privacy Policy, or in any other notice provided at the time of collection":
    "Conservamos su Información Personal durante el periodo de tiempo razonablemente necesario para alcanzar los fines descritos en la presente Política de Privacidad, o en cualquier otro aviso facilitado en el momento de su recogida",
  "To determine the appropriate retention period for Personal Information, we consider the amount, nature, and sensitivity of the data, the potential risk of harm from unauthorized use or disclosure, the purposes for which we process it, and the applicable legal requirements":
    "Para determinar el periodo de conservación adecuado de la Información Personal, tenemos en cuenta la cantidad, la naturaleza y la sensibilidad de dichos datos, el riesgo potencial de daño derivado de su uso o divulgación no autorizados, los fines para los que los procesamos y los requisitos legales aplicables",
  "terms of use": "términos de uso",
  "Last revised: August 30, 2024": "Última revisión: 30 de agosto de 2024",
  "Welcome to WebComprehensive's Terms of Use! We are really glad you are on board. Thank you for choosing to use our services.":
    "¡Bienvenido a los términos de uso de WebComprehensive! Estamos realmente muy contentos de que usted esté a bordo. Gracias por elegir usar nuestros servicios.",
  "Below we have listed important legal terms that apply to anyone who visits our website or uses our services. These terms are necessary for the protection of both you and us and to make our services possible and more enjoyable for everyone. WebComprehensive offers a wide range of services and features and some of the following terms may not be relevant to the specific services you use.":
    "A continuación hemos enumerado términos legales importantes que se aplican a cualquier persona que visita nuestro sitio web o utiliza nuestros servicios. Estos términos son necesarios para la protección tanto de usted como de nosotros y para hacer que nuestros servicios sean posibles y más agradables para todos. WebComprehensive ofrece una amplia gama de servicios y funciones y parte de los términos siguientes pueden no ser relevantes a los servicios específicos que usted usa.",
  "We know that reading legal terms can be tiring, so we try to make it a more enjoyable experience. If you have suggestions on how we can improve, we invite you to contact us.":
    "Sabemos que leer términos legales puede ser agotador. Por eso, intentamos hacer que sea una experiencia más agradable. Si tiene sugerencias sobre cómo podemos mejorar, lo invitamos a comunicarse con nosotros.",
  Introduction: "Introducción",
  "Our goal": "Nuestro objetivo",
  "Our Services offer our Users (as defined below) the ability to easily create a beautiful and functional online presence, manage and promote businesses, content and ideas, and have an overall satisfying experience doing so, even if they are not tech or design savvy. As detailed below, we offer Users many tools and features to create, publish and use amazing websites, e-commerce platforms, newsletters, galleries, media players, mobile applications and other online and mobile applications, tools and services. The websites, features and online and mobile platforms created by Users are collectively referred to as":
    "Nuestros servicios ofrecen a nuestros Usuarios (como se define abajo) la capacidad de crear fácilmente una presencia en línea hermosa y funcional, gestionar y promover negocios, contenidos e ideas, y tener una experiencia general satisfactoria al hacerlo, incluso sin ser experto en la tecnología ni el diseño. Como se detalla a continuación, ofrecemos a los usuarios muchas herramientas y funciones para crear, publicar y usar increíbles sitios web, plataformas de comercio electrónico, boletines informativos, galerías, reproductores de multimedia, aplicaciones móviles y otras aplicaciones, herramientas y servicios en línea y móviles. Los sitios web, las funciones y las plataformas en línea y móviles creadas por los Usuarios se denominan en conjunto",
  "User Platforms": "Plataformas de Usuario",
  "#ThatEasy": "#AsíDeFácil",
  "WebComprehensive offers online and mobile services and tools that allow you to create, manage and/or operate your own online and mobile presence.":
    "WebComprehensive ofrece servicios y herramientas en línea y móviles, que le permiten crear, gestionar y/o utilizar su propia presencia en línea y móvil.",
  "Legal agreement": "Acuerdo legal",
  "These WebComprehensive Terms of Use":
    "Estos términos de uso de WebComprehensive",
  "together with any additional terms that apply specifically to certain of our services and features as presented on the WebComprehensive website(s)":
    "junto con los términos adicionales que se aplican específicamente a algunos de nuestros servicios y funciones como se presentan en el o los sitios web de WebComprehensive",
  "WebComprehensive Website": "Sitio web de WebComprehensive",
  "and, collectively, the": "y, colectivamente, los",
  "WebComprehensive Terms": "Términos de WebComprehensive",
  "Together they form the total of terms and conditions applicable to each visitor or user":
    "forman en conjunto el total de términos y condiciones aplicables a cada visitante o usuario",
  User: "Usuario",
  "of the WebComprehensive Website, the WebComprehensive mobile application (the “WebComprehensive App”) and/or any other services, applications or features offered by us in connection herewith, except where we specifically state otherwise (all services offered on the WebComprehensive Website or the WebComprehensive App, collectively, the":
    "del Sitio Web WebComprehensive, la aplicación móvil WebComprehensive (la “App WebComprehensive“) y/o cualquier otro servicio, aplicación o función ofrecida por nosotros con respecto a lo aquí contemplado, excepto donde específicamente decimos lo contrario (todos los servicios ofrecidos en el Sitio Web de WebComprehensive o la App WebComprehensive, colectivamente, los",
  "WebComprehensive Services": "Servicios de WebComprehensive",
  "For the avoidance of doubt, WebComprehensive Services (as defined in these Terms of Use) do not include any services, applications, features or components that were created, developed, connected to or offered by a WebComprehensive User, even if presented on the WebComprehensive Website or WebComprehensive App.":
    "Para evitar dudas, los Servicios de WebComprehensive (como se definen en estos Términos de Uso) no incluyen los servicios, las aplicaciones, las funciones ni los componentes que fueron creados, desarrollados, conectados u ofrecidos por un Usuario de WebComprehensive, incluso si se presentan en el Sitio Web de WebComprehensive o en la App WebComprehensive.",
  "The WebComprehensive Terms constitute a binding and enforceable legal agreement between WebComprehensive and its affiliated companies and subsidiaries worldwide.":
    "Los Términos de WebComprehensive constituyen un contrato legal vinculante y ejecutable entre WebComprehensive y sus empresas afiliadas y subsidiarias en todo el mundo",
  "and you in connection with your use of any of the WebComprehensive Services. We therefore encourage you to read them carefully.":
    "y usted en relación con el uso de cualquiera de los Servicios de WebComprehensive. Por eso, le recomendamos que los lea con atención.",
  "You may only visit or use the WebComprehensive Services or the WebComprehensive App if you fully agree to the WebComprehensive Terms. If you use or register for any of the WebComprehensive Services, you indicate and affirm your informed consent to these Terms of Use and any other WebComprehensive Terms that apply to your use of any of the WebComprehensive Services. If you do not read the WebComprehensive Terms or if you do not fully understand or agree to them, you must immediately leave the WebComprehensive Website and avoid or discontinue use of all WebComprehensive Services or the WebComprehensive App.":
    "Solo puede visitar o usar los Servicios de WebComprehensive o la App WebComprehensive si acepta plenamente los Términos de WebComprehensive. Si usa cualquiera de los Servicios de WebComprehensive o se registra en ellos, indica y afirma su consentimiento informado de estos Términos de Uso y cualquiera de otros Términos de WebComprehensive que se apliquen al uso de cualquiera de los Servicios de WebComprehensive. Si no lee los Términos de WebComprehensive o si no los entiende por completo o no los acepta, debe abandonar de inmediato el Sitio Web de WebComprehensive y evitar o suspender el uso de todos los Servicios de WebComprehensive o la App WebComprehensive.",
  "By using our Services, you also represent that you have read our Privacy Policy, available at our Privacy Policy.":
    "Al usar nuestros Servicios, también declara que ha leído nuestra Política de privacidad, disponible en nuestra Política de privacidad.",
  "By visiting our website or using our application or services, you are entering into a legal agreement with us, consisting of these Terms of Use and the legal terms of our additional services.":
    "Al visitar nuestro sitio web o utilizar nuestra aplicación o los servicios, usted está celebrando un acuerdo legal con nosotros, que consiste en estos Términos de Uso y los términos legales de nuestros servicios adicionales.",
  "By using our services, you signify your consent to these terms and acknowledge that you have read our Privacy Policy. You may not use our services if you do not agree to all of our terms.":
    "Al utilizar nuestros servicios, usted manifiesta su consentimiento a estos términos y reconoce que ha leído nuestra Política de Privacidad. No puede usar nuestros servicios si no acepta todos nuestros términos.",
  "User Account": "Cuenta de Usuario",
  "To access and use certain sections and features of the WebComprehensive Services, you must first register and create an account with WebComprehensive.":
    "Para acceder y utilizar ciertas secciones y características de los Servicios de WebComprehensive, primero debe registrarse y crear una cuenta en WebComprehensive",
  "Creating a User Account or purchasing Paid Services (as defined in Clause 6 below) may be done directly on the WebComprehensive Website or through unrelated third parties who are authorized to sell WebComprehensive subscriptions for User Accounts and other Paid Services (a":
    "La creación de una Cuenta de Usuario o la compra de Servicios de Pago (como se define en la Cláusula 6 a continuación) se puede hacer directamente en el Sitio Web de WebComprehensive o a través de terceros no relacionados que estén autorizados para vender suscripciones de WebComprehensive para Cuentas de Usuario y otros Servicios de Pago (un",
  Reseller: "Revendedor",
  "under a separate agreement with WebComprehensive (a":
    "en virtud de un contrato independiente con WebComprehensive (un",
  "Reseller Agreement": "Contrato del Revendedor",
  "If you register for WebComprehensive Services or purchase Paid Services through a Reseller (herein referred to as a “Reseller User”), please note the following:":
    "Si se registra en los Servicios de WebComprehensive o compra Servicios de Pago a través de un Revendedor (aquí denominado como un “Usuario de Revendedor”), tenga en cuenta lo siguiente:",
  "These Terms of Use apply in addition to any understanding between you and Reseller, and govern your use of the WebComprehensive Services.":
    "Estos Términos de Uso se aplican además de cualquier entendimiento entre usted y el Revendedor, y rigen el uso de los Servicios de WebComprehensive.",
  "As between you and WebComprehensive, depending on your relationship with WebComprehensive and unless otherwise provided in these Terms of Use, these Terms of Use take precedence over any understanding between you and Reseller regarding your use of WebComprehensive Services or activities on your User Account (or the User Account to which you are added).":
    "Entre usted y WebComprehensive, según su relación con WebComprehensive y a menos que se disponga lo contrario en estos Términos de Uso, estos Términos de Uso tienen prioridad sobre cualquier entendimiento entre usted y el Revendedor en relación con el uso de los Servicios de WebComprehensive o las actividades en su Cuenta de Usuario (o la Cuenta de Usuario en donde lo agreguen).",
  "Certain services, applications and features of the WebComprehensive Services may not be available to you or may only be available to you through the Reseller platform.":
    "Determinados servicios, aplicaciones y funciones de los Servicios de WebComprehensive pueden no estar disponibles para usted o solo pueden estar disponibles para usted a través de la plataforma del Revendedor.",
  "You may also create an account and purchase services through a third party authorized to sell WebComprehensive (Reseller). If you register with WebComprehensive or purchase services through a Reseller, you acknowledge and agree to these WebComprehensive Terms of Use (in addition to any agreement between you and the Reseller), so that in your relationship with WebComprehensive, these terms will take precedence over any other terms, and some WebComprehensive services and features may not be available to you, or may only be available through the Reseller's platform.":
    "Puede crear una cuenta y comprar servicios también a través de un tercero autorizado para vender WebComprehensive (Revendedor). Si se registra en WebComprehensive o compra servicios a través de un Revendedor, confirma y acepta estos términos de uso de WebComprehensive (además de cualquier acuerdo entre usted y el Revendedor), de manera que, en su relación con WebComprehensive, estos términos tendrán prioridad sobre cualesquiera otros términos, y que es posible que algunos servicios y funciones de WebComprehensive no estén disponibles para usted, o solo lo estén a través de la plataforma del Revendedor.",
  "Access to accounts, roles and permissions":
    "Acceso a cuentas, roles y permisos",
  "You may invite others to your User Account and User Platforms and assign them certain roles and permissions to perform certain activities within your User Account and User Platforms.":
    "Puede invitar a otros a su Cuenta de Usuario y sus Plataformas de Usuario y asignarles determinados roles y permisos para que hagan determinadas actividades dentro de su Cuenta de Usuario y Plataformas de Usuario.",
  "A person who is granted permission to do activities on a User Account that he or she does not own is referred to herein as a":
    "Una persona a la que se le otorga permiso para hacer actividades en una Cuenta de Usuario de la que no es propietaria aquí se denomina como",
  Contributor: "Colaborador",
  "If anyone other than you (including Contributors) accesses your User Account settings or any of your User Platforms, they may also (and in the case of Contributors, depending on the roles and permissions you assign to them) perform actions available to you (unless specifically stated otherwise in the WebComprehensive Services), make changes to your User Platforms and User Account, agree to any legal terms available there, make various representations and warranties, and more. All such activities will be deemed to be done on behalf of and for the account of you as the owner of the User Account, whether or not you have specifically authorized this.":
    "Si cualquiera aparte de usted (incluidos los Colaboradores) accede a los ajustes de su Cuenta de Usuario o cualquiera de sus Plataformas de Usuario, también podrá (y en el caso de los Colaboradores, según los roles y permisos que les asigne) hacer acciones disponibles para usted (a menos que se indique específicamente lo contrario en los Servicios de WebComprehensive), hacer cambios en sus Plataformas de Usuario y Cuenta de Usuario, aceptar cualquier condición legal disponible allí, hacer distintas declaraciones y garantías, y más. Se entenderá que todas esas actividades se hicieron en nombre y por cuenta de usted como propietario de la Cuenta de Usuario, sin importar que usted lo haya autorizado específicamente o no.",
  "We therefore strongly encourage you to (i) keep your User Account login credentials confidential and (ii) allow access to your User Account only to individuals you trust, as you will be fully responsible for all activities that occur under your User Account or User Platforms (including all representations and warranties any Contributor makes therein) and for all damages, expenses or losses that may arise from such activities.":
    "Por lo tanto, le recomendamos encarecidamente que (i) mantenga la confidencialidad de las credenciales de inicio de sesión de su Cuenta de Usuario y que (ii) permita el acceso a su Cuenta de Usuario solo a las personas en las que confía, ya que usted tendrá responsabilidad plena por todas las actividades que ocurran en su Cuenta de Usuario o Plataformas de Usuario (incluidas todas las representaciones y garantías que cualquier Colaborador haga allí) y por todos los daños, los gastos o las pérdidas que puedan derivarse de dichas actividades.",
  "You must provide complete and accurate information when registering your User Account and using the WebComprehensive Services. We strongly encourage you to provide your own (or your company's, as applicable) contact and billing information, as well as your valid email address, as we may use it to identify you and determine the actual and true owner of the User Account or User Content (as defined below) submitted to us.":
    "Debe brindar información completa y precisa cuando registre su Cuenta de Usuario y use los Servicios de WebComprehensive. Le recomendamos encarecidamente que brinde su propia información de contacto y facturación (o la de su empresa, según corresponda), además de su dirección válida de correo electrónico, ya que podemos usarla para identificarlo y determinar el propietario real y verdadero de la Cuenta de Usuario o el Contenido de Usuario (como se define a continuación) que nos entreguen.",
  "If you are a Contributor, you must register and create your own User Account in order to access the User Account for which you will be a Contributor. Therefore, these Terms of Use also apply to Contributors. If you are a Reseller User, depending on the agreement between you and the Reseller, such Reseller may be the owner of the User Platform and you may be invited by the Reseller as a Contributor to such User Platform.":
    "Si usted es Colaborador, debe registrarse y crear una Cuenta de Usuario propia para poder acceder a la Cuenta de Usuario en la que será Colaborador. Por lo tanto, también se aplican estos Términos de Uso a los Colaboradores. Si es un Usuario de Revendedor, según el acuerdo entre usted y el Revendedor, dicho Revendedor podría ser el propietario de la Plataforma de Usuario y usted podría estar invitado por el Revendedor como Colaborador de dicha Plataforma de Usuario.",
  "If you are invited as a Contributor on a User Platform or if you are a Reseller User, you represent that the owner of such User Platform or the Reseller (i) will have full access to all information (including personal information) stored by you or stored on your behalf on such User Platform; and (ii) will have all rights and functions granted to a User Account owner in connection with such User Platform.":
    "Si usted es invitado como Colaborador en una Plataforma de Usuario o si es un Usuario de Revendedor, declara que el propietario de dicha Plataforma de Usuario o el Revendedor (i) tendrán acceso pleno a toda la información (incluida la información personal) que usted almacene o que se almacene en nombre suyo en dicha Plataforma de Usuario; y (ii) que tendrán todos los derechos y las funciones otorgadas a un propietario de Cuenta de Usuario en relación con dicha Plataforma de Usuario.",
  "You may invite other people (Collaborators) to perform activities on your User Account by assigning them certain roles and permissions. If you are invited to be a Collaborator on another User's Platform, you must create your own User Account before accessing the other User's Platform.":
    "Puede invitar a otras personas (Colaboradores) a realizar actividades en su Cuenta de Usuario, al asignarles determinados roles y permisos. Si le invitan a ser Colaborador en la Plataforma de otro Usuario, deberá crearse una Cuenta de Usuario propia antes de acceder a la otra Plataforma de Usuario.",
  "If you are a Reseller User, the Reseller may be the owner of the User Platform and have access to the information on such User Platform. The Reseller will also have all rights and functionalities granted to a User Account owner. The Reseller may invite you as a Contributor to such User Platform.":
    "Si usted es un Usuario del Revendedor, es posible que el Revendedor sea el propietario de la Plataforma de Usuario y tenga acceso a la información de dicha Plataforma de Usuario. El Revendedor también tendrá todos los derechos y funcionalidades concedidos a un propietario de Cuenta de Usuario. Es posible que el Revendedor lo invite como Colaborador a dicha Plataforma de Usuario.",
  "Account Ownership": "Propiedad de la cuenta",
  "For each User Platform, WebComprehensive will consider the owner of such User Platform to be the person or entity whose email address appears in WebComprehensive's records as the owner of the User Account within which the User Platform was created.":
    "Para cada Plataforma de Usuario, WebComprehensive considerará que el propietario de dicha Plataforma de Usuario es la persona o la entidad cuya dirección de correo electrónico figura en los registros de WebComprehensive como propietario de la Cuenta de Usuario dentro de la cual se creó la Plataforma de Usuario.",
  "In the event of a dispute over ownership of a User Account, we reserve the right to determine ownership of a User Account at our discretion, whether or not we conduct an independent investigation. However, if we are unable to make such a determination (as assessed in our sole discretion), we reserve the right to refrain from doing so or to suspend a User Account until the parties to the dispute reach an agreement, without WebComprehensive having any liability to you or any other party. We may request documents (e.g., government-issued identification, business license) to assist us in determining ownership.":
    "En caso de conflicto sobre la propiedad de una Cuenta de Usuario, nos reservamos el derecho a determinar la propiedad de una Cuenta de Usuario según nuestro criterio, ya sea que hagamos una investigación independiente o no. Sin embargo, si no podemos tomar dicha determinación (según lo evaluemos a criterio nuestro), nos reservamos el derecho a evitar hacerlo o suspender una Cuenta de Usuario hasta que las partes en el conflicto alcance un acuerdo, sin que WebComprehensive tenga responsabilidad alguna hacia usted o cualquier otra parte. Podemos solicitar documentos (p. ej., una identificación emitida por el gobierno, una licencia comercial) que nos ayuden a determinar la propiedad.",
  "If you created your User Account directly on the WebComprehensive Website (and not through a Reseller), we may evaluate the principles set forth below to determine ownership.":
    "Si usted creó su Cuenta de Usuario directamente en el Sitio Web de WebComprehensive (y no a través de un Revendedor), podemos evaluar los principios establecidos a continuación para determinar la propiedad.",
  "WebComprehensive will consider the person or entity that has access to the email address (entered into WebComprehensive's records for a User Account on which such User Platform or User Content was created) to be the owner of a User Account, User Platform or User Content that was created or uploaded to the applicable WebComprehensive Service.":
    "WebComprehensive considerará que la persona o la entidad que tiene acceso a la dirección de correo electrónico (ingresada en los registros de WebComprehensive para una Cuenta de Usuario en la que dicha Plataforma de Usuario o Contenido de Usuario fueron creados) como el propietario de una Cuenta de Usuario, Plataforma de Usuario o Contenido de Usuario que se hayan creado o cargado en el Servicio de WebComprehensive correspondiente.",
  "If any Paid Services were purchased through a User Account, WebComprehensive may consider the owner of such User Account or the relevant User Platform or the User Content created thereon to be the person or entity whose billing information was used for the purchase of such Paid Services (“Billing Information”). Notwithstanding the foregoing, where applicable, if a User Platform was connected to an external domain name (whether imported or purchased as part of certain Paid Services, as defined below), and the registration information for such domain is publicly available through the WHOIS database available at www.whois.net or in WebComprehensive's database, WebComprehensive may consider the owner of such User Platform to be the person or entity listed as the registrant of such domain. In the event that both a person and an organization are listed as the registrant or as the organization registering for such domain, WebComprehensive will consider the organization to be the actual owner of the domain. In the event that the Billing Information indicates that one person owns the User Platform and the domain registration indicates a different owner, WebComprehensive will consider the person registered as the owner of the domain connected to the WebComprehensive Account to be the Owner of the User Platform.":
    "Si cualquiera de los Servicios de Pago se compró a través de una Cuenta de Usuario, WebComprehensive podrá considerar que el propietario de dicha Cuenta de Usuario o la Plataforma de Usuario relevante o el Contenido de Usuario creado allí es la persona o la entidad cuya información de facturación se usó para la compra de dichos Servicios de Pago (“Información de Facturación”). Sin perjuicio de lo antedicho, cuando corresponda, si una Plataforma de Usuario se conectó con un nombre de dominio externo (ya sea importado o comprado como parte de determinados Servicios de Pago, como se define a continuación), y la información de registro de dicho dominio está disponible al público a través de la base de datos WHOIS disponible en el sitio web www.whois.net o en la base de datos de WebComprehensive, WebComprehensive podrá considerar que el propietario de dicha Plataforma de Usuario es la persona o la entidad ingresada como registrante de dicho dominio. En caso de que una persona y una organización estén ingresadas como registrantes o como organización registrante de dicho dominio, WebComprehensive considerará que la organización es el propietario real del dominio. En caso de que la Información de Facturación indique que una persona es propietaria de la Plataforma de Usuario y el registro del dominio indique un propietario distinto, WebComprehensive considerará que la persona registrada como propietaria del dominio conectado con la Cuenta de WebComprehensive es la Propietaria de la Plataforma de Usuario.",
  "Notwithstanding the foregoing, WebComprehensive shall have the right to determine ownership of User Content or a User Website in its sole discretion, including without regard to the foregoing, if WebComprehensive believes, in its sole discretion, that the situation warrants such a determination based on the factual situation as determined by WebComprehensive. In the case of a Reseller User, WebComprehensive may determine ownership of the applicable User Account pursuant to the terms of the Reseller Agreement.":
    "Sin perjuicio de lo antedicho, WebComprehensive tendrá derecho a determinar la propiedad del Contenido de Usuario o de un Sitio Web de Usuario a su criterio, incluso haciendo caso omiso de lo antedicho, en caso de que WebComprehensive considere, a su entera discreción, que la situación justifica dicha determinación en base a la situación fáctica determinada a criterio de WebComprehensive. En el caso de un Usuario de Revendedor, WebComprehensive podrá determinar la propiedad de la Cuenta de Usuario correspondiente en virtud de los términos del Contrato del Revendedor.",
  "Your obligations": "Sus obligaciones",
  "You represent and warrant that:": "Usted manifiesta y garantiza que:",
  "You are at least thirteen (13) years old, or sixteen (16) years old if you are a person within the European Union (EU), or the age of legal majority in your jurisdiction, and you have the legal authority, right and freedom to enter into the WebComprehensive Terms and form a binding agreement, for yourself or on behalf of the person or entity to whom you are bound by the WebComprehensive Terms;":
    "tiene, por lo menos, trece (13) años, o dieciséis (16) años si es una persona dentro de la Unión Europea (UE), o la edad de mayoría legal en su jurisdicción, y tiene autoridad legal, derecho y libertad de entrar en los Términos de WebComprehensive y formar un acuerdo vinculante, para usted o en nombre de la persona o entidad comprometida por usted con los Términos de WebComprehensive;",
  "You are not an habitual resident of, and will not use or distribute the WebComprehensive Services or the WebComprehensive App in, any country or region that is subject to comprehensive country or region blocks by the U.S. You further confirm that you are not listed on any sanctions list, including without limitation the U.S. Specially Designated Nationals and Blocked Persons List, maintained by the U.S. (“SDN List”), or any other applicable sanctions list maintained by the EU, the U.K., or others, and that your business is not owned by or otherwise controlled by any Sanctioned Party, directly or indirectly in the aggregate, or controlled by any such party (“Sanctioned Party”). You further confirm that you will not use or distribute the WebComprehensive Services or the WebComprehensive App directly or indirectly for or for the benefit of a Sanctioned Party. You agree to indemnify WebComprehensive against any and all losses, including but not limited to monetary penalties and legal fees, that WebComprehensive may suffer in the event that you violate any of the foregoing representations regarding your sanctions-free status.":
    "no es residente habitual de, ni usará ni distribuirá los Servicios de WebComprehensive ni la App WebComprehensive en, cualquier país o región que esté sujeta a bloqueos integrales de países o regiones por los EE. UU. Confirma además que no está incluido en ninguna lista de sanciones, incluidas, entre otras, la Lista de Nacionales Especialmente Designados y Personas Bloqueadas de los EE. UU., mantenida por los EE. UU. (“Lista de SDN”), o cualquier otra lista aplicable de sanciones mantenida por la UE, el Reino Unido u otros, y que ninguna Parte Sancionada es propietaria del 50 por ciento o más de su empresa, ya sea de forma directa o indirecta de manera agregada, ni está bajo el control de dicha parte (“Parte Sancionada”) de otra forma. Además, confirma que no usará ni distribuirá los Servicios de WebComprehensive ni la App WebComprehensive de forma directa o indirecta para o en beneficio de una Parte Sancionada. Acepta indemnizar a WebComprehensive contra toda pérdida, incluidas, entre otras, las multas monetarias y los honorarios legales, que WebComprehensive pueda sufrir en caso de que usted viole cualquiera de las declaraciones antedichas en relación con su estado de libre de sanciones.",
  "your country of residence or country of company incorporation is the same as the country specified in the contact or billing address you provide to us;":
    "su país de residencia o país de constitución de la empresa sea el mismo que el país especificado en el contacto o la dirección de facturación que nos proporciona;",
  "You understand that WebComprehensive does not provide legal advice or recommendations regarding laws or regulations applicable to your use or that of any of your End Users, or your compliance therewith;":
    "entiende que WebComprehensive no proporciona asesoramiento legal ni recomendaciones con respecto a las leyes o disposiciones aplicables a su uso o al de cualquiera de sus Usuarios Finales, o su cumplimiento de las mismas;",
  "And specifically, with respect to your User Content:":
    "Y específicamente, con respecto a su Contenido de Usuario:",
  "You confirm that you own all rights to the content uploaded, developed or provided by you, or imported, connected, copied or uploaded by the WebComprehensive Services for you, on your User Platform":
    "usted confirma que es propietario de todos los derechos sobre los contenidos cargados, desarrollados o brindados por usted, o importados, conectados, copiados o cargados por los Servicios de WebComprehensive para usted, en su Plataforma de Usuario",
  "User Content": "Contenido de Usuario",
  "including any design, image, animation, video, audio file, font, logo, code, algorithm, SPI, API, database, illustration, composition, artwork, interface, username, information you provide for the creation of a subdomain name, text, literary work and any other material":
    "incluido todo diseño, imagen, animación, video, archivo de audio, fuente, logotipo, código, algoritmo, SPI, API, base de datos, ilustración, composición, obra de arte, interfaz, nombre de usuario, información que brinde para la creación de un nombre de subdominio, texto, obra literaria y cualquier otro material",
  Content: "Contenido",
  "or that you otherwise have (and will continue to have) full power, title, license, consent and authority, in relation to the User Content, that is necessary to access, import, copy, use, connect, perform, publish, transfer or license such User Content, whether by you and us or any of our affiliates;":
    "o que tiene de otra forma (y seguirá teniendo) pleno poder, título, licencia, consentimiento y autoridad, en relación con el Contenido de Usuario, que sea necesario para acceder, importar, copiar, usar, conectar, desarrollar, publicar, transferir o licenciar dicho Contenido de Usuario, ya sea por usted y por nosotros o cualquiera de nuestras afiliadas;",
  "you have (and will retain) full power, title, licenses, consents and authority to permit WebComprehensive Services to access any websites, web pages and other online services for the purpose of importing, copying, displaying, uploading, transmitting or otherwise using your User Content.":
    "usted tiene (y conservará) el pleno poder, titularidad, licencias, consentimientos y autoridad para permitir a los Servicios de WebComprehensive el acceso a cualquier sitio web, páginas web y otros servicios en línea, con el propósito de importar, copiar, exhibir, cargar, transmitir o de cualquier otra forma usar su contenido de usuario.",
  "the User Content is (and will remain) true, current, accurate, does not infringe the rights of any third party, and is not otherwise unlawful for you to upload, import, copy, possess, publish, transmit, display or otherwise use in the country in which you or visitors and users of your User Platform (“End Users”) reside, or for WebComprehensive or its End Users to access, import, copy, upload, use or possess in connection with the WebComprehensive Services;":
    "el Contenido de Usuario es (y seguirá siendo) verdadero, actual, preciso, no infringe los derechos de terceros y de ninguna manera es ilegal para usted cargarlo, importarlo, copiarlo, poseerlo, publicarlo, transmitirlo, exhibirlo o de cualquier otra forma usarlo en el país en el que reside usted o los visitantes y usuarios de su Plataforma de Usuario (“Usuarios finales”) , o para que WebComprehensive o sus Usuarios Finales puedan acceder a ellos, importarlos, copiarlos, cargarlos, usarlos o poseerlos en relación con los Servicios de WebComprehensive;",
  "you have obtained all necessary consents and permissions under all applicable laws, in connection with the posting, transmission and publication of any personal information and/or the image or likeness of any person, entity or property that is part of the User Content, and that you adhere to all applicable laws.":
    "ha obtenido todos los consentimientos y permisos necesarios según todas las leyes aplicables, en relación con el registro, transmisión y publicación de cualquier información personal y/o la imagen o semejanza de cualquier persona, entidad o propiedad que sea parte del Contenido de Usuario, y que usted se adhiere a todas las leyes aplicables correspondientes.",
  "you will comply with and adhere to WebComprehensive's Content Guidelines, which describe permitted and prohibited content on our service.":
    "cumplirá y se adherirá a las Pautas de contenido de WebComprehensive, que describen el contenido permitido y prohibido en nuestro servicio.",
  "You agree and accept:": "Usted se compromete y acepta:",
  "fully comply with all applicable laws and contractual terms governing your use of the WebComprehensive Services (and any related interactions or transactions), including any specific laws applicable to you or your End Users in any of your geographic locations;":
    "cumplir plenamente con todas las leyes aplicables y los términos contractuales que rigen el uso de los Servicios de WebComprehensive (y cualquier interacción o transacción relacionada), incluyendo las leyes específicas aplicables a usted o sus Usuarios Finales en cualquiera de sus ubicaciones geográficas;",
  "be solely responsible with respect to any uses of the WebComprehensive Services that occur under your User Account or your User Platform(s) and for any of your User Content (including the consequences of accessing, importing, uploading, copying, using or publishing such User Content on or in connection with the WebComprehensive Services);":
    "ser el único responsable con respecto a cualquiera de los usos de los Servicios de WebComprehensive que ocurren bajo su Cuenta de Usuario o su(s) Plataforma(s) de Usuario y para cualquiera de sus Contenidos de Usuario (incluyendo las consecuencias de acceder, importar, cargar, copiar, usar o publicar tal Contenido de Usuario en o con respecto a los Servicios de WebComprehensive);",
  "regularly and independently save and back up your User Content and the information you are processing in connection with your User Website, including with respect to End Users, User Products and any Third Party applications or Services used by you;":
    "de manera regular e independiente guardar y hacer copia de seguridad de su Contenido de Usuario y la información que está procesando con respecto a su Sitio Web de Usuario, incluso con respecto a los Usuarios Finales, Productos del Usuario y cualesquiera aplicaciones o Servicios de Terceros utilizados por usted;",
  "receive periodic promotional messages and materials from WebComprehensive or its partners, by mail, email or any other form of contact you may provide to us (including your telephone number for calls or text messages). If you do not wish to receive these promotional materials or marketing messages, simply notify us at any time;":
    "recibir periódicamente mensajes promocionales y materiales de WebComprehensive o sus socios, por correo, correo electrónico o cualquier otra forma de contacto que usted nos pueda proporcionar (incluyendo su número de teléfono para llamadas o mensajes de texto). Si usted no desea recibir estos materiales promocionales o mensajes publicitarios, simplemente notifíquenos en cualquier momento;",
  "allow WebComprehensive to use in perpetuity, worldwide and free of charge, any version of your User Website (or any part thereof) for any of WebComprehensive's online or offline promotional and marketing activities and to modify them as reasonably necessary for such purposes, and you waive any claim against WebComprehensive or anyone acting on WebComprehensive's behalf relating to any past, present or future moral rights, artist's rights or any other similar rights throughout the world you may have in or to your User Website in respect of such permitted limited uses;":
    "permitir a WebComprehensive usar a perpetuidad, en todo el mundo y de forma gratuita, cualquier versión de su Sitio Web de Usuario (o cualquier parte de ella) para cualquiera de las actividades promocionales y de marketing de WebComprehensive, en línea o fuera de línea, y modificarlas según sea razonablemente necesario para tales fines, y usted renuncia a cualquier reclamación contra WebComprehensive o alguien que actúe en nombre de WebComprehensive, relacionada con cualquier derecho moral pasado, presente o futuro, derechos de artista o cualquier otro derecho similar en todo el mundo que usted pueda tener en o a su Sitio Web de Usuario con respecto a tales usos limitados permitidos;",
  "WebComprehensive's sole discretion as to the means, manner and method of providing the WebComprehensive Services, including with respect to the hosting, transmission, publication or display of any Platform or User Content (including the inclusion and presentation of any advertising messages or other commercial content in connection therewith).":
    "la única discreción de WebComprehensive en cuanto a medios, forma y método para prestar los Servicios de WebComprehensive, incluso los relativos al alojamiento, transmisión, publicación o exhibición de cualquier Plataforma o Contenido de Usuario (incluyendo la inclusión y presentación de los mensajes publicitarios u otro contenido comercial con respecto a los mismos).",
  "WebComprehensive shall have the right to offer the WebComprehensive Services under different pricing plans and to impose different restrictions on uploading, storing, downloading and using the WebComprehensive Services under each pricing plan, including but not limited to restrictions on network traffic and bandwidth, size or duration of Content, quality or format of Content, sources of Content, volume of download time, number of subscribers to Content, storage volume, etc.":
    "WebComprehensive tendrá derecho a ofrecer los Servicios de WebComprehensive con distintos planes de precios y a imponer distintas restricciones para la carga, el almacenamiento, la descarga y el uso de los Servicios de WebComprehensive en cada plan de precios, incluidas, entre otras, restricciones sobre el tráfico de red y el ancho de banda, el tamaño o la duración del Contenido, la calidad o el formato del Contenido, las fuentes del Contenido, el volumen del tiempo de descarga, la cantidad de suscriptores del Contenido, el volumen de almacenamiento, etc.",
  "List of Obligations": "Lista de Obligaciones",
  "You must comply with all applicable laws.":
    "Debe cumplir con todas las leyes aplicables.",
  "You will be responsible for your actions and the actions of anyone who accesses your user account settings or user platform.":
    "Usted será responsable de sus acciones y de las acciones de cualquier persona que acceda a la configuración de la cuenta de usuario o plataforma de usuario.",
  "You should regularly back up your content.":
    "Regularmente deberá guardar copias de seguridad de su contenido.",
  "You agree that we or our partners may send you promotional messages and content.":
    "Usted acepta que nosotros o nuestros socios podemos enviarle mensajes y contenidos promocionales.",
  "You can easily opt out of receiving promotional messages by contacting us.":
    "Puede fácilmente optar por no recibir mensajes promocionales poniéndose en contacto con nosotros.",
  "You allow us to use your website for our promotional activities and to determine how services will be provided.":
    "Usted nos permite utilizar su sitio web para nuestras actividades promocionales y para determinar la manera como se prestarán los servicios.",
  "You agree that WebComprehensive has the right to impose and modify pricing plans for its Services. In addition, WebComprehensive may impose restrictions based on your specific use of the Service.":
    "Usted acuerda que WebComprehensive tiene el derecho de imponer y modificar los planes de precios de sus Servicios. Además, WebComprehensive puede imponer restricciones en función de su uso específico del Servicio.",
  "You agree and undertake not to:": "Usted acepta y se compromete a no:",
  "copy, modify, reuse, create derivative works from, download, adapt, reverse engineer, emulate, migrate to another service, translate, compile, decompile or disassemble the WebComprehensive Website, the WebComprehensive Services (or any portion thereof), any Content offered by WebComprehensive or Third Party Services for use and display on the User Platforms (“Licensed Content”) or any portion thereof in any way, publicly display, perform, transmit or distribute any of the foregoing without WebComprehensive's prior specific written consent or as expressly permitted under the WebComprehensive Terms;":
    "copiar, modificar, reutilizar, crear trabajos derivados de, descargar, adaptar, hacer ingeniería inversa, emular, migrar a otro servicio, traducir, compilar, descompilar o desensamblar el Sitio Web de WebComprehensive, los Servicios de WebComprehensive (o cualquier parte de los mismos), cualquier Contenido ofrecido por WebComprehensive o Servicios de Terceros para usarlos y mostrarlos en las Plataformas de Usuario (“Contenido licenciado”) o cualquier parte de ellos de cualquier manera, públicamente exhibir, realizar, transmitir o distribuir cualquiera de los anteriores sin previo consentimiento específico y escrito de WebComprehensive o según lo expresamente permitido bajo los Términos de WebComprehensive;",
  "use any unlawful action to collect login data and/or passwords for other websites, third parties, software or services,":
    "utilizar cualquier acción ilegal para recopilar los datosde inicio de sesión y/o las contraseñas de otros sitiosweb, terceros, software o servicios,",
  "phish, harvest, collect, upload or otherwise make available credit card information or other forms of financial data used to receive payments, unless done so in accordance with any applicable law, including PCI DSS where applicable,":
    "utilizar phishing, recolectar, cargar o de cualquier otraforma hacer disponible información sobre tarjetas decrédito u otras formas de datos financieros utilizadospara recibir pagos, a menos que se haga de acuerdo concualquier ley aplicable, incluso con la norma PCI DSScuando sea aplicable,",
  "post or make any use of the WebComprehensive Services or Licensed Content on any website, media, network or system other than as intended by WebComprehensive, or frame, “deep-link,” “page-scrape,” mirror or create a browser or border environment around any of the WebComprehensive Services, Licensed Content and/or User Platform (or any portion thereof), except as expressly permitted by WebComprehensive in advance and in writing;":
    "publicar o hacer cualquier uso de los Servicios deWebComprehensive o Contenido Licenciado en cualquier sitioweb, medio, red o sistema distinto de los previstos porWebComprehensive, o usar un marco, “enlace profundo”,“raspado de página”, espejo o crear un entorno denavegador o borde alrededor de cualquiera de los Serviciosde WebComprehensive, Contenido Licenciado y/o Plataformade Usuario (o cualquier parte de los mismos), excepto loexpresamente permitido por WebComprehensive, poradelantado y por escrito;",
  "act in a manner that could be perceived as damaging to the reputation and goodwill of WebComprehensive or that could cause discredit or damage to WebComprehensive":
    "actuar de una manera que pudiera ser percibida comoperjudicial para la reputación y el goodwill deWebComprehensive o que pueda causar desprestigio o daños aWebComprehensive;",
  "buy search engine keywords or other pay-per-click (e.g., Google AdWords) advertising, or domain names using WebComprehensive or the WebComprehensive Marks or variations and misspellings thereof;":
    "comprar palabras clave de motores de búsqueda u otro pagopor clic (por ejemplo, Google AdWords), o nombres dedominio que usen a WebComprehensive o las Marcas deWebComprehensive o variaciones y faltas de ortografía delas mismas;",
  "reverse look-up, trace or attempt to trace another User of the WebComprehensive Services, interfere with or violate in any way another User's right to privacy or other rights, or harvest or collect personally identifiable information about visitors or users of the WebComprehensive Services and/or the User Platform without their express informed consent;":
    "hacer búsqueda inversa, rastrear o tratar de rastrear aotro Usuario de los Servicios de WebComprehensive,interferir o violar de cualquier forma el derecho a laprivacidad u otros derechos de otro Usuario, o cosechar orecopilar información personal identificable sobre losvisitantes o usuarios de los Servicios de WebComprehensivey/o la Plataforma de Usuario sin su expreso e informadoconsentimiento;",
  "disable, circumvent, bypass or otherwise avoid any measures used to prevent or restrict access to the WebComprehensive Services, the User Platform, other user(s) account(s), or other systems or networks connected to the WebComprehensive Services, using hacking, password mining or other illegitimate or prohibited means;":
    "desactivar, burlar, eludir o evitar de cualquier formacualquiera de las medidas utilizadas para impedir orestringir el acceso a los Servicios de WebComprehensive,la Plataforma de Usuario, la cuenta del otro(s)usuario(s), u otros sistemas o redes conectadas a losServicios de WebComprehensive, utilizando el hackeo,extracción de contraseñas u otros medios ilegítimos oprohibidos;",
  "probe, scan or test the vulnerability of the WebComprehensive Services or any network connected to the WebComprehensive Services;":
    "sondear, escanear o probar la vulnerabilidad de losServicios de WebComprehensive o de cualquier red conectadaa los Servicios de WebComprehensive;",
  "take any action that imposes an unreasonable or disproportionately large load on the infrastructure of the WebComprehensive Services, WebComprehensive systems, or the networks connected to the WebComprehensive Services, or otherwise interfere with or disrupt the operation of any of the WebComprehensive Services, or the servers or networks that host them or make them available, or disobey any requirements, procedures, policies or regulations of such servers or networks;":
    "emprender cualquier acción que suponga una cargairracional o desproporcionadamente grande sobre lainfraestructura de los Servicios de WebComprehensive, lossistemas de WebComprehensive o las redes conectadas a losServicios de WebComprehensive, o de cualquier formainterferir con o interrumpir el funcionamiento decualquiera de los Servicios de WebComprehensive, o losservidores o redes que los alojan o que permiten que esténdisponibles, o desobedecer los requisitos, procedimientos,políticas o reglamentos de dichos servidores o redes;",
  "use any of the WebComprehensive Services or WebComprehensive Systems for video streaming or storage purposes (whether or not primarily for streaming purposes). For example, creating and operating websites whose purpose is video streaming or whose primary purpose is file storage. This includes indirect use through integration or connection with third party platforms or services;":
    "usar cualquiera de los Servicios de WebComprehensive o losSistemas de WebComprehensive para fines de almacenamientoo transmisión de video (ya sea con fines principales detransmisión o no). Por ejemplo, crear y operar sitios webcuyo objetivo es la transmisión de video o cuyo objetivoprincipal es el almacenamiento de archivos. Incluye el usoindirecto a través de la integración o la conexión conplataformas o servicios de terceros;",
  "access WebComprehensive Services, User Accounts, Licensed Content or User Content, through any means or technology (e.g., scraping and crawling) other than our publicly available interfaces.":
    "acceder a Servicios de WebComprehensive, Cuentas deUsuario, Contenido con Licencia o Contenido de Usuario, através de cualquier medio o tecnología (p. ej., recopiladoy crawling) aparte de nuestras interfaces de soportepúblico.",
  "sell, license or exploit for any commercial purposes any use of or access to the Licensed Content or WebComprehensive Services, except as expressly permitted by the WebComprehensive Terms;":
    "vender, licenciar o explotar con fines comercialescualquier uso o acceso al Contenido con Licencia o losServicios de WebComprehensive, excepto según loexpresamente permitido por las Condiciones deWebComprehensive;",
  "remove or alter any copyright notices, watermarks, restrictions and proprietary rights notices of any of our licensors, including the copyright mark [©], Creative Commons [(cc)] indicators or trademarks [® or ™] contained in or accompanying the WebComprehensive Services or the Licensed Content; or":
    "quitar o alterar cualquier aviso de derecho de autor,marca de agua, restricción y señal que indique losderechos de propiedad de cualquiera de nuestroslicenciantes, incluida la marca de derecho de autor [©],los indicadores de Creative Commons [(cc)] o las marcascomerciales [® o ™] contenidas en los Servicios deWebComprehensive o el Contenido con Licencia o que losacompañan; o",
  "violate, attempt to violate, or otherwise fail to comply with any of the WebComprehensive Terms or any applicable laws or requirements for your use of the WebComprehensive Services.":
    "violar, intentar violar o incumplir de otra forma concualquiera de las Condiciones de WebComprehensive o lasleyes o requisitos aplicables para su uso de los Serviciosde WebComprehensive.",
  "access or use the Services for competitive analysis or similar competitive analysis purposes or to develop a competing product or service.":
    "acceder o usar los Servicios para análisis de competencia o fines similares de análisis competitivo o para desarrollar un producto o servicio competidor.",
  "You acknowledge and agree that failure to comply with any of the foregoing or any misrepresentation made by you herein may result in immediate termination of your User Account or any Services provided to you - with or without notice and without any refund of any amounts paid for such Services.":
    "Usted reconoce y acepta que el incumplimiento de cualquiera de los anteriores o cualquier declaración falsa hecha por usted en este documento puede resultar en la terminación inmediata de su Cuenta de Usuario o cualquiera de los Servicios proporcionados a usted - con o sin previo aviso y sin ningún reembolso de las sumas pagadas por esos Servicios.",
  "List of Prohibitions": "Lista de Prohibiciones",
  "In general, we just want you to be nice, and to avoid doing anything that might harm us or anyone else.":
    "En general, solo queremos que usted sea agradable, y que evite hacer cualquier cosa que pueda perjudicarnos a nosotros o a cualquier otra persona.",
  "Among others, you may not copy our materials, use any content in an illegal or harmful manner, use our services or content on any other platform or website not provided by us, make false statements or abuse our services, or otherwise violate anyone's rights or applicable laws.":
    "Entre otros, no puede copiar nuestros materiales, usar ningún contenido de forma ilegal o perjudicial, usar nuestros servicios o contenidos en ninguna otra plataforma o sitio web no proporcionado por nosotros, hacer falsas declaraciones o abusar de nuestros servicios, o de alguna forma violar los derechos de alguien o las leyes aplicables.",
  "Failure to comply with any of these rules may result in us terminating your account and ceasing to provide you with services.":
    "La omisión de obedecer cualquiera de estas reglas nos puede conducir a cancelar su cuenta y dejar de prestarle los servicios.",
  "Content and ownership": "Contenido y propiedad",
  "Your intellectual property": "Su propiedad intelectual",
  "As between WebComprehensive and you, you will own all intellectual property related to your User Content and any other material created, developed or connected to the WebComprehensive Services by you, including any design, image, animation, video, audio file, font, logo, illustration, composition, artwork, code, algorithm, SPI, API, database, interface, text and literary work. WebComprehensive does not claim ownership rights in your User Content or the content you have connected to the WebComprehensive Services. You acknowledge and agree that in order to provide the Services and to maintain and improve the Services (the “Purpose”), we need to access, upload or copy your User Content to our platform, including cloud services and CDNs, to make display adjustments, to improve our software tools (e.g., artificial intelligence or machine learning models), to make backup duplicates, and to make any other technical actions or uses necessary for the provision of our services, in our sole discretion. You hereby grant us a non-exclusive, transferable, sublicensable, royalty-free, worldwide license to use your User Content for such Purpose.":
    "En lo que respecta a WebComprehensive y usted, usted será el propietario de toda la propiedad intelectual relacionada con su Contenido de Usuario y cualquier otro material creado, desarrollado o conectado con los Servicios de WebComprehensive por usted, incluido todo diseño, imagen, animación, video, archivo de audio, fuente, logotipo, ilustración, composición, obra de arte, código, algoritmo, SPI, API, base de datos, interfaz, texto y obra literaria. WebComprehensive no alega derechos de propiedad sobre su Contenido de Usuario ni el contenido que haya conectado con los Servicios de WebComprehensive. Usted sabe y acepta que, para prestar los Servicios y mantener y mejorar los Servicios (el “Objetivo”), necesitamos acceder, cargar o copiar su Contenido de Usuario a nuestra plataforma, incluidos los servicios en la nube y los CDN, para hacer ajustes de visualización, para mejorar nuestras herramientas de software (p. ej., la inteligencia artificial o los modelos de aprendizaje automático), para hacer duplicados de respaldo y para hacer cualquier otra acción técnica o uso necesario para la prestación de nuestros servicios, a criterio nuestro. Por el presente, nos otorga una licencia no exclusiva, transferible, sublicenciable, libre de regalías y mundial para usar su Contenido de Usuario con dicho Objetivo.",
  "You have all rights to your content. In order to provide you with our services, we manipulate, store, back up and copy your content between servers.":
    "Usted tiene todos los derechos sobre su contenido. Para proporcionarle nuestros servicios, manipulamos, almacenamos, hacemos copia de respaldo y copiamos su contenido entre servidores.",
  "Intellectual property of WebComprehensive":
    "Propiedad intelectual de WebComprehensive",
  "All rights, title and interest in and to the WebComprehensive Services, including any and all copyrightable materials or other content thereon that is or may be subject to intellectual property rights under any applicable law (including artwork, graphics, images, website templates and widgets, literary works, source and object code, computer code (including html), applications, audio, music, video and other media, designs, animations, interfaces, the look and feel of the Services, WebComprehensive's methods, products, algorithms, data, interactive functions and objects, advertising and acquisition tools and methods, inventions, trade secrets, logos, domains, custom URLs, trademarks, service marks, trade names and other proprietary identifiers, whether or not registered or capable of being registered (collectively, “Intellectual Property”), and any derivatives thereof, are owned by or licensed to WebComprehensive.":
    "Todos los derechos, título e interés en y a los Servicios de WebComprehensive, incluyendo cualquiera y todos los materiales susceptibles de tener derechos de autor o cualquier otro contenido de los mismos que esté o pueda estar sujeto a derechos de propiedad intelectual en virtud de cualquier ley aplicable (incluyendo artes, gráficos, imágenes, plantillas y widgets de sitio web, obras literarias, código fuente y objeto, código de computadora (incluyendo html), aplicaciones, audio, música, vídeo y otros medios, diseños, animaciones, interfaces, el aspecto y estilo de los Servicios, métodos, productos, algoritmos, datos, funciones y objetos interactivos de WebComprehensive, herramientas y métodos de publicidad y adquisición, inventos, secretos comerciales, logotipos, dominios, URLs personalizadas, marcas registradas, marcas de servicio, nombres comerciales y otros identificadores propios, ya sean o no registrados o susceptibles de ser registrados (colectivamente, “Propiedad intelectual”), y cualesquiera derivaciones de los mismos, son propiedad de o licenciados a WebComprehensive.",
  "Subject to your full compliance with the WebComprehensive Terms and timely payment of all applicable Fees, WebComprehensive hereby grants you, upon creation of your User Account and for as long as WebComprehensive chooses to provide you with the WebComprehensive Services, a non-exclusive, non-transferable, non-sublicensable, limited, fully revocable license to use the WebComprehensive Services and the Licensed Content for the purpose of generating and displaying your User Platform to End Users and offering your User Products (as defined below) thereon, solely as expressly permitted under the WebComprehensive Terms, and solely within the WebComprehensive Services.":
    "Sujeto a su pleno cumplimiento de los Términos de WebComprehensive y al pago puntual de todas las Tarifas aplicables, WebComprehensive le otorga por el presente documento, al crear la Cuenta de Usuario y durante el tiempo que desee WebComprehensive ofrecerle los Servicios de WebComprehensive, una licencia no exclusiva, no transferible, no sublicenciable, limitada y totalmente revocable para utilizar los Servicios de WebComprehensive y el Contenido con Licencia, con el propósito de generar y exhibir su Plataforma de Usuario a Usuarios Finales y ofrecer sus Productos de Usuario (como se define más adelante) en la misma, únicamente como se permite expresamente bajo los Términos de WebComprehensive, y únicamente dentro de los Servicios de WebComprehensive.",
  "The WebComprehensive Terms do not convey any right or interest in or to the WebComprehensive Intellectual Property (or any part thereof), except only for the limited license set forth above. Nothing in the WebComprehensive Terms constitutes an assignment or waiver of WebComprehensive's Intellectual Property rights under any law.":
    "Los Términos de WebComprehensive no transmiten ningún derecho o interés en o a la Propiedad Intelectual de WebComprehensive (o cualquier parte de la misma), excepto solamente para la licencia limitada expresada anteriormente. Nada en los términos de WebComprehensive constituye una cesión o renuncia de los derechos de Propiedad Intelectual de WebComprehensive en virtud de ninguna ley.",
  "We own all rights in and to our services, content, data, technology and features.":
    "Poseemos todos los derechos en y a nuestros servicios, contenidos, datos, tecnología y funciones.",
  "You may use our services and content provided you fully comply with these terms and ensure full and timely payments.":
    "Usted puede utilizar nuestros servicios y contenido siempre que cumpla totalmente con estos términos y asegure los pagos completos y oportunos.",
  "Certain fonts that are available to you are licensed by a third party, be sure to read their terms as well.":
    "Ciertas fuentes que están disponibles para usted están licenciadas por un tercero, asegúrese de leer también sus términos.",
  "Comments and suggestions": "Comentarios y sugerencias",
  "If you provide us with suggestions, comments or other information relating to the WebComprehensive Services (whether existing, suggested or contemplated), which are or may be subject to Intellectual Property rights":
    "Si usted nos hace llegar sugerencias, comentarios u otras informaciones relativas a los Servicios de WebComprehensive (ya sean existentes, sugeridos o contemplados), que estén o puedan estar sujetos a derechos de Propiedad Intelectual",
  "this Feedback shall be the exclusive property of WebComprehensive. By submitting such Feedback to WebComprehensive, you acknowledge and agree that it may be used by WebComprehensive to (i) continue to develop, customize, and improve the WebComprehensive Services, (ii) provide ongoing technical assistance and support, (iii) contact you with news and/or general or personalized interview requests relating to WebComprehensive that may or may not be based on your opinions, (iv) facilitate, sponsor, and offer certain promotions, and monitor performance, (v) generate aggregated statistical data and other aggregated and/or inferred information, which WebComprehensive may use to provide and improve its Services, (vi) enhance WebComprehensive's data security and fraud prevention capabilities, and (vii) comply with all applicable laws and regulations. In addition, you (1) represent and warrant that such Feedback is accurate, complete, and does not infringe the rights of any third party; (2) you irrevocably assign to WebComprehensive all right, title and interest you may have in such Feedback, and (3) you expressly and irrevocably waive any and all claims relating to any past, present or future moral rights, artist's rights or any other similar rights throughout the world in or to such Feedback.":
    "este Feedback será propiedad exclusiva de WebComprehensive. Al enviar dicho Feedback a WebComprehensive, usted reconoce y acepta que pueden ser usados por WebComprehensive para (i) continuar el desarrollo, personalizar y mejorar los Servicios de WebComprehensive, (ii) proporcionar asistencia y soporte técnico continuado, (iii) ponerse en contacto con usted con noticias y/o solicitudes de entrevistas generales o personalizadas relacionadas con WebComprehensive que pueden estar o no estar basadas en sus opiniones, (iv) facilitar, patrocinar y ofrecer ciertas promociones, y supervisar el rendimiento, (v) generar datos estadísticos consolidados y otras informaciones consolidadas y/o inferidas, que WebComprehensive puede utilizar para proporcionar y mejorar sus servicios, (vi) mejorar la seguridad de los datos y las capacidades de prevención de fraudes de WebComprehensive, y (vii) cumplir con todas las leyes y reglamentaciones aplicables. Además, usted (1) representa y garantiza que dicho Feedback es exacto, completo, y no infringe los derechos de terceros; (2) irrevocablemente asigna a WebComprehensive todo derecho, título e interés que pueda tener en dicho Feedback, y (3) expresa e irrevocable renuncia a cualquier y todas las demandas relacionadas con cualesquiera derechos morales pasados, presentes o futuros, derechos de artista o cualquier otro derecho parecido en todo el mundo en o a tal Feedback.",
  "We encourage you to send us any form of feedback or suggestions. If you send us any of these, please make sure that it is accurate and legal.":
    "Le invitamos a enviarnos cualquier forma de comentarios o sugerencias. Si usted nos hace llegar uno de estos, por favor asegúrese de que sea preciso y legal.",
  Privacy: "Privacidad",
  "Certain parts of the WebComprehensive Services (including certain Third Party Services available on them, as explained in more detail in Section 10 below) require or involve the submission, collection and/or use of certain personally identifiable or personally identifiable information. In particular, as part of accessing or using the WebComprehensive Services, WebComprehensive and such Third Party Services may collect, access and use certain data regarding Users and End Users, including the activities or navigation undertaken by Users and End Users through the WebComprehensive services and/or User Platforms. We encourage you to regularly review our Privacy Policy and the relevant policies of each of these Third Party Services for a description of such data collection and use practices.":
    "Ciertas partes de los Servicios de WebComprehensive (incluyendo ciertos Servicios de Terceros disponibles en ellos, según está explicado más en detalle en la Sección 10 a continuación) requieren o implican el envío, la recolección y/o el uso de cierta información personalmente identificante o identificable. En particular y como parte de acceder a o utilizar los Servicios de WebComprehensive, WebComprehensive y dichos Servicios de Terceros podrán recopilar, acceder a y utilizar ciertos datos referentes a los Usuarios y los Usuarios Finales, incluyendo las actividades o navegación realizadas por los Usuarios y los Usuarios Finales a través de los servicios de WebComprehensive y/o Plataformas de Usuario. Le recomendamos que lea con regularidad nuestra Política de Privacidad y las políticas relevantes de cada uno de estos Servicios de Terceros, para ver una descripción de dichas prácticas de recopilación y utilización de datos.",
  "We care about your privacy, and you should too. Please read our Privacy Policy to learn more about our practices regarding personal information.":
    "Nos preocupamos por su privacidad, y usted también debe hacerlo. Por favor lea nuestra Política de Privacidad para aprender más acerca de nuestras prácticas sobre información personal.",
  "AI Services": "Servicios de IA",
  "As part of the WebComprehensive Services, WebComprehensive may provide you with access to artificial intelligence and machine learning tools and products, which will assist you in building your User Platform by generating Content (the “AI Tools”). Additionally, WebComprehensive may provide you with access to artificial intelligence and machine learning tools and products for you to enable and thereby offer your End Users the option to interact with AI (“AI Products”, and together with AI Tools - “AI Services”). This section applies only to the extent that you use AI Services as part of the WebComprehensive Services.":
    "Como parte de los Servicios de WebComprehensive, WebComprehensive puede brindarle acceso a inteligencia artificial y a herramientas y productos de aprendizaje automático, que le ayudarán a construir su Plataforma de Usuario mediante la generación de Contenido (las “Herramientas de IA”). Además, WebComprehensive puede brindarle acceso a herramientas y productos de inteligencia artificial y aprendizaje automático para que usted active y ofrezca así a sus Usuarios Finales la opción de interactuar con la IA (“Productos de IA”, y junto con herramientas de IA - “Servicios de IA”). Esta sección se aplica solo en la medida en que usted utilice los Servicios de IA como parte de los Servicios de WebComprehensive.",
  "Some of our services include AI tools and products.":
    "Algunos de nuestros servicios incluyen herramientas y productos de IA.",
  "Input and result.": "Entrada y resultado.",
  "The AI Services allow you or your End Users to input, provide, or send a message (in the form of text, image, or otherwise) (an “Input”) to the AI Services and thereby direct the AI Services to generate Content as a result (an “Output”).":
    "Los Servicios de IA le permiten a usted o a sus Usuarios Finales introducir, proporcionar, o enviar un mensaje (en forma de texto, imagen o de otro modo) (una “Entrada”) a los Servicios de IA y, por lo tanto, dirigir los Servicios de IA para que generen Contenido como resultado (un “Resultado”).",
  "Accuracy of the Result.": "Precisión del Resultado.",
  "The nature of AI technology is such that it is difficult to fully control and predict outcomes. As such, WebComprehensive does not represent or warrant that the Output generated by the AI Services will meet your or your End Users' needs or expectations, and we disclaim all liability for the accuracy, completeness, relevance, intellectual property compliance, legality, decency, quality, fairness, or any other aspect of such Output. This may occur, for example, in cases where the Output generated relates to medical, legal, or financial matters. The Output may also violate another person's rights, such as privacy or intellectual property rights. The Output may not be unique to you or your End Users, and other Users of the AI Services may generate their own Output that is identical or similar to the Output that may be generated for you, or your End Users. Respectively:":
    "La naturaleza de la tecnología de IA es tal que resulta difícil controlar y predecir totalmente los resultados. Como tal, WebComprehensive no representa ni garantiza que los Resultados generados por los Servicios de IA satisfarán sus necesidades o las de sus Usuarios Finales o sus expectativas, y renunciamos a toda responsabilidad por la exactitud, integridad, relevancia, cumplimiento de la propiedad intelectual, legalidad, decencia, calidad, imparcialidad o cualquier otro aspecto de dicho Resultado. Esto puede suceder, por ejemplo, en los casos en que el Resultado generado esté relacionado con asuntos médicos, legales o temas financieros. El Resultado también puede violar los derechos de otra persona, como la privacidad o los derechos de propiedad intelectual. Es posible que el Resultado no sea exclusivo de usted o sus Usuarios Finales y otros Usuarios de los Servicios de IA pueden generar sus propios Resultados que sean idénticos o similares a los Resultados que puedan generarse para usted, o sus Usuarios Finales. Respectivamente:",
  "You are responsible for reviewing all Output from the AI Tools before using, posting, transmitting, or displaying an Output in any manner, and ensuring that you are legally authorized to use such Output.":
    "Usted es responsable de revisar todos los Resultados de las Herramientas de IA antes de usar, publicar, transmitir o mostrar un Resultado de cualquier manera, y de garantizar que usted está legalmente autorizado a utilizar dichos Resultados.",
  "You must treat the Output as merely a suggestion and decide in your sole discretion whether or not it represents your views, serves your intended purposes, and conveys the message you wish to convey.":
    "Debe tratar el Resultado como una mera sugerencia y decidir en base a su exclusivo criterio si representa o no sus puntos de vista, sirve a sus objetivos previstos y transmite el mensaje que desea transmitir.",
  "You are responsible for informing your End Users, where required by applicable laws and regulations, of any restrictions, limitations, or obligations that End Users must comply with when using the Output from the AI Products, including the need to review the Output before using it.":
    "Usted es responsable de informar a sus Usuarios Finales, cuando así lo requieran leyes y regulaciones aplicables, de cualquier restricción, limitación u obligación que los Usuarios Finales deban cumplir cuando utilicen los resultados de los productos de IA, incluyendo la necesidad de revisar el Resultado antes de utilizarlo.",
  "You acknowledge that the Output generated does not represent the opinions of WebComprehensive.":
    "Reconoce que los Resultados generados no representan las opiniones de WebComprehensive.",
  "The results generated by these tools may not be accurate, truthful, legal or unbiased. Please verify the result before using it, because the result is just a suggestion.":
    "Los resultados generados por estas herramientas pueden no ser precisos, veraces, legales o imparciales. Verifique el resultado antes de usarlo, porque el resultado es solo una sugerencia.",
  "Your entries.": "Sus entradas.",
  "You agree that your and your End Users' Submissions will be considered User Content (as defined above) and the Terms will apply, to the extent permitted by applicable law, to all such Submissions as if they were User Content.":
    "Usted acepta que sus Entradas y las de sus Usuarios Finales se considerarán Contenido del Usuario (como se ha definido anteriormente) y los Términos se aplicarán, en la medida permitida por la ley aplicable, a todas esas Entradas como si fueran Contenido del Usuario.",
  "Ownership of the Result.": "Propiedad del Resultado.",
  "If you choose to use, post, transmit or display a Result in any manner, it will be considered your User Content (as defined above), and the Terms will apply, to the extent permitted by applicable law, as if it were User Content. As between you (or your End Users) and WebComprehensive, WebComprehensive does not claim any ownership rights in the Result to the extent the Result does not contain any pre-existing intellectual property owned by WebComprehensive.":
    "Si elige utilizar, publicar, transmitir o mostrar un Resultado de cualquier manera, se considerará su Contenido de Usuario (como se ha definido anteriormente), y los Términos se aplicarán, en la medida permitida por la ley aplicable, como si fuera Contenido de Usuario. Entre usted (o sus Usuarios Finales) y WebComprehensive, WebComprehensive no reclama ningún derecho de propiedad sobre el Resultado en la medida en que el Resultado no contenga cualquier propiedad intelectual preexistente propiedad de WebComprehensive.",
  "License granted to WebComprehensive.":
    "Licencia otorgada a WebComprehensive.",
  "To the extent a license is required under applicable laws and regulations, and in addition to the license you grant to us under Section 3.1 above, you also grant us a non-exclusive, transferable, sublicensable, royalty-free, worldwide license to: (a) access, upload, copy, perform and display adjustments to, train our software tools on, duplicate, and perform any other technical actions and/or uses with your (and your End Users') Inputs and Results in order to: improve WebComprehensive's services; review Inputs and Results for compliance with applicable laws and enforce the Terms; and (b) provide copies of your (and your End Users') Inputs and Results to our Third Party Services or our subcontractors as required to provide the WebComprehensive Services to our Users.":
    "En la medida en que se requiera una licencia según las leyes y regulaciones aplicables, y además de la licencia que usted nos otorga según la Sección 3.1 arriba, también nos concede una licencia no exclusiva, transferible, sublicenciable, libre de regalías y mundial para: (a) acceder, cargar, copiar, realizar y mostrar ajustes, entrenar nuestras herramientas de software, duplicar y realizar cualquier otra acción y/o uso técnico con sus Entradas y Resultados (y los de sus Usuarios Finales) para: mejorar los servicios de WebComprehensive; revisar las Entradas y Resultados para verificar el cumplimiento de las leyes aplicables y hacer cumplir los Términos; y (b) proporcionar copias de sus Entradas y Resultados (y los de sus Usuarios Finales) a nuestros Servicios de Terceros o a nuestros subcontratistas, según lo requerido para proporcionar los Servicios WebComprehensive a nuestros Usuarios.",
  "Although we do not claim ownership of the results generated, you grant us a license to use them to improve our services and to share them with third-party providers necessary for the operation of those services.":
    "Aunque no reclamamos la propiedad de los resultados generados, nos concede una licencia para utilizarlos con el fin de mejorar nuestros servicios y compartirlos con terceros proveedores necesarios para el funcionamiento de dichos servicios.",
  "Third party services.": "Servicios de terceros.",
  "WebComprehensive may use Third Party Services to provide the AI ​​Services to you and your use of such Third Party Services shall be subject to Section 10 of these Terms. In your use of WebComprehensive's AI Services, including your use of any Input and Output, you acknowledge and agree to review and comply with, and require each of your End Users to review and comply with, the policies of the Third Party AI Services as they may change from time to time.":
    "WebComprehensive puede utilizar Servicios de Terceros para proporcionarle los Servicios de IA a usted y el uso de dichos Servicios de Terceros estará sujeto a la sección 10 de estos Términos. En su uso de los Servicios de IA de WebComprehensive, incluido el uso de cualquier Entrada y Resultado, usted reconoce y acepta revisar y cumplir, y exigir a cada uno de sus Usuarios Finales que revisen y cumplan con las políticas de los Servicios de IA de terceros que pueden cambiar de vez en cuando.",
  "Service charges": "Cargos por servicios",
  "Paid services": "Servicios de pago",
  "Paid Services": "Servicios de Pago",
  "Use of certain WebComprehensive Services may be subject to payment of specific fees, as determined by WebComprehensive in its sole discretion.":
    "El uso de determinados Servicios de WebComprehensive podrá estar sujeto al pago de cargos específicos, según lo determinado por WebComprehensive a su criterio",
  "Rate(s)": "Tarifa(s)",
  "respectively). Important: If you are a Reseller User and your payment is made to the Reseller, then the provisions of paragraphs 6.1 (1), 6.1 (4), 6.2, 6.3 (2), 6.4 (1) and 6.5 of this Section 6 in relation to such specific Payment Services may not apply to you and payment and management of such Payment Services will be made directly with the Reseller, according to the terms agreed between you and the Reseller.":
    "respectivamente). Importante: si usted es un Usuario de Revendedor y su pago se hace al Revendedor, entonces las disposiciones de los incisos 6.1 (1), 6.1 (4), 6.2, 6.3 (2), 6.4 (1) y 6.5 de esta Cláusula 6 en relación con dichos Servicios de Pago específicos pueden no aplicarse a usted y el pago y la gestión de dichos Servicios de Pago se hará directamente con el Revendedor, según los términos acordados entre usted y el Revendedor.",
  "WebComprehensive will notify you of the applicable Fees; you must pay WebComprehensive directly in connection with the Paid Services if you choose to purchase from WebComprehensive. If you wish to receive or use the Paid Services, you must pay all applicable Fees in advance.":
    "WebComprehensive le notificará a usted las Tarifas vigentes; usted debe pagar directamente a WebComprehensive en relación con los Servicios de Pago si decide hacer la compra a WebComprehensive. Si quiere recibir o usar los Servicios de Pago, debe pagar todas las Tarifas correspondientes por adelantado.",
  "WebComprehensive reserves the right to change its Fees at any time at its discretion. WebComprehensive will provide notice of the change to you or Reseller if such change will affect your existing subscriptions.":
    "WebComprehensive se reserva el derecho a cambiar sus Tarifas en cualquier momento a su criterio. WebComprehensive enviará una notificación sobre el cambio a usted o al Revendedor si dicho cambio afectará sus suscripciones existentes.",
  "If you have received a discount or other promotional offer, whether from WebComprehensive or a Reseller, WebComprehensive or the Reseller, respectively, will have the right, upon expiration of the applicable discount period, to automatically and without prior notice renew your subscription for such WebComprehensive Services at the then-current full Rate.":
    "Si usted recibió un descuento u otra oferta promocional, ya sea de WebComprehensive o de un Revendedor, WebComprehensive o el Revendedor, respectivamente, tendrán derecho, al terminar el periodo aplicable de descuento, a renovar automáticamente y sin aviso previo la suscripción para dichos Servicios de WebComprehensive con la Tarifa completa vigente en ese momento.",
  "All Fees paid directly to WebComprehensive will be in U.S. dollars, unless WebComprehensive specifically states otherwise in writing. To the extent permitted by law (and unless WebComprehensive specifically states otherwise in writing), all Fees are exclusive of taxes (including value-added tax, sales tax, goods and services tax, etc.), duties or tariffs imposed by taxing authorities (“Taxes”), and you will be responsible for paying all applicable Taxes related to your use of the WebComprehensive Services or any payments or purchases you make. If WebComprehensive is obligated to collect or pay Taxes on Fees paid by you, whether or not such Taxes have been added and collected from you on prior transactions, such Taxes may be added to the payment for any outstanding Fees and will be reflected on the Invoice for such transaction. We recommend that you confirm any additional fees that may be charged by third parties in connection with your purchase of Paid Services or renewal (such as international transaction fees, currency exchange fees, or fees charged by banks or credit card companies). WebComprehensive is not responsible for any such additional fees or costs.":
    "Todas las Tarifas pagadas directamente a WebComprehensive se harán en dólares estadounidenses, excepto que WebComprehensive indique lo contrario específicamente por escrito. En la medida que lo permita la ley (y a menos que WebComprehensive especifique lo contrario por escrito), todas las Tarifas son sin incluir impuestos (incluido el impuesto por valor agregado, impuesto por venta, impuesto sobre bienes y servicios, etc.), tributos o aranceles aplicados por las autoridades impositivas (“Impuestos”), y usted será responsable de pagar todos los Impuestos aplicables relacionados con su uso de los Servicios de WebComprehensive o por los pagos o las compras que usted haga. Si WebComprehensive estuviera obligado a cobrar o pagar Impuestos por las Tarifas pagadas por usted, ya sea o no que dichos Impuestos se hayan agregado y se le hayan cobrado en operaciones previas, dichos Impuestos podrán agregarse al pago de las Tarifas pendientes y se reflejarán en la Factura de dicha operación. Recomendamos que confirme la existencia de cargos adicionales que puedan cobrar terceros en relación con la compra de los Servicios de Pago o con su renovación (como cargos por transacciones internacionales, cargos por cambio de moneda o cargos hechos por los bancos o las empresas de tarjetas de crédito). WebComprehensive no es responsable por dichos cargos o costos adicionales.",
  "If you purchase Payment Services directly from WebComprehensive, then as part of registering or submitting information to receive Payment Services, you also authorize WebComprehensive (whether directly or through its affiliates, subsidiaries or other third parties) to request and collect payments and service fees (or otherwise charge, refund or take any other billing action) from our payment provider or your designated bank account and to make such inquiries as WebComprehensive or its affiliates deem necessary to validate your designated payment account or financial information to ensure timely payment, and to receive updated payment details from your payment provider, credit card or bank account (e.g., updated expiration date or card number as may be provided to us by your credit card company).":
    "Si usted compra los Servicios de Pago directamente a WebComprehensive, entonces como parte del registro o el envío de información para recibir los Servicios de Pago, también autoriza a WebComprehensive (ya sea de forma directa o a través de sus afiliadas, subsidiarias u otros terceros) a solicitar y cobrar los pagos y las tarifas de servicio (o, de otra forma, a cobrar, hacer reembolsos o hacer cualquier otra acción de facturación) a nuestro proveedor de pagos o su cuenta bancaria designada y a hacer las consultas que WebComprehensive o sus afiliadas estimen necesarias para validar su cuenta designada de pago o información financiera, a fin de garantizar un pago oportuno, además de recibir detalles de pago actualizados de su proveedor de pagos, tarjeta de crédito o cuenta bancaria (p. ej., la fecha de vencimiento o el número de tarjeta actualizados que nos pueda informar su empresa de tarjeta de crédito).",
  "If you are a Reseller User, payment and administration of the Payment Service must be made directly with the Reseller.":
    "Si usted es un Usuario de Revendedor, el pago y la administración del Servicio de Pago deberá realizarse directamente con el Revendedor.",
  Invoices: "Facturas",
  "If you are a Reseller User and you pay the Reseller and not WebComprehensive directly, Clause 6.2 does not apply to you.":
    "Si usted es un Usuario Revendedor y le paga al Revendedor y no directamente a WebComprehensive, la Cláusula 6.2 no se aplica en su caso.",
  "WebComprehensive or its affiliated companies will issue an invoice or credit memo for any Fee payment or refund made to or by WebComprehensive":
    "WebComprehensive o sus empresas afiliadas emitirán una factura o una nota de crédito por cualquier pago de Tarifas o reembolso hecho a o por WebComprehensive",
  Invoice: "Factura",
  "Each Invoice will be issued electronically and in accordance with the country indicated in your billing address, and will be made available to you through your User Account or by email. For the purposes of issuing the Invoice, you may be required to provide certain Personal Information (as defined in the Privacy Policy) in order to comply with local laws. Please note that the Invoice presented in your User Account may be inappropriate according to the requirements of your local laws and, in such case, may only be used as a pro forma invoice.":
    "Cada Factura se emitirá en formato electrónico y de conformidad con el país indicado en su dirección de facturación, y se pondrá a disposición suya a través de su Cuenta de Usuario o por correo electrónico. Para los fines de emitir la Factura, quizá deba brindar determinada Información Personal (según lo definido en la Política de Privacidad) a fin de cumplir con las leyes locales. Tenga en cuenta que la Factura presentada en su Cuenta de Usuario puede ser inadecuada según los requisitos de sus leyes locales y, en dicho caso, solo podrá usarse como factura proforma.",
  "Invoices for our paid services will be available in your user account, unless you are a Reseller User.":
    "Las facturas por nuestros servicios de pago estarán disponibles en su cuenta de usuario, a menos que usted sea un Usuario de Revendedor.",
  "Automatic subscription renewals":
    "Renovaciones automáticas de la suscripción",
  "To ensure that you do not experience any interruption or loss of services, certain Paid Services include a default auto-renewal option whereby, unless you or your Reseller disables the auto-renewal option, such Paid Services will automatically renew at the end of the applicable subscription period for a renewal term equal to the original subscription term (excluding extension periods) and, unless otherwise notified to you (by WebComprehensive or your Reseller), at the same price (subject to applicable changes in Taxes and excluding any discounts or other promotional offers provided for the initial term) (“Renewing Paid Services”). For example, if the original subscription term for a Service is one month, then each of its renewal terms (as applicable) will be one month.":
    "Para garantizar que no sufra ninguna interrupción ni pérdida de servicios, determinados Servicios de Pago incluyen una opción de renovación automática por defecto según la cual, a menos que usted o su Revendedor desactive la opción de renovación automática, dichos Servicios de Pago se renovarán automáticamente al finalizar el periodo aplicable de suscripción por un periodo de renovación igual que el periodo de la suscripción original (sin incluir los períodos de extensión) y, a menos que se le notifique lo contrario (por WebComprehensive o por su Revendedor), con el mismo precio (sujeto a los cambios aplicables en los Impuestos y sin incluir cualquier descuento u otra oferta promocional brindada por el periodo inicial) (“Servicios de Pago con Renovación”). Por ejemplo, si el periodo original de la suscripción de un Servicio es un mes, cada uno de sus periodos de renovación (según corresponda) será de un mes.",
  "Accordingly, as applicable and in connection with Fees paid directly to WebComprehensive, WebComprehensive will attempt to automatically charge the applicable Fees to the Stored Card up to two (2) weeks prior to the commencement of such renewal period. In the event of failure to collect any Fees owed, we may, at our discretion (but are not obligated to), retry the charge at another time or suspend or terminate your User Account, without further notice. If your Renewing Paid Service is subject to a one-year or multi-year subscription period, WebComprehensive will attempt to provide you with notice prior to the renewal of such Paid Service at least thirty (30) days prior to the renewal date.":
    "En consecuencia, según corresponda y en relación con las Tarifas pagadas directamente a WebComprehensive, WebComprehensive intentará cobrar automáticamente las Tarifas aplicables con la Tarjeta Almacenada hasta dos (2) semanas antes de que comience dicho periodo de renovación. En caso de imposibilidad de cobrar las Tarifas adeudadas, podemos, a criterio nuestro (pero sin obligación), reintentar el cobro en otro momento o suspender o cancelar su Cuenta de Usuario, sin más notificación. Si su Servicio de Pago con Renovación está sujeto a un periodo de suscripción de un año o de múltiples años, WebComprehensive intentará darle una notificación antes de la renovación de dicho Servicio de Pago al menos treinta (30) días antes de la fecha de renovación.",
  "By accepting these Terms of Use and": "Al aceptar estos Términos de Uso y",
  "buy a Paid Service with Renewal":
    "comprar un Servicio de Pago con Renovación",
  declares: "declara",
  "and accepts": "y acepta",
  "that the": "que el",
  "Paid Service with Renewal": "Servicio de Pago con Renovación",
  "will be automatically renewed in accordance with the terms mentioned.":
    "se renovará automáticamente de conformidad con los términos mencionados.",
  "You (or the Reseller from whom you purchase Paid Services) may turn off the automatic renewal option for Paid Services with Renewal at any time within your User Account or by visiting the WebComprehensive Help Center.":
    "Usted (o el Revendedor a quien le compre los Servicios de Pago) podrá apagar la opción de renovación automática de los Servicio de Pago con Renovación en cualquier momento dentro de su Cuenta de Usuario o visitando el Centro de Ayuda de WebComprehensive.",
  "Certain domains are subject to a different renewal policy as detailed in the Web.com Reseller Domain Name Registration Agreement and the Tucows Reseller Domain Name Registration Agreement, as applicable.":
    "Determinados dominios están sujetos a una política distinta de renovación, como se detalla en el Acuerdo de registro de nombre de dominios de revendedores de Web.com y el Acuerdo de registro de nombre de dominios de revendedores de Tucows, según corresponda.",
  "Notwithstanding anything to the contrary in the foregoing, you are, and shall remain, solely responsible for verifying and ensuring successful renewal of the WebComprehensive Services you use (whether or not such WebComprehensive Services are subject to automatic subscription renewals). Accordingly, you shall be fully liable in connection with any suspension of any WebComprehensive Services you have previously purchased, including in the event of cancellation, failure to collect applicable Recurring Fees, or failure to automatically renew your subscription for any WebComprehensive Services. You acknowledge and agree that you shall have no claim against WebComprehensive in connection with the suspension of any WebComprehensive Services or Third Party Services, for any reason.":
    "Sin perjuicio de nada al contrario en lo antedicho, usted es, y seguirá siendo, el único responsable de verificar y garantizar la renovación exitosa de los Servicios de WebComprehensive que use (ya sea o no que dichos Servicios de WebComprehensive estén sujetos a renovaciones automáticas de la suscripción). Por consiguiente, será plenamente responsable en relación con toda suspensión de cualquiera de los Servicios de WebComprehensive que haya comprado previamente, incluso en caso de cancelación, incapacidad de cobrar las Tarifas recurrentes aplicables o ausencia de renovaciones automáticas de la suscripción de cualquiera de los Servicios de WebComprehensive. Usted reconoce y acepta que no tendrá ningún reclamo en contra de WebComprehensive en relación con la suspensión de cualquiera de los Servicios de WebComprehensive o los Servicios de Terceros, por cualquier motivo.",
  "To ensure that you do not lose your domain or experience interruptions with your website at the end of your subscription period":
    "Para asegurarse de que usted no pierda su dominio ni sufra interrupciones con su sitio de Internet al final de su periodo de suscripción",
  "we will automatically renew our service and bill you accordingly, at regular intervals based on your initial subscription, unless you (or the Reseller from whom you purchased Paid Services) turn off auto-renewal.":
    "renovaremos automáticamente nuestro servicio y le facturaremos de acuerdo con esto, a intervalos regulares según su suscripción inicial, a menos que usted (o el Revendedor al cual compró los Servicios de Pago) desactiven la renovación automática.",
  "Some services may not": "Es posible que algunos servicios no se",
  "renew automatically": "renueven automáticamente",
  "whether on purpose or by mistake. You must ensure that your subscriptions are renewed on time.":
    "ya sea a propósito o por error. Debe asegurarse de que sus suscripciones se renuevan a tiempo.",
  "Money back guarantee": "Garantía de reembolso",
  "If you are a Reseller User and you pay the Reseller and not WebComprehensive directly, Clause 6.4 does not apply to you.":
    "Si usted es un Usuario Revendedor y le paga al Revendedor y no directamente a WebComprehensive, la Cláusula 6.4 no se aplica en su caso.",
  "If you are dissatisfied with your initial purchase of a Premium Plan, you may provide notice of cancellation for any reason within fourteen (14) days of first ordering or activating such Premium Plan (the “Refund” and “Refund Period”). The Refund will only apply to the initial purchase of a Premium Plan that is a free website upgrade. The Refund does not apply to any additional purchase, upgrade, modification or renewal of WebComprehensive Services. If you live in a jurisdiction that requires a longer Refund Period, we will accommodate such requirements under all applicable laws. If WebComprehensive receives such notice within the Refund Period, WebComprehensive will refund to you the amount WebComprehensive charged you for such WebComprehensive Services, in the same currency as the original charge, and cancel the services accordingly. Please note that the Refund amount may be different than the amount charged due to currency exchange rates and third party fees. WebComprehensive will not be liable for any difference caused by a change in currency exchange rates or third party fees. After the Refund Period, the Fees paid by you will be non-refundable and non-cancellable. In addition, if we determine that a User provided a cancellation notice in bad faith or in an unlawful attempt to avoid payment for services actually received, we reserve our right to still charge the User for all WebComprehensive Services actually received, to the extent permitted by applicable law.":
    "Si no está satisfecho con la compra inicial de un Plan Premium, puede notificar la cancelación por cualquier motivo durante un plazo de catorce (14) días desde la primera solicitud o activación de dicho Plan Premium (el “Reembolso” y el “Periodo de Reembolso”). El Reembolso solo se aplicará a la compra inicial de un Plan Premium que sea una mejora de un sitio web gratis. El Reembolso no se aplica a ninguna compra, actualización, modificación o renovación adicional de los Servicios de WebComprehensive. Si vive en una jurisdicción que exija un Periodo de Reembolso más largo, nos adaptaremos a dichos requisitos según todas las leyes aplicables. Si WebComprehensive recibe dicha notificación dentro del Periodo de Reembolso, WebComprehensive le reembolsará la cantidad que WebComprehensive le haya cobrado por dichos Servicios de WebComprehensive, en la misma moneda que se haya cobrado en primer lugar, y cancelará los servicios de manera acorde. Tenga en cuenta que el monto de Reembolso puede ser distinto que el monto que se haya cobrado por cambios en las monedas y cargos de terceros. WebComprehensive no será responsable por cualquier diferencia causada por un cambio en las tasas de cambio de las monedas o por cargos que hayan aplicado terceros. Después del Periodo de Reembolso, las Tarifas pagadas por usted serán no reembolsables y no cancelables. Además, si determinamos que un Usuario entregó un aviso de cancelación con mala fe o en un intento ilegítimo para evitar el pago de servicios realmente recibidos, nos reservamos nuestro derecho a cobrar de todas formas al Usuario por todos los Servicios de WebComprehensive realmente recibidos, en la medida que lo permita la ley aplicable.",
  Note: "Nota",
  "Certain services purchased on or through the WebComprehensive Services may be non-refundable. This includes Third Party Services, such as domains, tools, and commerce applications. The terms of each purchased service or application are stated on the WebComprehensive Website or as part of or during the purchasing process for such services or applications. It is your responsibility to verify the ability to cancel a service before purchasing it. WebComprehensive will not refund any amounts paid for non-refundable Paid Services, applications, or Third Party Services.":
    "Determinados servicios comprados en o a través de los Servicios de WebComprehensive pueden ser no reembolsables. Esto incluye Servicios de Terceros, como dominios, herramientas y aplicaciones comerciales. Los términos de cada servicio o aplicación comprada se indican en el Sitio Web de WebComprehensive o como parte de o durante el proceso de compra de dichos servicios o aplicaciones. Es responsabilidad suya verificar la capacidad de cancelar un servicio antes de comprarlo. WebComprehensive no reembolsará ningún monto pagado por Servicios de Pago no reembolsables, aplicaciones ni Servicios de Terceros.",
  "We are happy to offer a 14-day money-back guarantee for our monthly or annual paid services when purchased for the first time. This can be extended subject to law.":
    "Estamos encantados de ofrecer una garantía de devolución de 14 días para nuestros servicios de pago mensuales o anuales cuando se compran por primera vez. Esta puede ampliarse conforme a la ley.",
  "Please carefully review the terms of each service before purchasing, as some services are non-refundable.":
    "Por favor revise cuidadosamente los términos de cada servicio antes de comprar, ya que algunos servicios no son reembolsables.",
  Chargebacks: "Contracargos",
  "If at any time we record a decline, chargeback or other rejection of a charge for the Fees Payable on your WebComprehensive account":
    "Si en cualquier momento registramos una declinación, contracargo u otro rechazo de un cargo de las Tasas a pagar en su cuenta de WebComprehensive",
  Chargeback: "Contracargo",
  "this will be deemed a breach of your payment obligations hereunder, and your use of WebComprehensive's services may be automatically disabled or terminated.":
    "esto se considerará como un incumplimiento de sus obligaciones de pago conforme al presente, y el uso de los servicios de WebComprehensive puede ser automáticamente deshabilitado o terminado.",
  "In the event of a Chargeback, your User Account may be blocked without the option to repurchase or reuse it, and the data contained in such User Account, including domains, applications and Third Party Services may be subject to cancellation and Loss of Capacity (as defined in Section 6.3 below).":
    "En caso de que se realice un Contracargo, su Cuenta de Usuario puede ser bloqueada sin la opción de volver a comprarla o reutilizarla, y los datos contenidos en dicha Cuenta de Usuario, incluyendo los dominios, aplicaciones y Servicios de Terceros pueden estar sujetos a cancelación y Pérdida de la Capacidad (según se define en la Sección 6.3 a continuación).",
  "Your use of the WebComprehensive Services will not resume until you re-subscribe to the WebComprehensive Services and pay any applicable charges in full, including all Fees and expenses incurred by WebComprehensive or any Third Party Service for each Chargeback received (including Fees for WebComprehensive Services provided prior to the Chargeback, handling and processing fees, and expenses incurred by the payment processor).":
    "Su uso de los Servicios de WebComprehensive no se reanudará hasta que vuelva a suscribirse a los Servicios de WebComprehensive y pague cualquier cargo aplicable en su totalidad, incluidos todas las Tarifas y gastos incurridos por WebComprehensive o cualquier Servicio de Terceros para cada Contracargo recibido (incluidas las Tarifas para los Servicios de WebComprehensive prestados antes del Contracargo, cargos de manejo y procesamiento y gastos incurridos por el procesador de pagos).",
  "If you have any questions or concerns regarding a payment made to WebComprehensive":
    "Si usted tiene alguna pregunta o inquietud con respecto a un pago efectuado a WebComprehensive",
  "we encourage you to first contact our Customer Service team before filing a Chargeback or Payment Reversal.":
    "le animamos a que primero entre en contacto con nuestro equipo de Atención al Cliente antes de presentar un Contracargo o reversión del pago",
  "to prevent cancellation of WebComprehensive Services and blocking of your User Account, and to prevent unjustified or erroneous filing of a Chargeback, which may result in you being liable for applicable Charges, in addition to repayment of all Fees applicable to WebComprehensive Services purchased (and Chargebacked) by you.":
    "para evitar la cancelación de los Servicios de WebComprehensive y el bloqueo de su Cuenta de Usuario, y para evitar la presentación injustificada o errónea de un Contracargo, lo que puede resultar en que usted sea responsable por los Cargos aplicables, además del repago de todas las Tarifas aplicables a los Servicios de WebComprehensive comprados (y Contracargados) por usted.",
  "We reserve the right to dispute any Chargeback received, including providing the relevant credit card company or financial institution with information and documentation proving that the User responsible for such Chargeback actually authorized the transaction and made use of the services provided thereafter.":
    "Nos reservamos el derecho de disputar cualquier Contracargo recibido, incluyendo el suministrar a la correspondiente empresa de tarjetas de crédito o institución financiera la información y documentación que prueba que el Usuario responsable de tal Contracargo en realidad autorizó la transacción e hizo uso de los servicios prestados después de eso.",
  "Chargebacks may result in us cancelling your account, so please avoid doing so.":
    "Los contracargos pueden llevarnos a cancelar su cuenta, así que le pedimos que evite hacerlos.",
  "Please contact our Customer Care team before filing a chargeback. You will be responsible for any incorrect fees, and any losses and expenses that follow.":
    "Póngase en contacto con nuestro equipo de Atención al Cliente antes de presentar un contracargo. Usted será responsable de cualquier tarifa incorrecta, y las pérdidas y gastos que le siguen.",
  Cancellation: "Cancelación",
  "Cancellation by the User": "Cancelación por el Usuario",
  "You may stop using and request cancellation of your User Account or the WebComprehensive Services at any time, in accordance with the instructions available in the WebComprehensive Services. The effective date and time for such cancellation will be the date and time you complete the cancellation process for the WebComprehensive Services, and the effective date of cancellation for Paid Services will be the end of your subscription period for the Paid Services. Notwithstanding the foregoing, any Paid Services (including Renewing Paid Services) purchased from a Reseller are subject to the cancellation terms agreed upon between you and your Reseller.":
    "Puede dejar de utilizar y solicitar la cancelación de su Cuenta de Usuario o los Servicios de WebComprehensive en cualquier momento, conforme a las instrucciones disponibles en los Servicios de WebComprehensive. La fecha y hora efectiva para dicha cancelación será la fecha y hora en que usted haya completado el proceso de cancelación de los Servicios de WebComprehensive, y la fecha efectiva de cancelación de Servicios de Pago será el final del periodo de suscripción de los Servicios de Pago.Sin perjuicio de lo antedicho, cualquier Servicio de Pago (incluidos los Servicio de Pago con Renovación) comprado a un Revendedor está sujeto a los términos de cancelación acordados entre usted y su Revendedor.",
  "Notwithstanding anything to the contrary in the foregoing, in relation to subscriptions for Paid Services with Renewal, such subscriptions will be suspended only upon expiration of the relevant period for which you have already paid. Please note that as the process may take a few days, to avoid the next automatic renewal and the corresponding charge, the cancellation request must be made at least fourteen (14) days before the expiration of the current service period.":
    "Sin perjuicio de cualquier disposición al contrario en lo antedicho, en relación con las suscripciones de los Servicio de Pago con Renovación, dichas suscripciones se suspenderán solo al vencimiento del periodo correspondiente que ya haya pagado. Tenga en cuenta que como el proceso puede tardar unos días, para evitar la próxima renovación automática y el cargo respectivo, la solicitud de cancelación debe hacerse por lo menos catorce (14) días antes de la expiración del período de servicio actual.",
  "For more information about canceling Paid Services you have purchased from WebComprehensive, please visit the WebComprehensive Help Center.":
    "Para obtener más información sobre la cancelación de los Servicios de Pago que haya comprado a WebComprehensive, visite el Centro de Ayuda de WebComprehensive.",
  "For information about canceling Paid Services you purchased from a Reseller, please contact your Reseller.":
    "Para obtener información sobre la cancelación de los Servicios de Pago que haya comprado a un Revendedor, comuníquese con su Revendedor.",
  "You may cancel your account or any of the Services at any time. Once we process your cancellation request, we will not charge you for any additional subscription renewals. Cancellation of Paid Service purchased from a Reseller is subject to the terms agreed upon between you and your Reseller.":
    "Usted puede cancelar su cuenta o cualquiera de los servicios en cualquier momento. Una vez que procesemos su solicitud de cancelación, no le cobraremos ninguna renovación de suscripción adicional. La cancelación del Servicio de Pago comprado a un Revendedor está sujeta a los términos acordados entre usted y su Revendedor.",
  "Cancellation by WebComprehensive": "Cancelación por WebComprehensive",
  "Your breach of any of the WebComprehensive Terms or failure to pay any Fees due will give WebComprehensive the right to suspend (until full payment is made) or terminate your User Account and User Platform (or certain features thereof), as well as the provision of related WebComprehensive Services (e.g., Paid Services) or Third Party Services to you.":
    "El incumplimiento de cualquiera de los Términos de WebComprehensive o la falta de pago de cualquier Tarifa adeudada dará a WebComprehensive el derecho de suspender (hasta que se realice el pago total) o cancelar su Cuenta de Usuario y Plataforma de Usuario (o ciertas funciones de los mismos), así como la prestación de los Servicios de WebComprehensive relacionados (p. ej., Servicios de Pago) o Servicios de Terceros a usted.",
  "Si abre una Cuenta de Usuario a través de un Revendedor o compra Servicios de Pago a un Revendedor, entonces WebComprehensive tendrá derecho a suspender, cerrar o bloquear el acceso a su Cuenta de Usuario y Plataforma de Usuario (o determinadas funciones de estas) además de la entrega de cualquier Servicio de WebComprehensive o Servicios de Terceros relacionados, en el caso de que: (a) el Revendedor lo solicite a WebComprehensive; o (b) el Revendedor no pague a WebComprehensive cualquier monto adeudado a WebComprehensive en virtud del Contrato del Revendedor. Usted acepta estos derechos de suspensión y cierre y reconoce y acepta que WebComprehensive no tendrá responsabilidad alguna hacia usted en relación con dicha suspensión o cierre. Su único recurso en relación con dicha suspensión o cierre será contra el Revendedor.":
    "Si abre una Cuenta de Usuario a través de un Revendedor o compra Servicios de Pago a un Revendedor, entonces WebComprehensive tendrá derecho a suspender, cerrar o bloquear el acceso a su Cuenta de Usuario y Plataforma de Usuario (o determinadas funciones de estas) además de la entrega de cualquier Servicio de WebComprehensive o Servicios de Terceros relacionados, en el caso de que: (a) el Revendedor lo solicite a WebComprehensive; o (b) el Revendedor no pague a WebComprehensive cualquier monto adeudado a WebComprehensive en virtud del Contrato del Revendedor. Usted acepta estos derechos de suspensión y cierre y reconoce y acepta que WebComprehensive no tendrá responsabilidad alguna hacia usted en relación con dicha suspensión o cierre. Su único recurso en relación con dicha suspensión o cierre será contra el Revendedor.",
  "If you violate any of these terms or fail to make timely payments, we may suspend or terminate your account.":
    "Si usted viola cualquiera de estos términos o no cumple con los pagos oportunos, podemos suspender o cancelar su cuenta.",
  "Loss of data, content and capacity":
    "Pérdida de datos, contenido y capacidad",
  "If your User Account or any WebComprehensive Services or Third Party Services related to your User Account are terminated (whether at your request or at WebComprehensive’s discretion), this may cause or result in the loss of certain content, features or capacity of your User Account, including any User Content, End User data or other usage data held therein, and any domain name registrations or reservations held therein (“Capacity Loss”). WebComprehensive shall have no liability whatsoever for such Capacity Loss, or for backing up your User Account, User Content or End User data. Please also note that additional Fees may apply to reactivating a User Account or the WebComprehensive Services following your termination, as determined by WebComprehensive in its sole discretion.":
    "Si se cancela su Cuenta de Usuario o cualquier Servicio de WebComprehensive o Servicios de Terceros relacionados con su Cuenta de Usuario (ya sea por su petición o a discreción de WebComprehensive), esto puede causar o provocar la pérdida de ciertos contenidos, funciones o capacidad de su Cuenta de Usuario, incluyendo cualquier Contenido de Usuario, datos de Usuarios Finales u otros datos de uso conservados en ellos, y cualquier reserva o registro de nombre de dominio que estuviera incluida en dichos Servicios (“Pérdida de capacidad”). WebComprehensive no será responsable en modo alguno por tal Pérdida de Capacidad, o por guardar una copia de seguridad de su Cuenta de Usuario, Contenido de Usuario o datos de Usuario Final. Tenga también en cuenta que se pueden aplicar Tarifas adicionales a la reactivación de una Cuenta de Usuario o los Servicios de WebComprehensive luego de su cancelación, según lo determinado por WebComprehensive a su entera discreción.",
  "Following the closure of your User Account or User Platform, WebComprehensive reserves the right to delete all data in the normal course of business. Data cannot be recovered once your User Account or User Platform is closed.":
    "Luego del cierre de su Cuenta de Usuario o Plataforma de Usuario, WebComprehensive se reserva el derecho a eliminar todos los datos en el transcurso normal de las operaciones. Los datos no se pueden recuperar una vez que su Cuenta de Usuario o Plataforma de Usuario se cierran.",
  "If your account or any of your services are terminated, loss of content and data may occur.":
    "Si se cancela su cuenta o cualquiera de sus servicios, puede producirse pérdida de contenido y datos.",
  "You are responsible for backing up your data and materials.":
    "Usted es responsable de hacer copia de seguridad de sus datos y materiales.",
  "E-commerce": "Comercio Electrónico",
  "WebComprehensive Services also include certain features that enable you to sell goods, content, media, event tickets and services through your User Platform":
    "Los Servicios de WebComprehensive también incluyen determinadas funciones que le permiten vender bienes, contenido, medios audiovisuales, entradas para eventos y servicios a través de su Plataforma de Usuario",
  "User Products": "Productos del Usuario",
  "and, together with the WebComprehensive Services":
    "y, junto con los Servicios de WebComprehensive",
  "You are fully responsible for your User Products and E-Commerce activities, and for any promotions and related Content contained within or referenced by your User Platform, and for compliance with all applicable laws. We only provide you with the platform to manage your online E-Commerce activities. We are not involved in your relationship or dealings with any actual or potential purchasers of your User Products.":
    "Usted es plenamente responsable por las actividades relacionadas con sus Productos del Usuario y Comercio Electrónico, y por toda promoción y Contenido relacionado que esté dentro de su Plataforma de Usuario o al que se haga referencia, además del cumplimiento con todas las leyes aplicables. Solo le prestamos la plataforma para que gestione sus actividades de Comercio Electrónico en línea. No estamos involucrados en su relación ni en sus operaciones con cualquiera de los compradores reales o potenciales de sus Productos del Usuario.",
  "When someone purchases your User Products, payments for such transactions will be processed through WebComprehensive or a third-party payment service provider":
    "Cuando alguien compre sus Productos del Usuario, los pagos para dichas operaciones se procesarán a través de WebComprehensive o de un proveedor externo de servicios de pago",
  "Payment Service Provider(s)": "Proveedor(es) de Servicios de Pago",
  "You may use our services to sell your products, content and services online.":
    "Usted puede utilizar nuestros servicios para vender sus productos, contenidos y servicios en línea.",
  "WebComprehensive can connect you to allow you to accept payments from your customers.":
    "WebComprehensive puede conectarle para permitirle aceptar pagos de sus clientes.",
  "You are responsible for all of your sales activities, including your relationships with customers and any payment service providers.":
    "Usted es responsable de todas sus actividades de ventas, incluyendo sus relaciones con clientes y cualquier proveedor de servicios de pagos.",
  "Payment Service Providers": "Proveedores de Servicios de Pago",
  "You acknowledge and agree that WebComprehensive will be your default payment service provider.":
    "Usted reconoce y acepta que WebComprehensive será su proveedor de servicio de pago por defecto.",
  "Additionally, subject to territorial limitations, you must accept payments using Apple Pay. By using Apple Pay, you acknowledge and agree to the Apple Pay Platform Web Merchant Terms and Conditions.":
    "Además, sujeto a limitaciones territoriales, debe aceptar pagos con Apple Pay. Al usar Apple Pay, reconoce y acepta los Términos y Condiciones de Comerciantes Web de la Plataforma Apple Pay.",
  "Payment processing through other Payment Service Providers will be governed by the terms of service of such Payment Service Providers. We are not a party to your relationship with the Payment Provider and you are responsible for any interaction with any such Payment Provider, or the actions of any such Payment Provider.":
    "El procesamiento de pagos mediante otros Proveedores de Servicios de Pago estará regido por los términos de servicio de dichos Proveedores de Servicios de Pago. No somos parte en su relación con el Proveedor de Pagos y es responsable de toda interacción con cualquiera de estos Proveedores de Pagos, o de las acciones de cualquiera de estos Proveedores de Pagos.",
  "WebComprehensive may suspend, disable access to, or remove from your User Account, User Platform(s) and/or the WebComprehensive Services any Payment Provider, whether or not incorporated into or forming part of your User Account and/or User Platform(s) at that time, without any liability to you or any End User.":
    "WebComprehensive puede suspender, deshabilitar el acceso o eliminar de su Cuenta de Usuario, Plataforma(s) de Usuario y/o de los Servicios WebComprehensive, cualquier Proveedor de Pagos, esté o no incorporado o forme parte de su Cuenta de Usuario y/o Plataforma(s) de Usuario en ese momento, sin ninguna responsabilidad hacia usted o hacia cualquier Usuario Final.",
  "We may charge service fees for event tickets sold through your site.":
    "Podemos cobrar tarifas de servicio por las entradas a eventos vendidas a través de su sitio.",
  Events: "Eventos",
  "Depending on your plan, WebComprehensive may charge you service fees for tickets to events sold on your site. You hereby agree to pay such fees, as required by WebComprehensive, and authorize WebComprehensive to direct its payment processing partners or your Payment Service Provider, as applicable, to deduct such fees from your relevant transactions or otherwise collect such fees.":
    "Según su plan, WebComprehensive puede cobrarle cargos de servicio por entradas para eventos que se vendan en su sitio. Por el presente, acepta pagar dichos cargos, según lo requerido por WebComprehensive, y autoriza a WebComprehensive a ordenar a sus socios de procesamiento de pagos o a su Proveedor de Servicios de Pago, según corresponda, que deduzcan dichos cargos de sus operaciones relevantes o que cobren dichos cargos de otra forma.",
  "Declarations and guarantees regarding electronic commerce":
    "Declaraciones y garantías sobre el Comercio Electrónico",
  "By using any of our E-Commerce features, you represent, warrant and agree as follows:":
    "Al usar cualquiera de nuestras funciones de Comercio Electrónico, usted declara, garantiza y acepta lo siguiente:",
  "that you are solely and fully responsible for all Taxes and charges of any kind associated with your E-Commerce activities, including all Taxes related to the purchase or sale of User Products, and for collecting, reporting and remitting the correct amounts to the appropriate authorities or reporting such acts to your End Users and providing a duly issued invoice as required by law;":
    "que es responsable único y pleno por todos los Impuestos y los cargos de cualquier tipo asociados con sus actividades de Comercio Electrónico, incluidos todos los Impuestos relacionados con la compra o la venta de los Productos del Usuario, y por el cobro, la declaración y el envío de los montos correctos a las autoridades correspondientes o el informe a sus Usuarios Finales de dichos actos y la entrega de una factura debidamente emitida según lo exigido por ley;",
  "that all Taxes indicated by the E-Commerce functions delivered to you by WebComprehensive are only provided for exemplary purposes and cannot be used as references;":
    "que todos los Impuestos indicados por las funciones de Comercio Electrónico entregados a usted por WebComprehensive solo se entregan para fines de ejemplificación y que no se pueden usar como referencias;",
  "that you are responsible for all costs for the purchase and delivery of the User Products and that you deliver them in a safe and professional manner, consistent with industry standards;":
    "que usted es responsable por todos los costos para la compra y la entrega de los Productos del Usuario y que los entrega de manera segura y profesional, coherente con los estándares de la industria;",
  "that you are fully responsible for all representations and promises you make and for all assistance, warranty and support in connection with the User Products, and that you will provide actual contact information on your User Platform for any questions, complaints or claims; and":
    "que usted es plenamente responsable por todas las declaraciones y promesas que haga y por toda la asistencia, la garantía y el soporte en relación con los Productos del Usuario, y que brindará información de contacto real en su Plataforma de Usuario para cualquier pregunta, queja o reclamo; y",
  "that you may not offer or sell User Products, or provide any information, Content or materials in connection with User Products, that may be deemed dangerous, counterfeit, stolen, fraudulent, offensive or abusive; that are prohibited for sale, distribution or use; or that are otherwise contrary to any applicable law, including in relation to consumer rights, intellectual property or privacy rights, product safety, regulations and trade sanctions, support, maintenance and export; and":
    "que usted no puede ofrecer ni vender Productos del Usuario, ni brindar información, Contenido ni material en relación con los Productos del Usuario, que puedan considerarse como peligrosos, falsificados, robados, fraudulentos, ofensivos o abusivos; que estén prohibidos para la venta, la distribución o el uso; o que de otra forma sean contrarios a cualquier ley aplicable, incluso en relación con los derechos del consumidor, los derechos de propiedad intelectual o privacidad, la seguridad de los productos, los reglamentos y las sanciones comerciales, el soporte, el mantenimiento y la exportación; y",
  "that WebComprehensive may, at any time and in its sole discretion, suspend, disable access to, or remove your User Platform or any User Product, whether or not incorporated, published or integrated with your User Platform at such time, without any liability to you or any End User, including for any Loss of Capacity as a result thereof.":
    "que WebComprehensive puede, en cualquier momento y a su criterio, suspender, desactivar el acceso o eliminar su Plataforma de Usuario o cualquier Producto del Usuario, ya sea o no que esté incorporado, publicado o integrado con su Plataforma de Usuario en dicho momento, sin ninguna responsabilidad hacia usted o cualquier Usuario Final, incluso por cualquier Pérdida de Capacidad como resultado de ello.",
  "Video Services": "Servicios de video",
  "As part of WebComprehensive's services, WebComprehensive may provide video services":
    "Como parte de los servicios de WebComprehensive, WebComprehensive puede proporcionar servicios de vídeo",
  "for the management of videos on the User Platforms (the “":
    "para la gestión de videos en las Plataformas de Usuario (los “",
  "Your use of the video services for your User Platform may require you to obtain a license to use certain MPEG-LA patents (the “License”). It is your sole responsibility to determine whether your activity requires a License and to obtain one. You may obtain information about MPEG LA L.L.C.'s License at MPEG LA L.L.C.":
    "El uso de los servicios de video para su Plataforma de Usuario puede requerir que usted reciba una licencia para utilizar ciertas patentes de MPEG-LA (la “Licencia”). Es su propia responsabilidad exclusiva decidir si su actividad requiere de una Licencia y obtenerla. Puede obtener información sobre la Licencia de MPEG LA L.L.C. en MPEG LA L.L.C.",
  "In addition to anything set forth in Section 15 of these Terms of Use and without any limitation of liability, you shall indemnify, defend and hold harmless WebComprehensive, its officers, directors, shareholders, employees, affiliates and agents, from and against any and all damages, costs, obligations, losses, liabilities and expenses (including attorneys' fees), accrued, arising from or relating to your infringement or misuse of the patent coverage under the MPEG-LA consortium.":
    "Además de lo mencionado en la Sección 15 de estos Términos de Uso y sin ninguna limitación de responsabilidad, usted deberá indemnizar, defender y proteger a WebComprehensive, sus funcionarios, directores, accionistas, empleados, afiliados y agentes, de cualquier y todos los daños y costos, obligaciones, pérdidas, responsabilidades y gastos (incluyendo honorarios de abogados), devengados, que surjan de o se relacionen con la violación o el mal uso de la cobertura del conjunto de patentes bajo el consorcio MPEG-LA.",
  "If your needs require a more inclusive plan than what we currently offer, please contact the support team at: business@webcomprehensive.com.":
    "Si sus necesidades requieren de un plan más inclusivo que los que actualmente ofrecemos, por favor contacte al equipo de soporte a: business@webcomprehensive.com.",
  "Certain use of WebComprehensive Video Services may require a license for the use of certain patents. You are required to verify whether you are required to obtain such a license.":
    "Cierto uso de los Servicios de Video de WebComprehensive puede requerir una licencia para el uso de ciertas patentes. Usted está obligado a verificar si está obligado a obtener dicha licencia.",
  "You may be required to obtain a license for certain online video activities. It is your responsibility to check for such a requirement and obtain this license if necessary.":
    "Es posible que deba obtener una licencia para ciertas actividades de video en línea. Es su responsabilidad comprobar tal requisito y obtener esta licencia si es necesario.",
  "Third party services": "Servicios de terceros",
  "WebComprehensive Services allow you to engage and acquire certain third-party services, products, and tools to enhance your User Platform and overall user experience, including, without limitation, domain registrars from which you may purchase a domain name for your User Website, third-party applications and widgets offered through the WebComprehensive Website":
    "Los Servicios de WebComprehensive le permiten contratar y adquirir ciertos servicios, productos y herramientas de terceros para mejorar su Plataforma de Usuario y su experiencia general de usuario, incluyendo, sin limitaciones, registradores de dominios desde los que puede comprar un nombre de dominio para su Sitio web de Usuario, aplicaciones y widgets de terceros que se ofrecen a través del Sitio Web de WebComprehensive",
  "Third-party Licensed Content, media distribution services, vendors of tangible products, third-party designers who may assist you with your User Platform, external databases, code packages, etc. (collectively":
    "Contenido con Licencia de terceros, servicios de distribución de medios, vendedores de productos tangibles, terceros diseñadores que pueden ayudarle con su Plataforma de Usuario, bases de datos externas, paquetes de código, etc. (en conjunto",
  "Third Party Services": "Servicios de Terceros",
  "You acknowledge and agree that regardless of the manner in which such Third Party Services may be offered to you (including or integrated with certain WebComprehensive Services, offered separately by WebComprehensive or persons certified or authorized by WebComprehensive, or otherwise offered anywhere on the WebComprehensive Services), WebComprehensive in no way endorses such Third Party Services, nor shall it be in any way responsible or liable for them. WebComprehensive shall not be a party to, nor in any way responsible for monitoring, any interaction or transaction between you and any Third Party Service.":
    "Usted reconoce y acepta que, independientemente de la forma en que se pueden ofrecer a usted dichos Servicios de Terceros (incluidos o integrados con ciertos Servicios de WebComprehensive, ofrecidos por separado por WebComprehensive o personas certificadas o autorizadas por WebComprehensive u ofrecidos de alguna otra forma en cualquier lugar en los Servicios de WebComprehensive), WebComprehensive de ninguna manera avala dichos Servicios de Terceros, ni será de cualquier manera responsable y obligada con respecto a los mismos. WebComprehensive no será parte de, ni de ninguna manera será responsable de supervisar, interacción o transacción alguna entre usted y cualquier Servicio de Terceros.",
  "You acknowledge that such services may require payment of additional amounts to WebComprehensive and/or the providers of such Third Party Services.":
    "Usted reconoce que tales servicios pueden requerir el pago de cantidades adicionales a WebComprehensive y/o a los proveedores de dichos Servicios de Terceros.",
  "Any and all use of such Third Party Services shall be made solely at your own risk and responsibility and may be subject to the legal and financial terms governing such Third Party Services, which we encourage you to review before engaging with them.":
    "Cualquiera y todos los usos de dichos Servicios de Terceros se realizarán únicamente a su propio riesgo y responsabilidad y pueden estar sujetos a los términos legales y financieros que regulan dichos Servicios de Terceros, que le invitamos a revisar antes de contratar con ellos.",
  "If you use any third party services, tools, code, algorithms, databases, products, programs or assets while using our Services, you represent that you are acting in accordance with their terms of use. For example, if you use YouTube while using the Services, you must comply with YouTube's applicable terms and privacy policy, as amended at the time of your use of such services.":
    "Si usa servicios, herramientas, código, algoritmos, bases de datos, productos, programas o bienes de terceros mientras usa nuestros Servicios, declara que actúa de conformidad con sus términos de uso. Por ejemplo, si usa YouTube mientras usa los Servicios, debe cumplir los términos aplicables de YouTube y su política de privacidad, en la versión vigente a la fecha de uso de dichos servicios.",
  "Although we hope to avoid such instances, WebComprehensive may, at any time and in its sole discretion, suspend, disable access to, or remove from your User Account, User Platforms or the WebComprehensive Services any third party services, whether or not incorporated with or made part of your User Account or User Platform(s) at that time, without any liability to you or End Users.":
    "Aunque esperamos evitar estos casos, WebComprehensive puede, en cualquier momento y a su entera discreción, suspender, desactivar el acceso o eliminar de su Cuenta de Usuario, Plataformas de Usuario o los Servicios de WebComprehensive, cualquiera de los servicios de terceros, ya sea que esté o no incorporado con o hecho parte de su Cuenta de Usuario o Plataforma(s) de Usuario en ese momento, sin ninguna responsabilidad con usted o los Usuarios Finales.",
  "Our Services allow you to obtain various third-party services and tools that are not affiliated with us.":
    "Nuestros servicios le permiten obtener diversos servicios y herramientas de terceros que no están afiliados con nosotros.",
  "Since we only act as an intermediary platform between you and such parties, you alone will be responsible for your dealings with them.":
    "Ya que solo actuamos como una plataforma de intermediario entre usted y dichas partes, solamente usted será responsable de sus compromisos con ellos.",
  "We may remove services at any time that may affect your website, user account or overall experience.":
    "Podemos eliminar en cualquier momento los servicios que puedan afectar a su sitio web, cuenta de usuario o experiencia en general.",
  "Misconduct and copyright": "Mala conducta y derechos de autor",
  "Misconduct and abuse": "Mala conducta y abuso",
  "By using WebComprehensive's services, you may be exposed to User Platform, User Content or Third Party Services from a variety of sources, which may be inaccurate, offensive, objectionable or unlawful. You hereby waive any legal or equitable rights or remedies you have or may have against WebComprehensive with respect thereto.":
    "Al utilizar los servicios de WebComprehensive, usted puede estar expuesto a la Plataforma de Usuario, Contenido de Usuario o Servicios de Terceros de una variedad de fuentes, que pueden ser inexactas, ofensivas, desagradables o ilegales. Por el presente usted renuncia a cualquier derecho o remedio legal o equitativo que tiene o pueda tener contra WebComprehensive con respeto al mismo.",
  "If you believe that a User or any Third Party Service has acted inappropriately or otherwise misused any of the WebComprehensive Services, please immediately report such User or Third Party Service to us using this form. You agree that your report will not impose any liability or obligation on WebComprehensive, and that WebComprehensive may consider such report and act accordingly, refrain from any action, or require additional information or documents before doing so, at its sole discretion.":
    "Si cree que un Usuario o cualquier Servicio de Terceros actuó en forma inadecuada o de alguna otra forma usó mal cualquiera de los Servicios de WebComprehensive, por favor repórtenos de inmediato a dicho Usuario o Servicio de Terceros usando este formulario. Usted acepta que su informe no impondrá ninguna responsabilidad u obligación sobre WebComprehensive, y que WebComprehensive puede considerar tal informe y actuar en consecuencia, abstenerse de cualquier acción o requerir información adicional o documentos antes de hacerlo, a su sola discreción.",
  "If you witness or experience any misconduct or abusive behavior by anyone using our services, please let us know.":
    "Si usted es testigo de, o sufre cualquier mala conducta o comportamiento abusivo por cualquier persona que utilice nuestros servicios, por favor háganoslo saber.",
  Copyright: "Derechos de Autor",
  "WebComprehensive actúa conforme a su interpretación de la Digital Millennium Copyright Act":
    "WebComprehensive actúa conforme a su interpretación de la Digital Millennium Copyright Act",
  "If you believe that your work has been copied or otherwise used in a way that constitutes copyright infringement, you may notify us of such infringement using this form, or by providing our designated Copyright Agent with the following information in writing: (1) contact information for the person authorized to act on behalf of the copyright owner; (2) a description of the copyrighted work that you claim has been infringed; (3) a description of the material that you claim is infringing or is the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit WebComprehensive to locate the material (including URL address); (4) a statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and (5) a statement that the information in the notification is accurate, and under penalty of perjury, that you are the copyright owner or are authorized to act on behalf of the owner of the copyright that is allegedly infringed. WebComprehensive's Copyright Agent can be reached at the following address:":
    "Si usted cree que su trabajo ha sido copiado o de cualquier otra forma usado de forma que constituye una infracción de los derechos de autor, puede notificarnos esta infracción usando este formulario, o suministrarnos por escrito la siguiente información a nuestro Agente de Derechos de Autor designado: (1) información de contacto de la persona autorizada para actuar en nombre del propietario de los derechos de autor; (2) una descripción del trabajo registrado que usted declara que ha sido infringido; (3) una descripción del material que usted alega que infringe o que es el tema de la actividad que infringe y que debe ser quitado o su acceso debe ser deshabilitado, e información suficiente para permitir a WebComprehensive localizar el material (incluyendo dirección de URL); (4) una declaración de que usted cree de buena fe que el uso del material de la manera que es motivo de la queja no está autorizado por el propietario del derecho de autor, su agente o la ley; y (5) una declaración de que la información en la notificación es exacta y, bajo pena de perjurio, que usted es el propietario de los derechos de autor o está autorizado para actuar en nombre del propietario de los derechos de autor que presuntamente se han infringido. El Agente de Copyright de WebComprehensive se puede encontrar en la siguiente dirección:",
  "In the event that WebComprehensive receives notice of a copyright infringement related to your User Account or the User Platform, it may terminate your User Account, disable your User Platform, or remove any Content at its discretion, with or without notice. In such event, you may file an appropriate remedy under Section 512 of the DMCA, which must include: (1) your full name, address, telephone number, and physical or electronic signature; (2) identification of the material and its location prior to removal; (3) a statement under penalty of perjury that the material was removed by mistake or misidentification; (4) your consent to an appropriate court of law; and (5) any other information required under the provisions of the DMCA. Any notification submitted under this Section 13 may or may not be deemed accepted, enforceable, and compliant with the DMCA, in WebComprehensive's reasonable discretion. WebComprehensive reserves the right to notify the person or entity that provided the notice of infringement of such remedy and will provide the data contained therein.":
    "En caso de que WebComprehensive reciba aviso sobre una infracción de derechos de autor relacionada con su Cuenta de Usuario o la Plataforma de Usuario, puede cancelar su Cuenta de Usuario, desactivar su Plataforma de Usuario o eliminar cualquier Contenido a su discreción, con o sin previo aviso. En tal caso, usted puede presentar un recurso adecuado según la sección 512 de la DMCA, en el cual debe incluir: (1) su nombre completo, dirección, número de teléfono y firma física o electrónica; (2) identificación del material y su ubicación antes de la eliminación; (3) una declaración bajo pena de perjurio de que el material fue eliminado por error o identificación errónea; (4) su consentimiento a un órgano judicial adecuado; y (5) cualquier otra información requerida bajo las disposiciones de la DMCA. Cualquier notificación presentada en virtud de esta sección 13 puede considerarse aceptada, aplicable y compatible con la DMCA, o no, a la discreción razonable de WebComprehensive. WebComprehensive se reserva el derecho de notificar a la persona o entidad que dio el aviso de infracción sobre dicho recurso y proporcionará los datos incluidos en el mismo.",
  "We respect the intellectual property rights of others.":
    "Respetamos los derechos de propiedad intelectual de terceros.",
  "If your copyrighted works have been misused by any of our users, please let us know and provide us with all necessary information, and we will take care of this in accordance with the DMCA.":
    "Si sus obras con derechos de autor fueron utilizadas indebidamente por cualquiera de nuestros usuarios, por favor déjenos saber y proporciónenos toda la información necesaria, y nos encargaremos de esto de común acuerdo con la DMCA.",
  "If we receive a notice of copyright infringement regarding your website or content, we may remove or terminate your website and account.":
    "Si recibimos un aviso de infracción de derechos de autor con respecto a su sitio web o contenido, podemos eliminar o cancelar su sitio web y la cuenta.",
  "Disclaimer of Warranties": "Exclusión de garantías",
  "We provide the WebComprehensive Services on an “as is“, “with all faults“ and “as available“ basis, without warranties of any kind, including any implied warranties or conditions of merchantability, fitness for a particular purpose, workmanlike effort, non-infringement, or any other warranties, all to the fullest extent permitted by law. We specifically do not represent or warrant that the WebComprehensive Services (or any part, feature or content thereof) are complete, accurate, of any quality, reliable or secure in any way, suitable for or compatible with any of your (or your end users') contemplated activities, devices, operating systems, browsers, software or tools (or that they will remain such at any time), or that they will comply with any laws applicable to you or your End Users (including in any jurisdiction in which you operate), or that their operation will be free of any viruses, bugs or other harmful components or program limitations. Furthermore, we do not endorse any entity, product or service (including Third Party Services) mentioned on or made available through the WebComprehensive Services - so be sure to check them out before using or engaging with them in any way.":
    "Prestamos los Servicios de WebComprehensive en una base de “Como Son”, “con todos sus fallos” y “Como esté Disponible”, sin garantías de ningún tipo, incluyendo cualquier garantía implícita o condiciones de comerciabilidad, idoneidad para un propósito particular, esfuerzo profesional, no infracción, o cualquier otra garantía, todo en la mayor medida permitida por la ley. Específicamente no representamos ni garantizamos que los Servicios de WebComprehensive (o cualquier parte, función o contenido de los mismos) sean completos, exactos, de cierta calidad, confiables o seguros de cualquier forma, adecuados para o compatibles con cualquiera de sus actividades contempladas (o las de sus usuarios finales), dispositivos, sistemas operativos, navegadores, software o herramientas (o que permanecerán como tales en cualquier momento), ni que cumplirán con cualquier ley aplicable para usted o sus Usuarios Finales (incluso en cualquier jurisdicción en la que usted opere), o que su funcionamiento esté libre de cualquier virus, bugs u otros componentes dañinos o limitaciones del programa. Por otra parte, no respaldamos ninguna entidad, producto o servicio (incluyendo los Servicios de Terceros) mencionado en o hecho disponible a través de los Servicios de WebComprehensive - así que asegúrese de verificarlos antes de usarlos o contratarlos de cualquier forma.",
  "WebComprehensive may, in its sole discretion (but shall have no obligation to do so), filter, monitor or edit any User Platform or User Content, at any time and for any reason, with or without notice.":
    "WebComprehensive puede, a su sola discreción (sin embargo, no tendrá ninguna obligación de hacerlo), filtrar, monitorizar o editar cualquier Plataforma de Usuario o Contenido de Usuario, en cualquier momento y por cualquier motivo, con o sin previo aviso.",
  "Notwithstanding anything to the contrary foregoing, in no event shall WebComprehensive be deemed a “Publisher” of any User Content, in no way endorses any User Content, and assumes no responsibility for any User Content uploaded, written, posted and/or made available by any User or anyone else on or through the WebComprehensive Services, for any use by any party, or for any loss, deletion or damage thereto or thereto or any loss, damage, costs or expenses which you or others may suffer or incur as a result of or in connection with posting, accessing or reliance on any User Content. Furthermore, WebComprehensive shall not be liable for any mistakes, defamation, libel, falsehoods, obscenity, pornography, inflammatory or other unlawful or infringing User Content you or anyone else may encounter.":
    "Sin perjuicio de cualquier disposición en contrario anterior, en ningún caso puede WebComprehensive ser considerado como “Editor” de cualquier Contenido de Usuario, de ninguna manera avala ningún Contenido de Usuario y no asume ninguna responsabilidad por cualquier Contenido de Usuario cargado, escrito, publicado y/o puesto a disposición por cualquier Usuario o cualquier otro en o a través de los Servicios de WebComprehensive, para cualquier uso por cualquier parte, o por cualquier pérdida, eliminación o daño al mismo o del mismo o cualquier pérdida, daño, costo o gasto que usted u otros puedan sufrir o incurrir como resultado de o en relación con la publicación, acceso o confianza en cualquier Contenido de Usuario. Además, WebComprehensive no será responsable por cualquier error, difamación, calumnias, falsedades, obscenidad, pornografía, incitación o cualquier otro Contenido de Usuario ilícito o infractor que puede encontrar usted o cualquier otro.",
  "You acknowledge that there are risks in using WebComprehensive Services, connecting to or dealing with any Third Party Services through or in connection with WebComprehensive Services, and that WebComprehensive cannot and does not guarantee specific results from such use or interactions, and you hereby assume all such risks, liabilities, or damages of any kind arising in connection with or as a result of such interactions. Such risks may include, but are not limited to, misrepresentation of information about and by Third Party Services or Licensed Content, breach of warranty or contract, violation of rights, and any resulting claims.":
    "Reconoce que existen riesgos en la utilización de los Servicios de WebComprehensive, al conectar o al hacer tratos con cualquier Servicio de Terceros a través de o en relación con los Servicios de WebComprehensive, y que WebComprehensive no puede y no garantiza los resultados específicos de dichos utilización o interacciones, y por el presente usted asume todos esos riesgos, responsabilidades o daño de cualquier tipo que surja en relación con o como resultado de tales interacciones. Tales riesgos pueden incluir, entre otros, tergiversación de información acerca de y por Servicios de Terceros o Contenido Licenciado, incumplimiento de garantía o de contrato, violación de derechos, y cualesquiera reclamaciones consiguientes.",
  "WebComprehensive does not recommend the use of WebComprehensive Services for hosting personal content and will not assume any obligation or risk of security or integrity with respect to non-compliance or damage of any content.":
    "WebComprehensive no recomienda el uso de los Servicios de WebComprehensive para el alojamiento de contenido personal y no asumirá ninguna obligación o riesgo de seguridad o integridad con respecto a incumplimiento o daño de cualquier contenido.",
  "Please note that certain WebComprehensive Services are currently offered in BETA and are undergoing BETA testing. You understand and agree that certain WebComprehensive Services may contain software errors, be subject to interruptions, and may not function as intended or designed. Your use of the WebComprehensive Services in this BETA stage signifies your agreement to participate in BETA testing of the WebComprehensive Services.":
    "Tenga en cuenta que ciertos Servicios de WebComprehensive se ofrecen actualmente en su versión BETA y se están sometiendo a pruebas BETA. Usted entiende y acepta que ciertos Servicios de WebComprehensive pueden contener errores de software, sufrir interrupciones y no funcionar como está previsto o designado. El uso de los Servicios de WebComprehensive en esta etapa BETA significa su acuerdo para participar en las pruebas BETA de los Servicios de WebComprehensive.",
  "We make no warranties regarding our services, including their quality, reliability, security, compatibility and conditions.":
    "No hacemos ninguna garantía con respecto a nuestros servicios, incluyendo su calidad, fiabilidad, seguridad, compatibilidad y condiciones.",
  "We may monitor and edit the website and its content.":
    "Podemos monitorear y editar el sitio web y su contenido.",
  "However, we should not be considered a “publisher” of your content, we do not endorse the content, and we are not responsible for any content used by you or anyone else.":
    "Sin embargo, no debemos ser considerados como un “editor“ de su contenido, no avalamos el contenido, y no nos hacemos responsables por cualquier contenido utilizado por usted o cualquier otra persona.",
  "There are risks in using our services or anyone else's. You accept these risks.":
    "Hay riesgos en el uso de nuestros servicios o los de cualquier otro. Usted acepta estos riesgos.",
  "Some of our services are still in BETA phase and may contain errors or experience interruptions.":
    "Algunos de nuestros servicios están todavía en fase BETA y pueden contener errores o experimentar interrupciones.",
  "Limitation of liability": "Limitación de responsabilidad",
  "To the fullest extent permitted by law in each applicable jurisdiction, neither WebComprehensive nor its officers, directors, shareholders, employees, affiliates and/or agents shall be liable for any direct, indirect, incidental, special, punitive, exemplary or consequential damages of any kind, including any damages resulting from (1) errors, inaccuracies, or inaccuracies of or in any content; (2) personal injury or property damage related to your use of the WebComprehensive Services; (3) any unauthorized access to or use of our servers or any personal information or other information stored therein; (4) any interruption or cessation of transmission to or from the WebComprehensive Services; (5) the use or display of any Content or User Content posted, emailed, transmitted or otherwise made available via the WebComprehensive Services; or (6) events beyond WebComprehensive's reasonable control, including internet failures, equipment failures, power failures, strikes, labor disputes, riots, mutinies, civil disturbances, shortages of labor or materials, fires, floods, storms, earthquakes, explosions, acts of God, war, terrorism, intergalactic strife, governmental actions, orders of courts, agencies and tribunals or the failure of third parties to perform them; and (7) loss of use, data, profits, goodwill or other intangible losses, resulting from the use of or inability to use any of WebComprehensive's Services.":
    "Hasta la mayor medida permitida por la ley en cada jurisdicción aplicable, ni WebComprehensive ni sus funcionarios, directores, accionistas, empleados, afiliados y/o agentes serán responsables de cualquier daño directo, indirecto, incidental, especial, punitivo, ejemplar o consecuente de ningún tipo, incluyendo cualquier daño resultante de (1) errores, imprecisiones o inexactitudes de o en cualquier contenido; (2) lesiones personales o daños a la propiedad relacionados con el uso de los Servicios de WebComprehensive; (3) cualquier acceso no autorizado o uso de nuestros servidores o cualquier información personal u otra información almacenada en ellos; (4) cualquier interrupción o cese de transmisión hacia o desde los Servicios de WebComprehensive; (5) el uso o la visualización de cualquier Contenido o Contenido de Usuario publicado, enviado por correo electrónico, transmitido o puesto a disposición a través de los Servicios de WebComprehensive; o (6) eventos fuera del control razonable de WebComprehensive, incluyendo fallas de internet, fallas de equipos, fallas de la energía eléctrica, huelgas, conflictos laborales, revueltas, motines, disturbios civiles, escasez de mano de obra o materiales, incendios, inundaciones, tormentas, terremotos, explosiones, actos de Dios, guerra, terrorismo, luchas intergalácticas, acciones gubernamentales, órdenes de las cortes, organismos y tribunales o la inejecución de terceros; y (7) pérdida de uso, datos, ganancias, goodwill u otras pérdidas intangibles, que resulten del uso o de la incapacidad de uso de cualquiera de los Servicios de WebComprehensive.",
  "You acknowledge and agree that these limitations of liability are agreed allocations of risk that constitute in part WebComprehensive's consideration for services to you, and such limitations will apply even if WebComprehensive has been advised of the possibility of such liabilities.":
    "Usted reconoce y acepta que estas limitaciones de responsabilidad son asignaciones de riesgo acordadas que constituyen en parte la consideración de los servicios de WebComprehensive hacia usted, y tales limitaciones se aplicarán aunque WebComprehensive haya sido advertido de la posibilidad de tales responsabilidades.",
  "Under applicable law, we will not be liable for any damages.":
    "Conforme a la ley aplicable, no seremos responsables por daños.",
  Immunity: "Inmunidad",
  "You shall defend, indemnify and hold harmless WebComprehensive, its officers, directors, shareholders, employees, affiliates and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including attorneys' fees) arising from: (1) your violation of any of these Terms of Use or any other WebComprehensive Terms; (2) your violation of any third party right, including any copyright, access right, property right or privacy right, resulting from your User Platform or User Content, or use of the WebComprehensive Services; including, without limitation, actions of the WebComprehensive Services for your benefit; and/or (3) any claim that your User Platform and/or User Content has caused damage to a third party.":
    "Deberá defender, indemnizar y proteger a WebComprehensive, sus funcionarios, directivos, accionistas, empleados, afiliados y agentes, de y contra cualesquiera y todas las reclamaciones, daños, obligaciones, pérdidas, responsabilidades, costes o deudas y gastos (incluidos los honorarios de abogados) derivados de: (1) su violación de cualquiera de estos Términos de Uso o cualquiera otro de los Términos de WebComprehensive; (2) su violación de cualquier derecho de terceros, incluyendo cualquier derecho de autor, derechos de acceso, derecho de propiedad o privacidad, resultante de su Plataforma de Usuario o Contenido de Usuario, o el uso de los servicios de WebComprehensive;, incluyendo, sin limitación, las acciones de los Servicios de WebComprehensive para su beneficio; y/o (3) cualquier tipo de reclamo de que su Plataforma de Usuario y/o Contenido de Usuario ha causado daños a un tercero.",
  "If we are sued or otherwise put in danger because of something you did, you will be responsible for the associated costs and damages.":
    "Si somos demandados o somos de otra manera puestos en peligro a causa de algo que usted hizo, usted asumirá los gastos y perjuicios asociados.",
  "Changes and updates": "Cambios y actualizaciones",
  "WebComprehensive reserves the right to change, suspend or cancel any of the WebComprehensive Services (or features thereof or prices applicable thereto), and/or terminate your access to any of the WebComprehensive Services (including removing any materials created by you in connection with the WebComprehensive Services) for any reason and/or modify any of the WebComprehensive Terms with or without notice - at any time and in any manner.":
    "WebComprehensive se reserva el derecho de cambiar, suspender o cancelar cualquiera de los Servicios de WebComprehensive (o funciones de los mismos o precios aplicables a los mismos),y/ o cancelar su acceso a cualquiera de los Servicios de WebComprehensive (incluyendo el retiro de cualesquiera materiales creados por usted en conexión con los Servicios de WebComprehensive) por cualquier razón y/o modificar cualquiera de los Términos de WebComprehensive con o sin previo aviso - en cualquier momento y en cualquier forma.",
  "You agree that WebComprehensive shall not be liable to you or any third party for any modification, suspension or discontinuance of such WebComprehensive Services (or any materials, content or services created, developed or connected by you in connection with the WebComprehensive Services).":
    "Usted acepta que WebComprehensive no será responsable ante usted o ante terceros por cualquier modificación, suspensión o discontinuación de tales Servicios de WebComprehensive (ni de materiales, contenidos o servicios creados, desarrollados o conectados por usted en relación con los Servicios de WebComprehensive).",
  "If such changes involve the payment of additional Fees, we will provide you with notice of such Fees prior to activating such specific changes. If you fail or refuse to pay such Fees, we may (at our sole discretion) terminate your User Account (as explained in more detail in Section 6 above), continue to support the previously effective WebComprehensive Services without enabling such changes, or provide you with alternative Services.":
    "Si tales cambios implican el pago de Tarifas adicionales, le daremos un aviso de dichas Tarifas antes de activar dichos cambios específicos. Si usted no paga o se niega a pagar dichas Tarifas, podemos (a nuestra exclusiva discreción) cancelar su Cuenta de Usuario (como se explica con más detalle en la Sección 6 anterior), continuar soportando los Servicios de WebComprehensive anteriormente vigentes sin habilitar esos cambios o proporcionarle Servicios alternativos.",
  "We may make changes to our services or these terms at any time.":
    "Podemos hacer cambios a nuestros servicios o estos términos en cualquier momento.",
  "Applicable Law and Jurisdiction; Class Action Waiver":
    "Ley aplicable y jurisdicción; Renuncia de la acción de clase",
  "You expressly acknowledge and agree that WebComprehensive shall have the right to enforce these Terms of Use against you.":
    "Usted reconoce y acepta de forma expresa que WebComprehensive tendrá derecho a ejecutar estos Términos de Uso en contra de usted.",
  "The WebComprehensive Terms, the rights and remedies provided under this warranty, and any and all claims and disputes relating to these Terms or the WebComprehensive Services, their interpretation, or the breach, termination or validity thereof, the relationships resulting from or under the WebComprehensive Terms, or any related transactions or purchases, shall be governed by, construed under and be binding in all respects solely and exclusively in accordance with the internal substantive laws of the State of Argentina, without respect to its conflict of laws principles.":
    "Los términos de WebComprehensive, los derechos y remedios proporcionados bajo esta garantía y cualesquiera y todos los reclamos y disputas relacionados con el presente Reglamento o con los servicios de WebComprehensive, su interpretación, o el incumplimiento, terminación o validez del mismo, las relaciones que resulten de o en virtud de los Términos de WebComprehensive, o cualquier transacción o compra relacionada, se regirá, interpretará bajo y será obligatoria en todos los sentidos única y exclusivamente con arreglo a las leyes sustantivas internas del estado de Argentina, sin respecto a sus principios de conflicto de las leyes.",
  "Any and all such claims and disputes shall be brought in a court of competent jurisdiction located in Buenos Aires, Argentina, and you hereby consent to their exclusive resolution by such court. The application of the United Nations Convention on Contracts for the International Sale of Goods is expressly excluded.":
    "Todas y cada una de dichas reclamaciones y disputas se presentarán ante un tribunal de jurisdicción competente situado en Buenos Aires, Argentina, y usted consiente por la presente en que sean resueltas exclusivamente por dicho tribunal. Queda expresamente excluida la aplicación de la Convención de las Naciones Unidas sobre los Contratos de Compraventa Internacional de Mercaderías.",
  "Subject to any applicable law, all disputes between you and WebComprehensive will only be resolved on an individual basis, and you will not have the right to bring any claim against WebComprehensive as a plaintiff or as a class member in a class, consolidated or representative action (or any other legal proceeding brought by a group or by representatives on behalf of others).":
    "Con sujeción a cualquier ley aplicable, todas las disputas entre usted y WebComprehensive solo serán resueltas en forma individual y usted no tendrá derecho a presentar ninguna reclamación contra WebComprehensive como demandante o como miembro de una acción de clase, consolidada o representativa (o cualquier otro procedimiento legal realizado por un grupo o por representantes en nombre de otros).",
  "Notwithstanding anything to the contrary in this Section 15.2, if you are located in the United States of America, (i) the legal jurisdiction governing all aspects of the payment transaction contemplated by Section 6 between you and WebComprehensive shall be the State of New York, without respect to its conflict of laws principles, and (ii) any and all claims and disputes relating to such payment transaction contemplated by Section 6 shall be brought in, and you hereby consent to have them resolved exclusively by, a court of competent jurisdiction located in New York, New York.":
    "No obstante lo contrario a lo expresado en esta Sección 15.2, si usted se encuentra en los Estados Unidos de América, (i) la jurisdicción legal que rige todos los aspectos de la transacción de pago contemplada en la Sección 6 entre usted y WebComprehensive será el Estado de Nueva York, sin respetar sus principios de conflicto de leyes, y (ii) cualquier y todas las reclamaciones y disputas relacionadas con dicha transacción de pago contempladas en la Sección 6 serán presentadas en, y por la presente usted da su consentimiento a que sean resueltas exclusivamente por, un tribunal de jurisdicción competente ubicado en Nueva York, Nueva York.",
  "These Terms and our relationship shall be governed by the laws of the State of Argentina. Disputes between us may only be brought before the courts of Buenos Aires, Argentina.":
    "Estos términos y nuestra relación se regirán por las leyes del estado de Argentina. Las disputas entre nosotros solo pueden ser llevadas ante los tribunales de Buenos Aires, Argentina.",
  Notifications: "Notificaciones",
  "We may give you notices by any of the following methods: (1) through the WebComprehensive Services, including a banner or pop-up on the WebComprehensive Website, User Account or elsewhere; (2) by email, sent to the email address you provided to us, (3) if you are a Reseller user, through your Reseller, and/or (4) by any other means, including any telephone number or physical address or number you have provided to us. WebComprehensive's notice to you will be deemed received and effective within twenty-four (24) hours after it was posted or sent via any of the foregoing methods, unless otherwise stated in the notice.":
    "Podemos darle avisos por cualquiera de los siguientes métodos: (1) a través de los Servicios de WebComprehensive, incluyendo un banner o pop-up en la Página Web de WebComprehensive, Cuenta de Usuario o en otros lugares; (2) por correo electrónico, enviado a la dirección de correo electrónico que nos proporcionó, (3) si usted es un usuario Revendedor, a través de su Revendedor, y/o (4) por cualquier otro medio, incluyendo cualquier número de teléfono o dirección física o número que nos haya proporcionado. El aviso de WebComprehensive para usted se considerará recibido y efectivo dentro de las veinticuatro (24) horas después de que fue publicado o enviado a través de cualquiera de los métodos anteriores, salvo que se indique otra cosa en el aviso.",
  "We may provide you (or your Reseller, if you are a Reseller User) with notices about our services, by email or through other contact methods you have provided to us.":
    "Podemos proporcionarle a usted (o a su Revendedor, si usted es un Usuario de Revendedor) notificaciones sobre nuestros servicios, por correo electrónico o a través de otras vías de contacto que nos haya proporcionado.",
  Relationship: "Relación",
  "The WebComprehensive Terms, and your use of the WebComprehensive Services, are not and shall not be construed to create any partnership, joint venture, employer-employee, agency or franchisor-franchisee relationship between you and WebComprehensive.":
    "Los Términos de WebComprehensive, y su uso de los Servicios de WebComprehensive, no se interpretan y no deberán interpretarse en el sentido de que crean cualquier asociación, empresa conjunta, relación de empleador-empleado, agencia o franquiciador-franquiciado entre usted y WebComprehensive.",
  "Acceptance of these terms and services does not form a partnership or any special relationship between us.":
    "La aceptación de estos términos y servicios no forma una sociedad o ninguna relación especial entre nosotros.",
  "Contract Integrity": "Integridad del Contrato",
  "These Terms of Use, together with the WebComprehensive Terms and any other legal notices or payment notices sent to you by WebComprehensive, shall constitute the entire agreement between you and WebComprehensive concerning the subject matter hereof and supersede any and all prior or contemporaneous agreements, understandings, promises, conditions, negotiations, arrangements or representations, whether oral or written, between you and WebComprehensive, including those made by or between any of our respective representatives, with respect to any of the WebComprehensive Services. You further agree that you are not relying on any promise, inducement, representation, statement, disclosure or duty of disclosure by WebComprehensive in entering into any of the WebComprehensive Terms.":
    "Estos Términos de Uso, junto con los Términos de WebComprehensive y cualesquiera otras notificaciones legales o avisos de pago enviados a usted por WebComprehensive, constituirán el acuerdo completo entre usted y WebComprehensive sobre el tema presente de los mismos y reemplaza cualquier y todos los acuerdos, entendimientos, promesas, condiciones, negociaciones, convenios o representaciones anteriores o contemporáneos, ya sea orales o escritos, entre usted y WebComprehensive, incluyendo aquellas hechas por o entre cualquiera de nuestros respectivos representantes, con respecto a cualquiera de los Servicios de WebComprehensive. Usted además acepta que no se está basando en ninguna promesa, inducción, representación, declaración, revelación o deber de divulgación de WebComprehensive al entrar en cualquiera de los Términos de WebComprehensive.",
  "These Terms (together with our Privacy Policy and any additional terms) shall constitute the sole and entire agreement between us.":
    "Estos términos (junto con nuestra Política de Privacidad y términos adicionales) constituirá el único y entero acuerdo entre nosotros.",
  Assignment: "Asignación",
  "WebComprehensive may assign its rights and/or obligations under this Agreement or transfer the rights and title in the WebComprehensive Services and/or Licensed Content to a third party without your consent or prior notice to you. You may not assign or transfer any of your rights and obligations hereunder without the prior written consent of WebComprehensive. Any attempted or actual assignment thereof without the prior express written consent of WebComprehensive shall be null and void. In any event, an assignment or transfer pursuant to this Section 17.6 shall not by itself grant WebComprehensive or you the right to terminate any of the WebComprehensive Services or Third Party Services then in effect.":
    "WebComprehensive puede ceder sus derechos y/u obligaciones bajo este acuerdo o transferir los derechos y título de propiedad en los Servicios de WebComprehensive y/o Contenido Licenciado a un tercero sin consentimiento de usted o aviso previo. Usted no puede asignar o transferir ninguno de sus derechos y obligaciones conforme al presente sin el previo consentimiento por escrito de WebComprehensive. Cualquier asignación intentada o real del mismo sin previo consentimiento expreso escrito de WebComprehensive será nula y sin valor. En cualquier caso, una asignación o transferencia de acuerdo con esta Sección 17.6 no otorgará por sí sola a WebComprehensive ni a usted el derecho a cancelar ninguno de los Servicios de WebComprehensive ni los Servicios de Terceros entonces en efecto.",
  "If you are a Reseller User, then your subscription with the Reseller in connection with your User Account or User Platform (or any part thereof) may be assigned to WebComprehensive. You agree that if the subscription (or any part thereof) of a WebComprehensive User Account or User Platform with the Reseller is assigned to WebComprehensive, the surviving rights to access and use the User Account and User Platform shall be subject to these Terms of Use, in their entirety (as amended from time to time), and you agree to the full application of these Terms of Use, including, without limitation, the billing and payment provisions set forth herein. You agree that, following any such assignment, you shall, upon WebComprehensive's request, provide such information as is necessary to secure payment for any Paid Service that commences after such assignment.":
    "Si es un Usuario Revendedor, entonces su suscripción con el Revendedor en relación con su Cuenta de Usuario o Plataforma de Usuario (o cualquier parte de estas) podrá cederse a WebComprehensive. Usted acepta que si la suscripción (o cualquier parte de esta) de una Cuenta de Usuario o Plataforma de Usuario de WebComprehensive con el Revendedor se cede a WebComprehensive, los derechos sobrevivientes para acceder a la Cuenta de Usuario y la Plataforma de Usuario y usarlas estarán sujetos a estos Términos de Uso, de manera integral (según sus modificaciones de tanto en tanto), y acepta la aplicación plena de estos Términos de Uso, incluidas, entre otras, las disposiciones sobre facturación y pago establecidas en el presente. Acepta que, luego de dicha cesión, usted deberá, a pedido de WebComprehensive, brindar la información que sea necesaria para garantizar el pago de cualquier Servicio de Pago que comience después de dicha cesión.",
  "We may assign our rights and obligations to third parties. You may only do so with our prior written consent.":
    "Podríamos ceder nuestros derechos y obligaciones a terceros. Solo podrá hacerlo con nuestro consentimiento previo por escrito.",
  "If you are a Reseller User, your User Account may be assigned to WebComprehensive. In such case, if you wish to continue using your User Account, you agree to be legally bound by WebComprehensive's Terms of Use, and to provide WebComprehensive with all information necessary to secure payment for all Paid Services you have purchased.":
    "Si usted es un Usuario de Revendedor, su Cuenta de Usuario puede estar asignada a WebComprehensive. En tal caso, si desea continuar usando su Cuenta de Usuario, acepta la obligación legal de los Términos de Uso de WebComprehensive, y proporcionar a WebComprehensive toda la información necesaria para asegurar el pago de todos los Servicios de pago que haya adquirido.",
  "Divisibility and waivers": "Divisibilidad y renuncias",
  "If any provision of the WebComprehensive Terms is held by a court of competent jurisdiction to be invalid, unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable and shall not affect the validity and enforceability of any remaining provisions. No waiver of any breach or default of any of the WebComprehensive Terms shall be deemed a waiver of any preceding or subsequent breach.":
    "Si cualquier disposición de los Términos de WebComprehensive es considerada inválida, ilegal, nula o por cualquier razón inaplicable por un tribunal de jurisdicción competente, dicha disposición se considerará divisible y no afectará la validez y exigibilidad de las disposiciones restantes. Ninguna Renuncia de cualquier violación o incumplimiento de cualquiera de los Términos de WebComprehensive se considerará una renuncia de cualquier incumplimiento anterior o posterior.",
  "These terms are independent of each other, in case any of them is found to be invalid.":
    "Estos términos son independientes uno del otro, en caso de que se encuentre que alguno de ellos es inválido.",
  Interpretation: "Interpretación",
  "Any heading, subheading or section title contained herein and any explanation or summary in the right-hand column “#AsiDeFacil” is provided for convenience only and in no way defines or explains any present section or provision or legally binds any of us in any manner.":
    "Cualquier encabezado, subtítulo o título de sección aquí contenido y cualquier explicación o resumen en la columna de la derecha “#AsiDeFacil”, se da siempre solo por conveniencia y en ningún modo define o explica cualquier sección o disposición presente o legalmente nos une a cada uno de nosotros de ninguna manera.",
  "These Terms of Use were written in English and translated into other languages for your convenience. You may access and view other language versions by changing your language settings on the WebComprehensive Website. If a translated (non-English) version of these Terms of Use conflicts in any way with the English version, the provisions of the English version shall prevail.":
    "Estos Términos de Uso fueron escritos en inglés y traducidos a otros idiomas para su conveniencia. Puede acceder a y ver otras versiones de idioma, cambiando su configuración de idioma en la Página Web de WebComprehensive. Si una versión traducida (no en inglés) de estos Términos de Uso entra en conflicto de cualquier manera con la versión en Inglés, prevalecerán las disposiciones de la versión en inglés.",
  "Only the left column is legally binding (this column is for clarity only).":
    "Sólo la columna de la izquierda es legalmente vinculante (esta columna es sólo para mayor claridad).",
  "Translated (non-English) versions of these terms are provided for your convenience only.":
    "Las versiones traducidas (no en inglés) de estos términos se proporcionan únicamente para su comodidad.",
  "Contact Customer Service": "Contacto con el Servicio al Cliente",
  "Please contact our Customer Service at business@webcomprehensive.com":
    "Comuníquese con nuestro Servicio de Atención al Cliente mediante business@webcomprehensive.com",
  "Do you have questions or suggestions? We'd love to hear from you!":
    "¿Tiene preguntas o sugerencias? ¡Nos encantaría escucharlas!",
  "About Us": "Acerca de nosotros",
  About: "Nosotros",
  // "Privacy Policy of": "Politica de Privacidad de",
  "In force from ": "En vigor desde ",
  "Welcome to the Privacy Policy of ":
    "Bienvenido a la Política de Privacidad de ",
  "This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from ":
    "Esta Política de privacidad describe cómo se recopila, utiliza y comparte su información personal cuando visita o hace una compra en ",
  " (hereinafter referred to as the “Site”).":
    " (denominado en lo sucesivo el “Sitio”).",
  "PERSONAL INFORMATION WE COLLECT": "INFORMACIÓN PERSONAL QUE RECOPILAMOS",
  "When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device.":
    "Cuando visita el Sitio, recopilamos automáticamente cierta información sobre su dispositivo, incluida información sobre su navegador web, dirección IP, zona horaria y algunas de las cookies que están instaladas en su dispositivo.",
  "Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, the web pages or search terms that referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information”.":
    "Además, a medida que navega por el Sitio, recopilamos información sobre las páginas web individuales o los productos que ve, las páginas web o los términos de búsqueda que lo remitieron al Sitio e información sobre cómo interactúa usted con el Sitio. Nos referimos a esta información recopilada automáticamente como “Información del dispositivo”.",
  "We collect Device Information by using the following technologies:":
    "Recopilamos Información del dispositivo mediante el uso de las siguientes tecnologías:",
  "Log files": "Archivos de registro",
  "“Log Files” track actions occurring on the Site and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.":
    "Los “Archivos de registro” rastrean las acciones que ocurren en el Sitio y recopilan datos, incluyendo su dirección IP, tipo de navegador, proveedor de servicio de Internet, páginas de referencia/salida y marcas de fecha/horario.",
  "Web beacons, tags and pixels": "Balizas web, etiquetas y píxeles",
  "“Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site.":
    "Las “balizas web”, las “etiquetas” y los “píxeles” son archivos electrónicos utilizados para registrar información sobre cómo navega usted por el Sitio.",
  "Additionally when you make a purchase or attempt to make a purchase through the Site, we collect certain information from you, including your name, billing address, shipping address, payment information (including credit card numbers), email address, and phone number.":
    "Además, cuando hace una compra o intenta hacer una compra a través del Sitio, recopilamos cierta información de usted, entre la que se incluye su nombre, dirección de facturación, dirección de envío, información de pago (incluidos los números de la tarjeta de crédito), dirección de correo electrónico y número de teléfono.",
  "Order Information": "Información del pedido",
  "We refer to this information as “Order Information”.":
    "Nos referimos a esta información como “Información del pedido”.",
  "When we talk about “Personal Information” in this Privacy Policy, we mean both Device Information and Order Information.":
    "Cuando hablamos de “Información personal” en la presente Política de privacidad, nos referimos tanto a la Información del dispositivo como a la Información del pedido.",
  "HOW DO WE USE YOUR PERSONAL INFORMATION?":
    "¿CÓMO UTILIZAMOS SU INFORMACIÓN PERSONAL?",
  "We use the Order Information that we collect generally to prepare any orders placed through the Site (including processing your payment information, arranging for shipping, and providing you with invoices and/or order confirmations).":
    "Usamos la Información del pedido que recopilamos en general para preparar los pedidos realizados a través del Sitio (incluido el procesamiento de su información de pago, la organización de los envíos y la entrega de facturas y/o confirmaciones de pedido).",
  "Additionally, we use this Order Information to:":
    "Además, utilizamos esta Información del pedido para:",
  "communicate with you;": "comunicarnos con usted;",
  "screen our orders for fraud or potential risk;":
    "examinar nuestros pedidos en busca de fraudes o riesgos potenciales;",
  "and when, in accordance with the preferences you have shared with us, provide you with information or advertising relating to our products or services.":
    "y cuando de acuerdo con las preferencias que usted compartió con nosotros, le proporcionamos información o publicidad relacionada con nuestros productos o servicios.",
  "We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating reports and statistics on how our customers browse and interact with the Site and to assess the success of our advertising and marketing campaigns).":
    "Utilizamos la Información del dispositivo que recopilamos para ayudarnos a detectar posibles riesgos y fraudes (en particular, su dirección IP) y, en general, para mejorar y optimizar nuestro Sitio (por ejemplo, al generar informes y estadísticas sobre cómo nuestros clientes navegan e interactúan con el Sitio y para evaluar el éxito de nuestras campañas publicitarias y de marketing).",
  "SHARING YOUR PERSONAL INFORMATION": "COMPARTIR SU INFORMACIÓN PERSONAL",
  "We share your Personal Information with third parties to assist us in using your Personal Information, as described above.":
    "Compartimos su Información personal con terceros para que nos ayuden a utilizar su Información personal, tal como se describió anteriormente.",
  "For example, we use WebComprehensive technology in our online store.":
    "Por ejemplo, utilizamos la tecnología de  WebComprehensive en nuestra tienda online.",
  "You can learn more about how WebComprehensive uses your Personal Information here: https://store.webcomprehensive.com/policies.":
    "Puede obtener más información sobre cómo  WebComprehensive utiliza su Información personal aquí: https://store.webcomprehensive.com/policies.",
  "We also use Google Analytics to help us understand how our customers use the Site.":
    "También utilizamos Google Analytics para ayudarnos a comprender cómo usan nuestros clientes el Sitio.",
  "You can learn more about how Google uses your Personal Information here: https://www.google.com/intl/en/policies/privacy/.":
    "Puede obtener más información sobre cómo Google utiliza su Información personal aquí: https://www.google.com/intl/es/policies/privacy/.",
  "You can opt out of Google Analytics here: https://tools.google.com/dlpage/gaoptout.":
    "Puede darse de baja de Google Analytics aquí: https://tools.google.com/dlpage/gaoptout.",
  "Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.":
    "Finalmente, también podemos compartir su Información personal para cumplir con las leyes y regulaciones aplicables, para responder a una citación, orden de registro u otra solicitud legal de información que recibamos, o para proteger nuestros derechos.",
  "BEHAVIORAL ADVERTISING": "PUBLICIDAD ORIENTADA POR EL COMPORTAMIENTO",
  "As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you.":
    "Como se describió anteriormente, utilizamos su Información personal para proporcionarle anuncios publicitarios dirigidos o comunicaciones de marketing que creemos que pueden ser de su interés.",
  "For more information about how targeted advertising works, you can visit the Network Advertising Initiative (“NAI”) educational page at http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.":
    "Para más información sobre cómo funciona la publicidad dirigida, puede visitar la página educativa de la Iniciativa Publicitaria en la Red (“NAI“ por sus siglas en inglés) en http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.",
  "You can opt out of targeted advertising by following the links below:":
    "Puede darse de baja de la publicidad dirigida mediante los siguientes enlaces:",
  "GOOGLE: https://adssettings.google.com/authenticated?hl=en":
    "GOOGLE: https://adssettings.google.com/authenticated?hl=es",
  "BING: https://about.ads.microsoft.com/en-us/resources/policies/personalized-ads":
    "BING: https://about.ads.microsoft.com/es-es/recursos/directivas/anuncios-personalizados",
  "Additionally, you may opt out of some of these services by visiting the Digital Advertising Alliance's opt-out portal at: https://optout.aboutads.info/.":
    "Además, puede darse de baja de algunos de estos servicios visitando el portal de exclusión voluntaria de Digital Advertising Alliance en: ttp://optout.aboutads.info/.",
  "DO NOT TRACK": "NO RASTREAR",
  "Please note that we do not alter our Site's data collection and use practices when we see a Do Not Track signal from your browser.":
    "Tenga en cuenta que no alteramos las prácticas de recopilación y uso de datos de nuestro Sitio cuando vemos una señal de No rastrear desde su navegador.",
  "YOUR RIGHTS": "SUS DERECHOS",
  "If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted.":
    "Si usted es un residente europeo, tiene derecho a acceder a la información personal que tenemos sobre usted y a solicitar que su información personal sea corregida, actualizada o eliminada.",
  "If you wish to exercise this right, please contact us using the contact information below.":
    "Si desea ejercer este derecho, comuníquese con nosotros a través de la información de contacto que se encuentra a continuación.",
  "Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above.":
    "Además, si usted es un residente europeo, tenemos en cuenta que estamos procesando su información para cumplir con los contratos que podamos tener con usted (por ejemplo, si realiza un pedido a través del Sitio) o para perseguir nuestros intereses comerciales legítimos enumerados anteriormente.",
  "Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States.":
    "Además, tenga en cuenta que su información se transferirá fuera de Europa, incluidos Canadá y los Estados Unidos.",
  "DATA RETENTION": "RETENCIÓN DE DATOS",
  "When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.":
    "Cuando realiza un pedido a través del Sitio, mantendremos su Información del pedido para nuestros registros a menos que y hasta que nos pida que eliminemos esta información.",
  MINORS: "MENORES",
  "The site is not intended for persons under 18 years of age.":
    "El sitio no está destinado a personas menores de 18 años de edad.",
  CHANGES: "CAMBIOS",
  "We may update this privacy policy from time to time to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.":
    "Podemos actualizar esta política de privacidad periódicamente para reflejar, por ejemplo, cambios en nuestras prácticas o por otros motivos operativos, legales o reglamentarios.",
  "CONTACT US": "CONTÁCTENOS",
  "For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at ":
    "Para obtener más información sobre nuestras prácticas de privacidad, si tiene alguna pregunta o si desea presentar una queja, contáctenos por correo electrónico a ",
  "Welcome to ": "¡Bienvenido a los términos de uso de ",
  "'s Terms of Use! We are really glad you are on board. Thank you for choosing to use our services.":
    "! Estamos realmente muy contentos de que usted esté a bordo. Gracias por elegir usar nuestros servicios.",
  "This website is operated by ": "",
  ". Throughout the site, the terms “we”, “us” and “our” refer to ":
    " gestiona este sitio web. En todo el sitio, los términos ”nosotros”, ”nos” y ”nuestro” se refieren en lo sucesivo a ",
  " offers this website, including all information, tools and services made available to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.":
    " ofrece esta página web, incluida toda la información, las herramientas y los servicios que se ponen en este sitio a disposición suya, el usuario, siempre y cuando acepte la totalidad de los términos, condiciones, políticas y avisos contemplados aquí.",
  "By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (hereinafter referred to as “Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink.":
    "Al visitar nuestro sitio y/o comprarnos algo, usted interactúa con nuestro “Servicio“ y reconoce como vinculantes los siguientes términos y condiciones (denominados en lo sucesivo “Términos del servicio“, “Términos“), incluidos aquellos términos y condiciones adicionales y las políticas que se mencionan aquí y/o disponibles por medio de hipervínculo.",
  "These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content.":
    "Estos Términos del servicio se aplican a todos los usuarios del sitio, incluyendo de manera enunciativa mas no limitativa los usuarios que son navegadores, proveedores, clientes, comerciantes y/o que aporten contenido.",
  "Please read these Terms of Service carefully before accessing or using our website.":
    "Lea estos Términos del servicio detenidamente antes de acceder o utilizar nuestra página web.",
  "By accessing or using any part of the site, you agree to these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services.":
    "Al acceder o utilizar cualquier parte del sitio, usted acepta estos Términos del servicio. Si no acepta la totalidad de los términos y condiciones de este acuerdo, no podrá acceder al sitio web ni utilizar ningún servicio.",
  "If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.":
    "Si estos Términos del servicio se considerasen una oferta, la aceptación se limita expresamente a los presentes Términos del servicio.",
  "Any new features or tools which are added to the current store shall also be subject to the Terms of Service.":
    "Las nuevas funciones o herramientas que se agreguen a la tienda actual también estarán sujetas a los Términos del servicio.",
  "We reserve the right to update, change or replace any part of these Terms of Service by posting updates and changes to our website.":
    "Nos reservamos el derecho de actualizar, cambiar o reemplazar cualquier parte de los presentes Términos del servicio mediante la publicación de actualizaciones o cambios en nuestra página web.",
  "It is your responsibility to check this page periodically for changes.":
    "Es su responsabilidad revisar esta página periódicamente para ver los cambios.",
  "Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.":
    "Su uso de la página web o el acceso a ella de forma continuada después de la publicación de cualquier cambio constituye la aceptación de dichos cambios.",
  "Our store is hosted on WebComprehensive. They provide us with the online e-commerce platform that allows us to sell our products and services to you.":
    "Nuestra tienda está alojada en WebComprehensive. Nos proporcionan la plataforma de comercio electrónico en línea que nos permite venderle nuestros productos y servicios.",
  "ONLINE STORE TERMS": "TÉRMINOS DE LA TIENDA ONLINE",
  "By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.":
    "Al aceptar los presentes Términos del servicio, usted declara que tiene la mayoría de edad en su estado o provincia de residencia, o que es mayor de edad en su estado o provincia de residencia y que nos ha dado su consentimiento para permitir que cualquiera de las personas menores que dependen de usted utilice este sitio.",
  "You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).":
    "No puede utilizar nuestros productos para ningún fin ilegal o no autorizado ni puede, al hacer uso del Servicio, infringir las leyes de su jurisdicción (incluyendo de manera enunciativa más no limitativa, las leyes de derechos de autor).",
  "You will not transmit any computer worms or viruses or any code of a destructive nature.":
    "No transmitirá ningún gusano o virus informáticos ni ningún código de naturaleza destructiva.",
  "A breach or violation of any of the Terms will result in immediate termination of your Services.":
    "El incumplimiento o violación de cualquiera de los Términos dará como resultado la rescisión inmediata de sus Servicios.",
  "GENERAL CONDITIONS": "CONDICIONES GENERALES",
  "We reserve the right to refuse service to anyone, for any reason, at any time.":
    "Nos reservamos el derecho de rechazar el servicio a cualquier persona, por cualquier motivo, en cualquier momento.",
  "You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to adapt to and conform to technical requirements of connecting networks or devices.":
    "Usted comprende que su contenido (sin incluir la información de la tarjeta de crédito) puede transferirse sin cifrar e implicar (a) transmisiones en varias redes; y (b) cambios para adaptarse a los requisitos técnicos de conexión de redes o dispositivos y cumplir con ellos.",
  "Credit card information is always encrypted during transfer over networks.":
    "La información de la tarjeta de crédito siempre se cifra durante la transferencia a través de las redes.",
  "You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us.":
    "Usted acepta no reproducir, duplicar, copiar, vender, revender ni aprovechar ninguna parte del Servicio, uso del Servicio o acceso al Servicio o cualquier contacto en el sitio web a través de la cual se presta el servicio, sin nuestro permiso expreso por escrito.",
  "The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.":
    "Los encabezados utilizados en este acuerdo se incluyen solo para facilitar la lectura y no limitarán ni afectarán los presentes Términos.",
  "ACCURACY, COMPLETENESS AND CHRONOLOGY OF INFORMATION":
    "EXACTITUD, TOTALIDAD Y CRONOLOGÍA DE LA INFORMACIÓN",
  "We are not responsible if information available on this site is not accurate, complete or current.":
    "No nos responsabilizamos si la información disponible en este sitio no es precisa, completa o actualizada.",
  "The material presented on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more recent sources of information.":
    "El material presentado en este sitio se proporciona solo para información general y no se debe confiar en él ni utilizarlo como la única base para tomar decisiones sin consultar fuentes de información principales, más precisas, más completas o más recientes.",
  "Your reliance on any material on this site is at your own risk.":
    "Al confiar en cualquier material de este sitio lo hace por su cuenta y riesgo.",
  "This site may contain certain historical information.":
    "Este sitio puede contener cierta información histórica.",
  "Historical information is inevitably not current and is provided for reference only.":
    "La información histórica, inevitablemente, no es actual y se proporciona únicamente como referencia.",
  "We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site.":
    "Nos reservamos el derecho de modificar el contenido de este sitio en cualquier momento, pero no tenemos la obligación de actualizar ninguna información en nuestro sitio.",
  "You agree that it is your responsibility to monitor changes to our site.":
    "Usted acepta que es su responsabilidad controlar los cambios en nuestro sitio.",
  "MODIFICATIONS TO THE SERVICE AND PRICES":
    "MODIFICACIONES AL SERVICIO Y PRECIOS",
  "Prices of our products are subject to change without notice.":
    "Los precios de nuestros productos están sujetos a cambios sin previo aviso.",
  "We reserve the right to modify or discontinue the Service (or any part or content thereof) without notice at any time.":
    "Nos reservamos el derecho de modificar o discontinuar el Servicio (o cualquier parte o contenido del mismo) sin previo aviso en cualquier momento.",
  "We will not be liable to you or any third party for any modification, price change, suspension or discontinuance of the Service.":
    "No seremos responsables ante usted ni ante ningún tercero por ninguna modificación, cambio de precio, suspensión o interrupción del Servicio.",
  "PRODUCTS OR SERVICES": "PRODUCTOS O SERVICIOS",
  "Certain products or services may be available exclusively online through the website.":
    "Ciertos productos o servicios pueden estar disponibles exclusivamente online a través del sitio web.",
  "These products or services may have limited quantities and are subject to return or exchange only in accordance with our Return Policy.":
    "Estos productos o servicios pueden tener cantidades limitadas y están sujetos a devolución o cambio solo de acuerdo con nuestra Política de devolución.",
  "We have made every effort to display as accurately as possible the colors and images of our products that appear at the store.":
    "Hemos hecho todo lo viable para mostrar con la mayor precisión posible los colores y las imágenes de nuestros productos que aparecen en la tienda.",
  "We cannot guarantee that your computer monitor's display of any color will be accurate.":
    "No podemos garantizar que la visualización de cualquier color en el monitor de su computadora sea precisa.",
  "We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction.":
    "Nos reservamos el derecho, pero no estamos obligados, de limitar las ventas de nuestros productos o servicios a cualquier persona, región geográfica o jurisdicción.",
  "We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or services that we offer.":
    "Podemos ejercer este derecho caso por caso. Nos reservamos el derecho de limitar las cantidades de cualquier producto o servicio que ofrecemos.",
  "All descriptions of products or product pricing are subject to change at anytime without notice, at the sole discretion of us.":
    "Todas las descripciones de los productos o los precios de los productos están sujetos a cambios en cualquier momento y sin previo aviso, a nuestra entera discreción.",
  "We reserve the right to discontinue any product at any time.":
    "Nos reservamos el derecho de discontinuar cualquier producto en cualquier momento.",
  "Any offer for any product or service made on this site is void where such product or service is prohibited.":
    "Cualquier oferta de cualquier producto o servicio que se realice en este sitio no tiene validez donde dicho producto o servicio esté prohibido.",
  "We do not warrant that the quality of any products, services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected.":
    "No garantizamos que la calidad de cualquier producto, servicio, información u otro material que usted haya comprado u obtenido cumplirá con sus expectativas, o que cualquier error en el Servicio se corregirá.",
  "ACCURACY OF BILLING AND ACCOUNT INFORMATION":
    "EXACTITUD DE LA FACTURACIÓN Y DE LA INFORMACIÓN DE LA CUENTA",
  "We reserve the right to refuse any order you place on our store.":
    "Nos reservamos el derecho de rechazar cualquier pedido que realice en nuestra tienda.",
  "We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order.":
    "Podemos, a nuestro exclusivo criterio, limitar o cancelar las cantidades compradas por persona, por hogar o por pedido.",
  "These restrictions may include orders placed by or under the same customer account, the same credit card, or orders that use the same billing or shipping address.":
    "Estas restricciones pueden incluir pedidos realizados con la misma cuenta de cliente, la misma tarjeta de crédito o pedidos que usen la misma dirección de facturación o de envío.",
  "In the event that we make a change to or cancel an order, we will attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made.":
    "En el caso de que realicemos un cambio o cancelemos un pedido, intentaremos notificarle vía correo electrónico o la dirección de facturación / número de teléfono proporcionados en el momento en que se realizó el pedido.",
  "We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors.":
    "Nos reservamos el derecho de limitar o prohibir los pedidos que, a nuestra entera discreción, parezcan haber sido realizados por comerciantes, revendedores o distribuidores.",
  "You agree to provide current, complete and accurate purchase and account information for all purchases made at our store.":
    "Usted acepta suministrar información completa y precisa de la compra y cuenta actual, para todas las compras realizadas en nuestra tienda.",
  "You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.":
    "Usted acepta actualizar rápidamente su cuenta y demás informaciones, entre ellas, su dirección de correo electrónico, los números de tarjeta de crédito y las fechas de vencimiento, para que podamos completar sus transacciones y contactarlo según sea necesario.",
  "For more information, please see our Returns Policy.":
    "Para obtener más información, consulte nuestra Política de devoluciones.",
  "OPTIONAL TOOLS": "HERRAMIENTAS OPCIONALES",
  "We may provide you with access to third-party tools over which we neither monitor nor have any control nor input.":
    "Podemos proporcionarle acceso a herramientas de terceros que no supervisamos, ni tenemos ningún control sobre ellas, ni tampoco contribuimos.",
  "You acknowledge and agree that we provide access to such tools “as is” and “as available” without any warranties, representations or conditions of any kind and without any endorsement.":
    "Usted reconoce y acepta que brindamos acceso a dichas herramientas “tal como se encuentran“ y “según disponibilidad“ sin garantías, representaciones ni condiciones de ningún tipo y sin ningún tipo de respaldo.",
  "We shall have no liability whatsoever arising from or relating to your use of optional third-party tools.":
    "No tendremos ninguna responsabilidad como consecuencia del uso que haga de herramientas opcionales de terceros o en relación a ellas.",
  "Any use by you of optional tools offered through the site is entirely at your own risk and discretion and you should ensure that you are familiar with and approve of the terms on which tools are provided by the relevant third-party provider(s).":
    "Cualquier uso que haga de las herramientas opcionales ofrecidas a través del sitio es por su cuenta y riesgo, y debe asegurarse de estar familiarizado con los términos según los cuales los proveedores externos relevantes suministran dichas herramientas y aprobarlos.",
  "We may also, in the future, offer new services and/or features through the website (including, the release of new tools and resources).":
    "También podemos, en el futuro, ofrecer nuevos servicios o funciones a través del sitio web (incluido el lanzamiento de nuevas herramientas y recursos).",
  "These new features or services will also be subject to these Terms of Service.":
    "Estas nuevas funciones o servicios también estarán sujetos a los presentes Términos de servicio.",
  "THIRD PARTY LINKS": "ENLACES DE TERCEROS",
  "Certain content, products and services available via our Service may include third-party resources.":
    "Algunos contenidos, productos y servicios disponibles a través de nuestro Servicio pueden incluir recursos de terceros.",
  "Third-party links on this site may direct you to third-party websites that are not affiliated with us.":
    "Los enlaces de terceros en este sitio pueden dirigirlo a páginas web de terceros que no están afiliados a nosotros.",
  "We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party resources or websites, or for any other materials, products, or services of third-parties.":
    "No somos responsables de examinar o evaluar el contenido o la exactitud, ni garantizamos ni asumiremos ninguna obligación ni responsabilidad por los recursos o páginas web de terceros, ni por ningún otro material, producto o servicio de terceros.",
  "We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites.":
    "No somos responsables de ningún daño o perjuicio relacionado con la compra o el uso de bienes, servicios, recursos, contenido o cualquier otra transacción realizada en conexión con sitios web de terceros.",
  "Please review carefully the third-party's policies and practices and make sure you understand them before you engage in any transaction.":
    "Revise cuidadosamente las políticas y prácticas de terceros, y asegúrese de comprenderlas antes de participar en cualquier transacción.",
  "Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.":
    "Las quejas, reclamos, inquietudes o preguntas referentes a productos de terceros deben dirigirse a estos.",
  "USER COMMENTS, OPINIONS AND OTHER COMMUNICATIONS":
    "COMENTARIOS DE LOS USUARIOS, OPINIONES Y OTRAS COMUNICACIONES",
  "If, at our request, you send certain specific submissions (for example contest entries) or without a request from us you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively, 'comments'), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you forward to us.":
    "Si, a petición nuestra, usted envía ciertas comunicaciones específicas (por ejemplo, participaciones en un concurso) o, sin una solicitud nuestra, envía ideas creativas, sugerencias, propuestas, planes u otros materiales, ya sea online, por correo electrónico, por correo postal, o de otro modo (denominado en lo sucesivo y de manera colectiva, 'comentarios'), usted acepta que podemos, en cualquier momento, sin restricción: editar, copiar, publicar, distribuir, traducir y usar en cualquier medio cualquier comentario que usted nos envíe.",
  "We are and shall be under no obligation (1) to maintain any comments in confidence;":
    "No tenemos ni tendremos ninguna obligación (1) de mantener ningún comentario de manera confidencial;",
  "(2) pay any compensation for any comments;":
    "(2) pagar alguna compensación por cualquier comentario;",
  "or (3) respond to any comments.": "o (3) responder a cualquier comentario.",
  "We may, but have no obligation to, monitor, edit or remove content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party's intellectual property or these Terms of Service.":
    "Podemos, pero no tenemos la obligación de monitorear, editar o eliminar contenido que a nuestra entera discreción determinemos que es ilegal, ofensivo, amenazante, calumnioso, difamatorio, pornográfico, obsceno u objetable, o que infrinja la propiedad intelectual de cualquiera de las partes o de los presentes Términos del servicio.",
  "You agree that your comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary right.":
    "Usted acepta que sus comentarios no infringirán ningún derecho de terceros, incluidos los derechos de autor, marca registrada, privacidad, personalidad u otro derecho personal o de propiedad.",
  "You further agree that your comments will not contain libelous, unlawful, abusive or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website.":
    "Además, acepta que sus comentarios no contendrán material difamatorio, ilegítimo, abusivo u obsceno, ni contendrán ningún virus informático ni otro software dañino que pueda afectar de cualquier manera el funcionamiento del Servicio o de cualquier sitio web relacionado.",
  "You may not use a false e-mail address, pretend to be someone other than yourself, or otherwise mislead us or third parties as to the origin of any comments.":
    "No puede utilizar una dirección de correo electrónico falsa, simular ser otra persona o engañarnos o engañar a terceros sobre el origen de los comentarios.",
  "You are solely responsible for any comments you make and their accuracy.":
    "Usted es el único responsable de los comentarios que realice y de su exactitud.",
  "We take no responsibility and assume no liability for any comments posted by you or any third-party.":
    "No asumimos ninguna responsabilidad ni ninguna obligación por los comentarios publicados por usted o por un tercero.",
  "PERSONAL INFORMATION": "INFORMACIÓN PERSONAL",
  "Your submission of personal information through the store is governed by our Privacy Policy. To view our Privacy Policy.":
    "El envío de la información personal que haga a través de la tienda se rige por nuestra Política de privacidad. Para ver nuestra Política de privacidad.",
  "ERRORS, INACCURACIES AND OMISSIONS": "ERRORES, INEXACTITUDES Y OMISIONES",
  "There may be information on our site or in the Service that occasionally contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability.":
    "Puede haber información en nuestro sitio o en el Servicio que, ocasionalmente, contenga errores tipográficos, inexactitudes u omisiones que puedan relacionarse con descripciones de productos, precios, promociones, ofertas, cargos por envío de productos, tiempos de tránsito y disponibilidad.",
  "We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order).":
    "Nos reservamos el derecho de corregir cualquier error, inexactitud u omisión, de cambiar o actualizar información, o cancelar pedidos si alguna información en el Servicio o en cualquier página web relacionada es inexacta en cualquier momento sin previo aviso (incluso después de haber enviado su pedido).",
  "We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law.":
    "No asumimos ninguna obligación de actualizar, modificar o aclarar la información en el Servicio o en cualquier sitio web relacionado, incluyendo de manera enunciativa pero no limitativa, la información de precios, excepto cuando lo exija la ley.",
  "No specified update or update date applied to the Service or any Related Websites should be taken to indicate that all information in the Service or any Related Websites has been modified or updated.":
    "Ninguna actualización especificada o fecha de actualización aplicada en el Servicio o en cualquier sitio web relacionado debe tomarse para indicar que toda la información en el Servicio o en cualquier sitio web relacionado se modificó o actualizó.",
  "PROHIBITED USES": "USOS PROHIBIDOS",
  "In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information;":
    "Además de las prohibiciones establecidas en los Términos del servicio, se le prohíbe utilizar el sitio o su contenido (a) para cualquier propósito ilegal; (b) para solicitar a otros que realicen o participen en cualquier acto ilegal; (c) para infringir cualquier reglamento, norma, ley u ordenanza local internacional, federal, provincial o estatal; (d) para infringir o violar nuestros derechos de propiedad intelectual o los derechos de propiedad intelectual de otros; (e) acosar, abusar, insultar, dañar, difamar, calumniar, denigrar, intimidar o discriminar por motivos de género, orientación sexual, religión, etnia, raza, edad, nacionalidad o discapacidad; (f) enviar información falsa o engañosa;",
  "(g) to upload or transmit viruses or any other type of harmful code that will or may affect the functionality or operation of the Service or any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet.":
    "(g) cargar o transmitir virus o cualquier otro tipo de código dañino que afecte o pueda afectar a la funcionalidad o el funcionamiento del Servicio o de cualquier sitio web relacionado, de otros sitios web o de Internet; (h) recopilar o rastrear la información personal de otros; (i) enviar correo no deseado, phishing, pharm, pretexto, spider, rastrear o extraer; (j) para cualquier propósito obsceno o inmoral; o (k) para interferir o eludir las funciones de seguridad del Servicio o de cualquier sitio web relacionado, o de otros sitios web o de Internet.",
  "We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.":
    "Nos reservamos el derecho de dar por terminado su uso del Servicio o de cualquier sitio web relacionado por infringir cualquiera de los usos prohibidos.",
  "DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY":
    "DESCARGO DE RESPONSABILIDAD DE GARANTÍAS; LIMITACIÓN DE RESPONSABILIDAD",
  "We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure or error-free.":
    "No garantizamos, representamos ni aseguramos que el uso que haga de nuestro servicio será sin interrupciones, oportuno, seguro o sin errores.",
  "We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable.":
    "No garantizamos que los resultados que se puedan obtener del uso del servicio sean exactos o confiables.",
  "You agree that from time to time we may remove the service for indefinite periods of time or cancel the service at any time, without notice to you.":
    "Usted acepta que periódicamente, podamos eliminar el servicio por lapsos de tiempo indefinidos o cancelar el servicio en cualquier momento, sin notificarle.",
  "You expressly agree that your use of, or inability to use, the service is at your sole risk. The service and all products and services delivered to you through the service are (except as expressly stated by us) provided 'as is' and 'as available' for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.":
    "Usted acepta expresamente que su uso del servicio o la imposibilidad de utilizarlo corre por su riesgo. El servicio y todos los productos y servicios que se le entregan a través del servicio (salvo que así lo especifiquemos nosotros) se ofrecen “tal como están“ y “según disponibilidad“ para su uso, sin ninguna representación, garantía o condición de ningún tipo, ya sea expresa o implícita, entre las que se incluyen todas las garantías implícitas o condiciones de comerciabilidad, calidad comercial, idoneidad para un propósito particular, durabilidad, título y no violación.",
  "In no case shall ": "En ningún caso ",
  ", our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the service or any products procured using the service, or for any other claim related in any way to your use of the service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the service, even if advised of their possibility.":
    ", nuestros directores, funcionarios, empleados, afiliados, agentes, contratistas, pasantes, proveedores, proveedores de servicios o licenciantes serán responsables de cualquier lesión, pérdida, reclamo o cualquier daño directo, indirecto, incidental, punitivo, especial o consecuente de cualquier tipo, incluyendo de manera enunciativa más no limitativa; la pérdida de beneficios, pérdida de ingresos, pérdida de ahorros, pérdida de datos, costos de reemplazo o daños similares, ya sea por contrato, perjuicio (incluida la negligencia), responsabilidad estricta o de otro tipo, que surjan del uso que haga de cualquiera de los servicios o de cualquier producto adquirido por medio del servicio, o para cualquier otro reclamo relacionado de alguna manera con su uso del servicio o de cualquier producto, incluidos, entre otros, cualquier error u omisión en cualquier contenido, o cualquier pérdida o daño de cualquier tipo en el que se haya incurrido como resultado del uso del servicio o de cualquier contenido (o producto) publicado, transmitido o puesto a disposición a través del servicio, incluso si se informa de su posibilidad.",
  "Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.":
    "Debido a que algunos estados o jurisdicciones no permiten la exclusión o la limitación de la responsabilidad por daños consecuentes o incidentales, en dichos estados o jurisdicciones, nuestra responsabilidad se limitará a la extensión máxima de lo permitido por la ley.",
  COMPENSATION: "INDEMNIZACIÓN",
  "You agree to indemnify, defend and hold harmless ":
    "Usted acepta indemnizar, defender y mantener indemne a ",
  " and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys' fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.":
    " y a nuestra empresa matriz, subsidiarias, afiliadas, asociados, funcionarios, directores, agentes, contratistas, licenciantes, proveedores de servicios, subcontratistas, proveedores, pasantes y empleados, de cualquier reclamo o demanda, incluidos los honorarios razonables de abogados, en los que un tercero haya incurrido debido a su incumplimiento de los presentes Términos del servicio o de los documentos que incorporan como referencia o que surjan por su incumplimiento de los mismos, o por la violación de cualquier ley o derechos de un tercero que haga.",
  DIVISIBILITY: "DIVISIBILIDAD",
  "In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the validity and enforceability of any other remaining provisions.":
    "En caso de que se determine que alguna disposición de los presentes Términos del servicio sea ilegal, nula o inaplicable, dicha disposición será, no obstante, ejecutable en la medida en que lo permita la legislación aplicable, y la parte inaplicable se considerará separada de los presentes Términos del servicio, sin que dicha determinación afecte a la validez y aplicabilidad de las demás disposiciones.",
  RESCISSION: "RESCISIÓN",
  "The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this Agreement for all purposes.":
    "Las obligaciones y responsabilidades de las partes incurridas antes de la fecha de rescisión seguirán vigentes después de la rescisión de este contrato a todos los efectos.",
  "These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our site.":
    "Estos Términos del servicio se encuentran vigentes a menos que usted o nosotros los rescindamos. Puede rescindir los presentes Términos del servicio en cualquier momento al notificarnos que ya no desea utilizar nuestros Servicios o cuando cese de utilizar nuestro sitio.",
  "If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).":
    "Si a nuestro juicio usted incumple, o sospechamos que ha incumplido con cualquier término o disposición de los presentes Términos del servicio, podemos rescindir igualmente este acuerdo en cualquier momento sin previo aviso y usted seguirá siendo responsable de todos los importes adeudados, hasta la fecha de rescisión inclusive; y/o en consecuencia podemos denegarle el acceso a nuestros Servicios (o a parte de ellos).",
  "COMPLETE AGREEMENT": "ACUERDO COMPLETO",
  "Our failure to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.":
    "El hecho de que no ejerzamos o hagamos valer algún derecho o disposición de los presentes Términos del Servicio no constituirá una renuncia a dicho derecho o disposición.",
  "These Terms of Service and any policies or operating rules posted by us on this site or in respect to The Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).":
    "Estos Términos de servicio y cualquier política o regla de funcionamiento que hayamos publicado en este sitio o con respecto al Servicio constituye el acuerdo y el entendimiento completo entre usted y nosotros, y rigen su uso del Servicio, sustituyendo a cualquier acuerdo, comunicación o propuesta anterior o contemporánea, ya sea oral o escrita, entre usted y nosotros (incluyendo de manera enunciativa más no limitativa, las versiones anteriores de los Términos del servicio).",
  "Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.":
    "Cualquier ambigüedad en la interpretación de los presentes Términos del servicio no se interpretará en contra de la parte redactora.",
  "APPLICABLE LAW": "LEY APLICABLE",
  "These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws.":
    "Los presentes Términos del servicio y cualquier acuerdo por separado por el cual le proporcionemos Servicios se regirán e interpretarán de acuerdo con las leyes.",
  "CHANGES TO TERMS OF SERVICE": "CAMBIOS EN LOS TÉRMINOS DEL SERVICIO",
  "You can review the most current version of the Terms of Service at any time on this page.":
    "Puede revisar la versión más reciente de los Términos del servicio en cualquier momento en esta página.",
  "We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.":
    "Nos reservamos el derecho, a nuestra entera discreción, de actualizar, cambiar o sustituir cualquier parte de los presentes Términos del servicio mediante la publicación de actualizaciones y cambios en nuestro sitio web. Es su responsabilidad consultar nuestro sitio web periódicamente para ver los cambios. El uso que haga de nuestra página web o del Servicio o su acceso a cualquiera de estos de forma continua después de la publicación de cualquier cambio en los presentes Términos del servicio, constituye la aceptación de dichos cambios.",
  "CONTACT INFORMATION": "INFORMACIÓN DE CONTACTO",
  "Questions about the Terms of Service should be sent to ":
    "Las preguntas sobre los Términos del servicio se deben enviar a ",
  "Refund Policy": "Política de Reembolso",
  "refund policy": "política de reembolso",
  "Welcome to *": "!Bienvenido a la Política de Reembolso de ",
  "'s Refund Policy! Thank you for choosing to use our services.":
    "! Gracias por elegir utilizar nuestros servicios.",
  GENERALITIES: "GENERALIDADES",
  RETURNS: "DEVOLUCIONES",
  "Our policy lasts 30 days. If 30 days have passed since your purchase, unfortunately we cannot offer you a refund or exchange.":
    "Nuestra política dura 30 días. Si pasaron 30 días desde su compra, desafortunadamente no podemos ofrecerle un reembolso ni un cambio.",
  "To be eligible for a return, your item must be unused and in the same condition that you received it. It must also be in the original packaging.":
    "Para ser elegible para una devolución, su artículo debe estar sin usar y en la misma condición en que lo recibió. También debe estar en el embalaje original.",
  "Several types of products are exempt from return. Perishable products, such as food, flowers, newspapers or magazines cannot be returned. We also do not accept products that are intimate or sanitary, hazardous materials or flammable liquids or gases.":
    "Varios tipos de productos están exentos de devolución. Los productos perecederos, como alimentos, flores, periódicos o revistas no se pueden devolver. Tampoco aceptamos productos que sean íntimos o sanitarios, materiales peligrosos ni líquidos o gases inflamables.",
  "Additional items that cannot be returned:":
    "Elementos adicionales que no se pueden devolver:",
  "Gift Cards": "Tarjetas de regalo",
  "Downloadable software products": "Productos de software descargables",
  "Some health and personal care items":
    "Algunos artículos de salud y cuidado personal",
  "To complete your return, we require a receipt or proof of purchase.":
    "Para completar su devolución, necesitamos un recibo o comprobante de compra.",
  "Do not return your purchase to the manufacturer.":
    "No devuelva su compra al fabricante.",
  "There are certain situations where only partial refunds are granted:":
    "Hay ciertas situaciones en las que solo se otorgan reembolsos parciales:",
  "Book with obvious signs of use": "Libro con signos evidentes de uso",
  "CD, DVD, VHS tape, software, video game, cassette tape, or vinyl record that has been opened.":
    "CD, DVD, cinta VHS, software, videojuego, cinta de cassette o disco de vinilo que fue abierto.",
  "Any item not in its original condition, is damaged or missing parts for reasons not due to our error.":
    "Cualquier artículo que no se encuentre en su estado original, esté dañado o le falten piezas por razones que no se deban a nuestro error.",
  "Any item returned more than 30 days after delivery.":
    "Cualquier artículo devuelto más de 30 días después de la entrega.",
  Refunds: "Reembolsos",
  "Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. We will also notify you of the approval or rejection of your refund.":
    "Una vez recibida e inspeccionada su devolución, le enviaremos un correo electrónico para notificarle que recibimos el artículo que devolvió. También le notificaremos sobre la aprobación o el rechazo de su reembolso.",
  "If approved, your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within a certain amount of days.":
    "Si se aprueba, se procesará su reembolso y se aplicará un crédito automáticamente en su tarjeta de crédito o método de pago original, dentro de una cierta cantidad de días.",
  "Late or missing refunds": "Reembolsos tardíos o faltantes",
  "If you haven't received a refund yet, first check your bank account again.":
    "Si aún no recibió un reembolso, primero revise de nuevo su cuenta bancaria.",
  "Next, contact your credit card company. It may take some time before your refund is officially posted.":
    "Luego, comuníquese con la empresa de su tarjeta de crédito. Puede demorar algún tiempo antes de que su reembolso se vea reflejado de manera oficial.",
  "Next, contact your bank. There is often a processing time required before a refund can be posted.":
    "Después, contacte a su banco. A menudo es necesario un tiempo de procesamiento antes de poder ver reflejado un reembolso.",
  "If you have done all of the above and you still have not received your refund, you can contact us at ":
    "Si ya hizo todo lo anterior y aún no recibió su reembolso, puede contactarnos escribiendo a ",
  "Items on sale": "Artículos en oferta",
  "Only regular priced items may be refunded. Unfortunately, sale items cannot be refunded.":
    "Solo se pueden reembolsar los artículos de precio normal. Lamentablemente, no se pueden reembolsar los artículos en oferta.",
  Changes: "Cambios",
  "We only replace items if they are defective or damaged. If you need to exchange it for the same item, please email us at ":
    "Solo reemplazamos los artículos si están defectuosos o dañados.  Si necesita cambiarlo por el mismo artículo, envíenos un correo electrónico a ",
  Gifts: "Regalos",
  "If the item was marked as a gift when purchased and we shipped it directly to you, you'll receive a gift credit for the value of your return. Once we receive the returned item, a gift certificate will be mailed to you.":
    "Si el artículo estaba marcado como regalo cuando se compró y se lo enviamos directamente a usted, recibirá un crédito de regalo por el valor de su devolución. Una vez que recibamos el artículo devuelto, se le enviará un certificado de regalo por correo.",
  "If the item wasn't marked as a gift when purchased, or the gift giver chose to have the order shipped to themselves and then give it to you, we will send a refund to the gift giver and he or she will find out about your return.":
    "Si el artículo no estaba marcado como regalo cuando se compró, o si la persona que hizo el regalo decidió que le enviaran el pedido para luego dárselo a usted, le enviaremos un reembolso a la persona que hizo el regalo y se enterará de su devolución.",
  Shipment: "Envío",
  "To return your product, you can contact us by writing to ":
    "Para devolver su producto, puede contactarnos escribiendo a ",
  "Shipping costs for returning your item are your responsibility. Shipping costs are non-refundable. If you receive a refund, the cost of return shipping will be deducted from your refund.":
    "Los costos de envío para devolver su artículo corren por su cuenta. Los costos de envío no son reembolsables. Si recibe un reembolso, el costo de envío de la devolución se deducirá de su reembolso.",
  "Depending on where you live, the time it may take to receive your exchanged product may vary.":
    "Según dónde viva, el tiempo que tome recibir el cambio de su producto puede variar.",
  "It is recommended to use a trackable shipping service or purchase shipping insurance. We do not guarantee that we will receive your returned item.":
    "Se recomienda usar un servicio de envío con seguimiento o comprar un seguro de envío. No garantizamos que recibiremos la devolución de su artículo.",
  "Create your Online Empire": "Crea tu Imperio Online",
  "E-Commerce Experts at your Service": "Expertos en E-Commerce a tu Servicio",
  "From strategy to launch, we are with you every step":
    "Desde la estrategia hasta el lanzamiento, estamos contigo en cada paso",
  "We use Artificial Intelligence to obtain optimal results and lead in the development of our products":
    "Usamos Inteligencia Artificial para obtener resultados optimos y liderar en el desarrollo de nuestros productos",
  "Transform your vision and adapt your business to new trends; success is waiting for you":
    "Transformá tu visión y adaptá tu negocio a las nuevas tendencias; el éxito te está esperando",
  "Expand your reach and strengthen your business with our E-Commerce, created to achieve your goals":
    "Amplía tu alcance y fortalece tu negocio con nuestro E-Commerce, creado para lograr tus objetivos",
  "When you resize the screen, the image will adapt and be cropped to maintain the aesthetics of the page.":
    "Al cambiar el tamaño de la pantalla, la imágen  se adaptará y se recortará para mantener la estética de la página.",
  "For the best browsing experience in your store, we recommend adjusting your images' height, width, and format whenever possible. Larger images will take longer to load and lighter image formats will take less time.":
    "Para obtener la mejor experiencia de navegación en su tienda le recomendamos que ajuste las imágenes en alto, ancho y formato siempre y cuando sea posible. Ya que las imágenes más amplias tardaran más en cargar y los formatos de imágen más livianos tardaran menos.",
  Cut: "Cortar",
  "Please wait before retrying.":
    "Por favor espere antes de volver a intentarlo.",
  "There was an error.": "Ha ocurrido un error.",
  "Reset Password Notification": "Restablecimiento de contraseña",
  "We have emailed your password reset link.":
    "Hemos enviado un enlace para restablecer su contraseña a su correo electrónico.",
  "Forgot Password?": "¿Has olvidado tu contraseña?",
  "Remember your password?": "¿Recuerdas tu contraseña?",
  "Reset password": "Restablecer contraseña",
  "Reset account password": "Restablecer contraseña de la cuenta",
  "Enter a new password below to change your password":
    "Ingrese una nueva contraseña a continuación para cambiar su contraseña",
  "Change password": "Cambiar contraseña",
  "The password has been changed successfully.":
    "La contraseña ha sido cambiada exitosamente.",
  "New Address": "Nueva dirección",
  "Address Line One": "Primer Línea de dirección",
  "Address Line Two": "Segunda Línea de dirección",
  City: "Ciudad",
  "Postal Code": "Código Postal",
  State: "Estado/Provincia",
  Country: "País",
  Telephone: "Teléfono",
  Mobile: "Celular",
  "Store link": "Enlace de tienda",
  "Store link successfully copied!":
    "¡El enlace de la tienda se copió correctamente!",
  Description: "Descripción",
  Price: "Precio",
  "Your cart is empty, start adding some products!":
    "Tu carrito está vacío, ¡comienza agregando algunos productos!",
  "Empty cart": "Vaciar carrito",
  Buy: "Comprar",
  "Keep buying": "Seguir comprando",
  Show: "Ver",
  "Questions and answers": "Preguntas y respuestas",
  "Questions asked": "Preguntas formuladas",
  "Deal of the day": "Oferta del día",
  "Add Address": "Agregar Dirección",
  "Select an address": "Seleccione una dirección",
  "The seller will contact you to coordinate payment.":
    "El vendedor se pondrá en contacto contigo para coordinar el pago.",
  "The seller will contact you to coordinate shipping and payment.":
    "El vendedor se pondrá en contacto contigo para coordinar el envío y el pago.",
  "Shipping Options": "Opciones de envío",
  "To create questions you must log in first.":
    "Para crear preguntas debes iniciar sesión primero.",
  "Go to Login": "Ir a Iniciar sesión",
  "Question has been sent.": "La pregunta ha sido enviada.",
  Question: "Pregunta",
  "Submit Question": "Enviar pregunta",
  "There is no questions.": "No hay preguntas.",
  "Your order has been generated successfully!":
    "¡Su pedido ha sido generado con éxito!",
  Hello: "Hola",
  "Hello!": "¡Hola!",
  "Thank you for your purchase!": "¡Gracias por tu compra!",
  "Your order is being processed.": "Tu pedido está siendo procesado.",
  "We will contact you very soon for any additional details.":
    "Nos pondremos en contacto contigo muy pronto para cualquier detalle adicional.",
  "We are excited to serve you and make sure your experience is great!":
    "¡Estamos emocionados de atenderte y asegurarnos de que tu experiencia sea excelente!",
  "If you have any questions or need assistance, please do not hesitate to contact us.":
    "Si tienes alguna pregunta o necesitas asistencia, no dudes en contactarnos.",
  "This is our email": "Este es nuestro correo electrónico",
  "See Purchases": "Ver Compras",
  "New Order in Your Store": "Nueva orden en tu tienda.",
  "You have a new order in your store. The details of the order are as follows":
    "Tienes un nuevo pedido en tu tienda. Los detalles del pedido son los siguientes:",
  "Order details": "Detalles de la orden",
  "User Name": "Nombre del Usuario",
  "User Email": "Email del Usuario",
  "View Order": "Ver Orden",
  "You have a question in your store": "Tienes una pregunta en tu tienda.",
  "You have received a new question in your store. The question details are as follows":
    "Has recibido una nueva pregunta en tu tienda. Los detalles de la pregunta son los siguientes:",
  Product: "Producto",
  "See Question": "Ver Pregunta",
  Empty: "Vacío",
  Action: "Acción",
  Search: "Buscar",
  "Your Order Confirmation": "Confirmación de tu pedido",
  "New Order Received": "Nueva orden recibida",
  "New question in your store": "Nueva pregunta en tu tienda",
  "Cannot upload file.": "No se puede subir el archivo.",
  "Add Image": "Agregar Imágen",
  "An error has occurred.": "Ha ocurrido un error.",
  "Deleting ...": "Eliminando ...",
  "Category Images": "Imágenes de Categoría",
  "Create Category": "Crear Categoría",
  Store: "Guardar",
  "Edit Category ": "Editar Categoría",
  Update: "Actualizar",
  "Category Title": "Título de Categoría",
  "Do you want to delete the category and all its products?":
    "¿Quieres eliminar la categoría y todos sus productos?",
  Yes: "Sí",
  "Category deleted successfully.": "Categoría eliminada con éxito.",
  Delete: "Eliminar",
  "Address deleted successfully.": "Dirección eliminada con éxito.",
  Addresses: "Direcciónes",
  Add: "Agregar",
  "Address added successfully.": "Dirección añadida con éxito.",
  Create: "Crear",
  Carousel: "Carrusel",
  "Create Item": "Crear Item",
  "Edit Item": "Editar Item",
  Edit: "Editar",
  "Contact & Networks": "Contacto y Redes",
  "Create  - Contact & Networks": "Crear  - Contacto y Redes",
  "Edit - Contact & Networks": "Editar - Contacto y Redes",
  " theme updated successfully.": " actualizado con éxito.",
  "**theme": "tema ",
  Select: "Seleccionar",
  "Edit - About Section": "Editar - Sección Acerca de",
  "About Section updated successfully.":
    "Sección Acerca de actualizada con éxito.",
  "Edit - Brand & Styles": "Editar - Marca y Estilos",
  "Brand & Styles updated successfully.":
    "Marca y Estilos actualizados con éxito.",
  "Create Order": "Crear Orden",
  "Edit Order ": "Editar Orden ",
  "Address Info": "Iformación de Dirección",
  "Order Status": "Estado de Orden",
  Orders: "Ordenes",
  "Add Document": "Agregar Documento",
  "Payment Documents": "Documentos de Pago",
  "Create Payment": "Crear Pago",
  "Edit Payment ": "Editar Pago ",
  "Payment for ": "Pago para ",
  "User Role": "Rol de Usuario",
  "User Rating": "Calificación del usuario",
  "User Status": "Estado de Usuario",
  ACTIVE: "ACTIVO",
  DELETED: "DELETED",
  "Payment Status": "Estado de Pago",
  "Shipment Status": "Estado de Envío",
  PAYED: "PAGADO",
  "NEED PAY": "DEBE PAGAR",
  "Subscribed Days": "Días Suscritos",
  "Subscribed Months": "Meses Suscritos",
  Payments: "Pagos",
  "Product Documents": "Documentos de Producto",
  "Product Images": "Imágenes de Producto",
  "Create Product": "Crear Producto",
  "Edit Product ": "Editar Producto ",
  "Product code allready exists.": "El código del producto ya existe.",
  "Product ": "Producto ",
  "File imported successfully.": "El archivo ha sido importado correctamente.",
  "Cannot import file.": "No se ha podido importar el archivo.",
  Export: "Exportar",
  Import: "Importar",
  "Create Question": "Crear Pregunta",
  "Answer Question ": "Responder Pregunta",
  question: "pregunta",
  "Question ": "Pregunta ",
  Questions: "Preguntas",
  "User Complains": "Quejas de usuario",
  "Mark as Read": "Marcar como Leído",
  "Mark as Unread": "Marcar como No Leído",
  "Create User": "Crear Usuario",
  "Edit User ": "Editar Usuario ",
  Role: "Rol",
  Rating: "Calificación",
  Status: "Estado",
  "Are you sure you want to delete the user ":
    "¿Estás seguro que quieres eliminar el usuario ",
  "Yes, delete it!": "Sí, ¡Elimínalo!",
  "User deleted successfully.": "El usuario ha sido eliminado correctamente.",
  "Are you sure you want to undelete the user ":
    "¿Estás seguro que quieres restaurar el usuario ",
  "Yes, undelete it!": "Sí, ¡Restáuralo!",
  "User undeleted successfully.":
    "El usuario ha sido restaurado satisfactoriamente.",
  "Create Complaint": "Crear Queja",
  "Edit Complaint ": "Editar Queja ",
  "The complaint has been stored.": "La queja se a guardado correctamente.",
  "User Profile": "Perfíl de Usuario",
  Verified: "Verificado",
  "Not Verified": "No Verificado",
  Verify: "Verificar",
  Complain: "Quejarse",
  "Successfully verified.": "Verificado satisfactoriamente.",
  Verification: "Verificación",
  "Upload your national identity document.":
    "Carga tu documento nacional de identidad.",
  "To add products to the shopping cart you must log in first.":
    "Para añadir productos al carrito de compras, debes iniciar sesión primero.",
  "Add to cart": "Agregar al carrito",
  Purchases: "Compras",
  Quantity: "Cantidad",
  "Successfully rated.": "Calificado correctamente.",
  "You are not verified, verify yourself in your profile to be able to rate the seller.":
    "No estás verificado. Verifícate en tu perfil para poder calificar al vendedor.",
  "Purchase Details": "Detalles de Compra",
  "Rate seller": "Calificar al vendedor",
  "Write a review here": "Escribe una reseña aquí",
  "Already rated": "Ya calificado",
  "My Purchases": "Mis Compras",
  "Order created successfully.": "Orden creada con éxito.",
  "Your shopping cart": "Tu carrito de compras",
  "Sorry, this page isn't available.":
    "Lo siento, esta página no está disponible.",
  "The site needs seller verification.":
    "El sitio necesita verificación del vendedor.",
  "Hello! You are receiving this email because we received a password reset request for your account.":
    "¡Hola! Estás recibiendo este correo electrónico porque recibimos una solicitud de restablecimiento de contraseña para tu cuenta.",
  "RESET PASSWORD": "RESTABLECER CONTRASEÑA",
  "This password reset link will expire in 60 minutes. If you did not request a password reset, no further action is required. Regards, WebComprehensive.":
    "Este enlace para restablecer la contraseña expirará en 60 minutos. Si no solicitaste un restablecimiento de contraseña, no se requiere ninguna acción adicional. Saludos, WebComprehensive.",
  "If you're having trouble clicking the “Reset Password“ button, copy and paste the URL below into your web browser:":
    "Si tienes problemas para hacer clic en el botón “Restablecer contraseña“, copia y pega la URL a continuación en tu navegador web:",
  "Home delivery": "Entrega a domicilio",
  "Agree later": "Acordar más tarde",
  "Edit - SEO Configuration": "Editar - Configuración de SEO",
  "SEO Configuration updated successfully.":
    "Configuratión SEO actualizada con éxito.",
  "Without Stock!": "¡Sin Stock!",
  "Warning!": "¡Aviso!",
  "Do you want to delete the product: ": "¿Quieres eliminar el producto: ",
  " with code: ": " con código: ",
  "Share this Product": "Comparte este Producto",
  "Order Options": "Opciones de Orden",
  "Payment Options": "Opciones de Pago",
  "User is Client.": "El Usuario es Cliente.",
  Configuration: "Configuración",
  "SEO Configuration": "Configuración de SEO",
  "About Section": "Sección Acerca-de",
  "Brand & Styles": "Marca y Estilos",
  Themes: "Temas",
  Style: "Estilo",
  Address: "Dirección",
  Code: "Código",
  Title: "Título",
  Category: "Categoría",
  Featured: "Remarcado",
  Published: "Publicado",
  PreviousPrice: "PrecioAnterior",
  Updated: "Actualizado",
  Images: "Imágenes",
  Documents: "Documentos",
  Categories: "Categorias",
  Answer: "Respuesta",
  Image: "Imágen",
  "Address line1": "Línea 1 de dirección",
  "Address line2": "Línea 2 de dirección",
  "Provider id": "Proveedor",
  "Access token": "Token de acceso",
  Text: "Texto",
  Emptyimage: "Borrar Imágen",
  Emptylogo: "Borrar Logo",
  Showbrand: "Mostrar Marca",
  Topbarcolor: "Color Barra Superior",
  Topbartextcolor: "Color Texto Barra Superior",
  Footertextcolor: "Color Texto Pie Página",
  Textcolor: "Color Texto",
  BoxShadowCards: "Sombra en Tarjetas",
  Showbuttoncolor: "Color Botón Ver",
  Paginationbuttoncolor: "Color Botón Paginación",
  Footercolor: "Color Pie Página",
  Backgroundcolor: "Color Fondo",
  Cardcolor: "Color en Tarjetas",
  ColorBoxShadowCards: "Color de Sombra",
  Paginationcolor: "Color de Paginación",
  Users: "Usuarios",
  Deleted: "Eliminado",
  Undelete: "Restaurar",
  Complains: "Quejas",
  UserName: "NombreUsuario",
  UserEmail: "EmailUsuario",
  SubscribedDays: "DiasSuscripto",
  SubscribedMonths: "MesesSuscripto",
  Created: "Creado",
  "Order payed successfully.": "Orden pagada correctamente.",
  "Are you sure you want to delete the order ":
    "¿Estás seguro que quieres eliminar la orden ",
  "Are you sure you want to delete the Payment Method ":
    "¿Estás seguro que quieres eliminar el Método de Pago ",
  "Order payment is in process.": "El pago de la orden esta en proceso.",
  "The product has not stock to add.":
    "El producto no tiene stock para agregar.",
  "Are you sure you want to delete the Shipment Method ":
    "¿Estás seguro que quieres eliminar el Método de Envío ",
  "Shipment Methods": "Métodos de Envío",
  "Payment Methods": "Métodos de Pago",
  "Error getting shipment quote.": "Error al obtener el presupuesto de envío.",
  "Most Relevant": "Más Relevante",
  "Lower Price": "Menor Precio",
  "Higher Price": "Mayor Precio",
  "Order By:": "Ordenar Por:",
  Tracking: "Seguimiento",
  pending: "pendiente",
  new: "nuevo",
  paid: "pagado",
  "Previous price": "Precio previo",
  "Price unit": "Unidad de precio",
  Weight: "Peso",
  "Weight unit": "Unidad de peso",
  Width: "Ancho",
  Height: "Alto",
  Large: "Largo",
  "Dimension unit": "Unidad de dimensión",
  "Package units": "Unidades por paquete",
  "Right text": "Texto lado derecho",
  done: "terminado",
  "Online Store": "Tienda Online",
  "Everything you are looking for can be found here!":
    "¡Todo lo que buscás podés encontrarlo acá!",
  "Your first custom store by WebComprehensive":
    "Tu primera tienda personalizada de WebComprehensive",
  Change: "Cambiar",
  "Change Name & Email": "Cambiar Nombre y Email",
  "The name and email has been changed successfully.":
    "El nombre y el correo electrónico se han cambiado correctamente.",
  "Change Name": "Cambiar Nombre",
  "The name has been changed successfully.":
    "El nombre se ha cambiado correctamente.",
  "Enter a new name below to change":
    "Ingrese un nuevo nombre a continuación para cambiar",
  "The name has already been taken.": "El nombre ya existe.",
  "Please upload another file.": "Por favor, sube otro archivo.",
  "By clicking this button all products will be displayed.":
    "Al hacer click en este botón se mostraran todos los productos.",
  "In this text box you can enter the name of the product you want.":
    "En este cuadro de texto puedes ingresar el nombre del producto que deseas.",
  Back: "Atras",
  Close: "Cerrar",
  Last: "Ultimo",
  Next: "Siguiente",
  "Next (Step {step} of {steps})": "Siguiente ({step} de {steps})",
  "Open the dialog": "Abrir diálogo",
  Skip: "Saltar",
  "From here you can see the products you have chosen to buy.":
    "Desde aquí puedes ver los productos que has elegido comprar.",
  "Here you can see the product categories and select a particular one.":
    "Aquí podras ver categorias de productos y seleccionar una en particular.",
  "Here you will find information about us.":
    "Aquí encontraras información acerca de nosotros.",
  "To purchase you need to log in here.":
    "Para poder comprar necesitas iniciar sesión aquí.",
  "Start tour": "Iniciar recorrido",
  "Here you can see your profile, your purchases, your configuration and log out.":
    "Aquí podras ver tu perfil, tus compras, tu configuración y cerrar sesión.",
  "Here you can see the product information and choose to buy.":
    "Aquí puedes ver la información del producto y elegir comprar.",
  "Here you can select how you want to order the products.":
    "Aquí puedes seleccionar como deseas ordenar los productos.",
  "Here you can browse through the different product pages.":
    "Aquí puedes navegar por las diferentes paginas de productos.",
  "Here you can ask questions about the product and its details.":
    "Aquí puedes hacer preguntas acerca del producto y sus detalles.",
  "Here the questions that are asked will be answered.":
    "Aquí se responderan las preguntas que se hagan.",
  "If you enter a product with stock you will be able to see more options.":
    "Si ingresas a un producto con stock podras ver mas opciones.",
  "With these buttons you can choose the quantity you want to buy.":
    "Con estos botones podras elegir la cantidad que deseas comprar.",
  "With this button you can add the product with the chosen quantity to the shopping cart to proceed with the purchase.":
    "Con este botón, podras agregar el producto al carrito de compras, con la cantidad elegida, para avanzar con la compra.",
  "With this button you can share the product on your social networks.":
    "Con este botón puedes compartir el producto en tus redes sociales.",
  "With this button, you can remove a product from your shopping cart.":
    "Con este bot{on, puedes quitar un producto de tu carrito de compras.",
  "By clicking on this button you can select the payment and shipping method to complete the purchase.":
    "Al hacer click en este botón podras seleccionar e método de pago y envío para finalizar la compra.",
  "Here you can choose if you want to arrange shipping after making the purchase (contacting the seller) or if you want to choose an address to include shipping.":
    "Aquí puedes elegir si quieres acordar el envío luego de realizar la compra (contactándote con el vendedor) o si quieres elegir una dirección para incluir el envío.",
  "Here you can choose how to pay (if you choose Manual the seller will contact you to make the payment).":
    "Aquí puedes elegir como pagar (si eliges Manual, el vendedor se contactará contigo para realizar el pago).",
  "Here you can see the shopping cart before completing it.":
    "Aquí podrás ver el carrito de compras antes de completarla.",
  "With this button you will advance with the purchase process.":
    "Con este botón avanzarás con el proceso de compra.",
  "Here you will find a menu with products (categories), our About Us section and the option to log in to make inquiries and purchases.":
    "Aquí encontrarás un menú con productos (categorías), nuestra sección Nosotros y la opción de iniciar sesión para poder realizar consultas y compras.",
  Visitors: "Visitantes",
};
