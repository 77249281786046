import { Card, Row } from "react-bootstrap";
import { ProductModal } from "./ProductModal";
import { ProductPrice } from "./ProductPrice";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import ShareButtons from "components/ShareButtons";
import { ProductFeatured } from "./ProductFeatured";
import { Link, useLocation } from "react-router-dom";
import { ProductRightText } from "./ProductRightText";
import { ProductStockText } from "./ProductStockText";
import { isLightColor } from "helpers/functionsHelper";
import { Image } from "components/Image";

export const ProductItem = ({ product, customer, customerPath, target }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [isHovered, setIsHovered] = useState(false);
  const [showImages, setShowImages] = useState(false);
  const [isButtonHovered, setIsButtonHovered] = useState(false);
  const hostUrl = window.location.href.replace(location.pathname, "");
  const [image, setImage] = useState("/images/no-image-available.webp");

  const handleShow = () => {
    setShowImages(true);
  };

  const handleHide = () => {
    setShowImages(false);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleButtonMouseEnter = () => {
    setIsButtonHovered(true);
  };

  const handleButtonMouseLeave = () => {
    setIsButtonHovered(false);
  };

  const boxShadowColor =
    customer &&
    customer.data &&
    customer.data.style &&
    customer.data.style.colorBoxShadowCards
      ? customer.data.style.colorBoxShadowCards
      : "#000000";

  const textColor =
    customer && customer.data && customer.data.style
      ? customer.data.style.textcolor
      : "black";
  const isTextColorLight = isLightColor(textColor);

  const style = {
    cards: {
      width: "24rem",
      overflow: "visible",
      backgroundColor:
        customer && customer.data && customer.data.style
          ? customer.data.style.cardcolor
          : "white",
      boxShadow:
        customer &&
        customer.data &&
        customer.data.style &&
        customer.data.style.boxShadowCards === "on"
          ? `-17px 15px 18px 7px ${boxShadowColor}`
          : "none",
    },
    image: {
      maxWidth: "100%",
      maxHeight: "100%",
      objectFit: "contain",
      transition: "transform 0.3s ease",
      transform: isHovered ? "scale(1.6)" : "scale(1)",
      cursor: "pointer",
    },
    cardBody: {
      backgroundColor: "white",
      padding: "15px",
      borderTop: "1px solid #ddd",
    },
    title: {
      color: textColor,
      textShadow: isTextColorLight ? "1px 1px 2px #000000" : "none",
    },
    text: {
      color: textColor,
      textShadow: isTextColorLight ? "1px 1px 2px #000000" : "none",
    },
    button: {
      color: "white",
      backgroundColor: isButtonHovered
        ? "green"
        : customer && customer.data && customer.data.style
        ? customer.data.style.showbuttoncolor
        : "blue",
      borderColor: isButtonHovered
        ? "green"
        : customer && customer.data && customer.data.style
        ? customer.data.style.showbuttoncolor
        : "blue",
      textShadow: isTextColorLight ? "1px 1px 2px #000000" : "none",
    },
  };

  useEffect(() => {
    if (product.images && product.images.length > 0 && product.images[0]) {
      setImage(product.images[0].url);
    }
  }, [product.images]);

  return (
    <>
      <Card style={style.cards} className="my-3 mx-auto">
        <div
          style={{
            width: "100%",
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "visible",
          }}
          className="mt-3 overflow-visible"
        >
          <ProductFeatured product={product} top={0} />
          <ProductRightText product={product} top={0} />
          <ProductStockText product={product} top={185} />
          <ShareButtons
            position="absolute"
            top="25%"
            left={0}
            cardTitle={t("Share this Product")}
            title={product.title}
            url={`${hostUrl}/${customerPath}/products/${product.id}`}
          />
          <Image
            className="card-img-top bg-white"
            style={style.image}
            src={image}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleShow}
            loading="lazy"
          />
        </div>
        <Card.Body style={style.cardBody}>
          <Row className="h-100 align-items-end">
            <Card.Title style={style.title}>{product.title}</Card.Title>
            <div style={style.text}>
              <ProductPrice product={product} />
            </div>
            <Link
              style={style.button}
              className="btn fw-bold w-25 mx-auto fs-5 eighth-cataloge-step"
              to={`/${customerPath}/products/${product.id}`}
              onMouseEnter={handleButtonMouseEnter}
              onMouseLeave={handleButtonMouseLeave}
              target={target}
            >
              {t("Show")}
            </Link>
          </Row>
        </Card.Body>
      </Card>
      <ProductModal
        product={product}
        customer={customer}
        customerPath={customerPath}
        target={target}
        handleHide={handleHide}
        show={showImages}
      />
    </>
  );
};
