import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Header from "components/home/Header";
import ViewShop from "components/home/ViewShop";
import FooterShop from "components/home/FooterShop";
import CarouselHome from "components/home/CarouselHome";
import DescriptionShop from "components/home/DescriptionShop";
import FadeInSection from "components/fadeInSection/FadeInSection";
import { useTranslation } from "react-i18next";

const HomeView = () => {
  const [activeSection, setActiveSection] = useState("Home");
  const { t } = useTranslation();

  // https://schema.org/WebSite
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "OnlineStore",
    "@id": "Store by WebComprehensive",
    url: "https://store.webcomprehensive.com/",
    name: "Store - by WebComprehensive",
    alternateName: "Store - WC",
    description: t("Your first custom store by WebComprehensive"),
    image: "https://webcomprehensive.com/logo.png",
    logo: "https://webcomprehensive.com/logo.png",
    parentOrganization: {
      "@type": "OnlineBusiness",
      name: "WebComprehensive",
      url: "https://webcomprehensive.com/",
    },
    foundingDate: "2024-08-27",
  };

  const homeRef = useRef(null);
  const informationRef = useRef(null);
  const contactRef = useRef(null);

  const sections = useMemo(
    () => [
      { name: "Home", ref: homeRef },
      { name: "Information", ref: informationRef },
      { name: "Contact", ref: contactRef },
    ],
    [homeRef, informationRef, contactRef]
  );

  const handleClick = (section) => {
    if (!section.ref.current) {
      return;
    }
    if (section.name === "Home") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return;
    }
    if (section.name === "Contact") {
      window.scrollTo({
        top: section.ref.current.offsetTop,
        behavior: "smooth",
      });
      return;
    }
    window.scrollTo({
      top: section.ref.current.offsetTop - 100,
      behavior: "smooth",
    });
  };

  const getDimensions = (ele) => {
    const { height } = ele.getBoundingClientRect();
    const offsetTop = ele.offsetTop;
    const offsetBottom = offsetTop + height;

    return { height, offsetTop, offsetBottom };
  };

  const handleScroll = useCallback(() => {
    for (const section of sections) {
      if (!section.ref.current) continue;
      const { height: sectionHeight } = getDimensions(section.ref.current);
      const scrollPosition = window.scrollY + sectionHeight;
      const element = section.ref.current;
      if (element) {
        const { offsetBottom, offsetTop } = getDimensions(element);
        const selected =
          scrollPosition > offsetTop - 800 &&
          scrollPosition < offsetBottom + 50;
        if (selected) {
          if (section.name !== activeSection) {
            setActiveSection(section.name);
          }
          break;
        }
      }
    }
  }, [activeSection, sections]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, [activeSection, handleScroll]);

  return (
    <div className="mw-100" style={{ fontFamily: "Montserrat" }}>
      <Header
        home={{ name: "Home", ref: homeRef }}
        handleClick={handleClick}
        sections={sections}
        activeSection={activeSection}
      />
      <div className="my-5 pt-3"></div>
      <FadeInSection>
        <ViewShop reference={homeRef} />
      </FadeInSection>
      <CarouselHome reference={homeRef} />
      <DescriptionShop reference={informationRef} />
      <FooterShop reference={contactRef} />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(structuredData),
        }}
      />
    </div>
  );
};

export default HomeView;
