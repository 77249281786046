import { Button, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function OrderSelect({ handleOrder, handleStartTour }) {
  const { t } = useTranslation();
  return (
    <Row className="mb-2 mt-4 mx-1 align-items-end">
      <Col className="text-start px-0 px-md-3">
        <Button
          className="mt-auto"
          variant="secondary"
          onClick={handleStartTour}
        >
          {t("Start tour")}
        </Button>
      </Col>
      <Col className="text-end px-0 px-md-3">
        <strong>{t("Order By:")} </strong>
        <Form.Select
          className="d-inline seventh-cataloge-step"
          style={{ width: 200 }}
          aria-label="Default select example"
          onChange={handleOrder}
        >
          <option value="featured">{t("Most Relevant")}</option>
          <option value="lowerPrice">{t("Lower Price")}</option>
          <option value="higherPrice">{t("Higher Price")}</option>
        </Form.Select>
      </Col>
    </Row>
  );
}
