import { useState } from "react";
import { Modal } from "react-bootstrap";
import ImageCropper from "./ImageCropper";
import ImageUploader from "./ImageUploader";
import { UpscaledImage } from "components/UpscaledImage";
import { getFileFromUrl } from "helpers/functionsHelper";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const CropInput = ({ id, name, className, onChange, aspect, value }) => {
  const [imageUrl, setImageUrl] = useState(false);
  const [selected, setSelected] = useState(value);
  const closeTime = process.env.REACT_APP_CLOSE_TIME
    ? process.env.REACT_APP_CLOSE_TIME
    : 1500;
  const { t } = useTranslation();

  const selectedImageCallback = (url) => {
    setImageUrl(url);
  };

  const handleChange = (event) => {
    setSelected(false);
  };

  const handleCut = (croppedImage) => {
    setSelected(croppedImage.url);
    const imageFile = new File([croppedImage.file], `img-${Date.now()}.png`, {
      type: "image/png",
    });
    const event = {
      target: {
        name: name,
        value: imageFile,
        files: {
          0: imageFile,
        },
      },
    };
    if (onChange) {
      onChange(event);
    }
  };

  const handleUpscaleResult = (url) => {
    const imageFile = getFileFromUrl(url);
    const event = {
      target: {
        name: name,
        value: imageFile,
        files: {
          0: imageFile,
        },
      },
    };
    if (onChange) {
      onChange(event);
    }
  };

  const handleError = (error) => {
    console.log(error);
    Swal.fire({
      icon: "error",
      title: t("Please upload another file."),
      showConfirmButton: false,
      timer: closeTime,
    });
  };

  return (
    <>
      <ImageUploader
        id={id}
        name={name}
        selectedImageCallback={selectedImageCallback}
        onChange={handleChange}
      />
      <UpscaledImage
        src={selected}
        showEnhance={imageUrl ? true : false}
        resultCallback={handleUpscaleResult}
        resize={true}
        scale={2}
        // progressCallback={(progress) =>
        //   console.log("Progress:" + progress + "%")
        // }
        errorCallback={handleError}
      />
      {imageUrl && (
        <Modal show={imageUrl && !selected} centered={true}>
          <Modal.Body
            className={"bg- text-white text-center"}
            style={{ height: 500 }}
          >
            <ImageCropper
              aspect={aspect}
              imageUrl={imageUrl}
              onChange={handleCut}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default CropInput;
