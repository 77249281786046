import { useTranslation } from "react-i18next";

export const ProductStockText = ({ product, top }) => {
  const { t } = useTranslation();
  return (
    <>
      {!product.stock && (
        <div
          className="m-1 z-2 px-3 py-2 bg-danger rounded-1 fw-bold third-product-step"
          style={{
            position: "absolute",
            top: top,
            right: 0,
            textShadow: "0 0 10px white, 0 0 15px white , 0 0 20px white",
          }}
        >
          {t("Without Stock!")}
        </div>
      )}
    </>
  );
};
