import { useState } from "react";
import ImageUploader from "./ImageUploader";
import { UpscaledImage } from "components/UpscaledImage";

const ImageInput = ({
  id,
  name,
  className,
  onChange,
  value,
  showEnhance = true,
}) => {
  const [imageUrl, setImageUrl] = useState(value);

  const selectedImageCallback = (url) => {
    setImageUrl(url);
  };

  const handleChange = (event) => {
    if (onChange) {
      onChange(event);
    }
  };

  const handleUpscaleResult = (url) => {
    let event = {
      target: {
        name: name,
        value: url,
      },
    };
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <>
      <ImageUploader
        id={id}
        name={name}
        selectedImageCallback={selectedImageCallback}
        onChange={handleChange}
      />
      <UpscaledImage
        src={imageUrl}
        showEnhance={imageUrl !== value ? showEnhance : false}
        resultCallback={handleUpscaleResult}
        resize={true}
        scale={2}
        // progressCallback={(progress) =>
        //   console.log("Progress:" + progress + "%")
        // }
      />
    </>
  );
};

export default ImageInput;
