import Swal from "sweetalert2";
import { useMemo, useState } from "react";
import React, { useEffect } from "react";
import { useForm } from "../hooks/useForm";
import { useTranslation } from "react-i18next";
import { UserData } from "../helpers/ClientHelper";
import { apiPasswordAuth } from "../helpers/helper";
import { Link, useNavigate } from "react-router-dom";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LoginView = ({ customerPath }) => {
  const { t } = useTranslation();
  const { data: user, refetch } = UserData();
  const closeTime = process.env.REACT_APP_CLOSE_TIME
    ? process.env.REACT_APP_CLOSE_TIME
    : 1500;
  const navigate = useNavigate();
  const initialForm = {
    username: "",
    password: "",
  };
  const { formState, username, password, onInputChange } = useForm(initialForm);
  const defaultButton = useMemo(
    () => <Button as="input" type="submit" value={t("Login")} />,
    [t]
  );
  const [currentButton, setButton] = useState(defaultButton);
  const [title, setTitle] = useState(process.env.REACT_APP_NAME);

  useEffect(() => {
    if (customerPath) {
      setTitle(customerPath.charAt(0).toUpperCase() + customerPath.slice(1));
    }
    if (user && user.data) {
      if (user.data.role_name !== "client") {
        navigate("/admin");
      } else if (customerPath) {
        navigate("/" + customerPath);
      } else {
        Swal.fire({
          icon: "error",
          title: t("User is Client."),
          showConfirmButton: false,
          timer: closeTime,
          timerProgressBar: true,
        });
        setButton(defaultButton);
      }
    }
  }, [user, customerPath, navigate, closeTime, defaultButton, t]);

  const onSubmit = (event) => {
    event.preventDefault();
    var grant_type = "password";
    var data = {
      password: formState.password,
      username: formState.username,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      client_id: process.env.REACT_APP_CLIENT_ID,
      grant_type: grant_type,
    };
    setButton(<LoadingSpinner />);
    apiPasswordAuth(data)
      .then(() => {
        refetch();
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: t("Invalid password or email."),
          showConfirmButton: false,
          timer: closeTime,
          timerProgressBar: true,
        });
        setButton(defaultButton);
      });
  };
  return (
    <div className="d-flex align-items-center min-vh-100 bg-primary">
      <Container>
        <Row className="justify-content-center">
          <Col md="6">
            <div className="card">
              <div className="card-body m-3">
                <Row>
                  <Col xs={1}>
                    <Link
                      to={customerPath ? "/" + customerPath : "/"}
                      className="link-secondary h4"
                    >
                      <FontAwesomeIcon
                        icon={faHome}
                        className="mt-3 ms-3 me-2"
                      />
                    </Link>
                  </Col>
                  <Col>
                    <h4 className="text-center mx-3 mt-3">{t("Welcome to")}</h4>
                  </Col>
                  <Col xs={1}></Col>
                </Row>
                <Row>
                  <Col>
                    <h4 className="text-center mb-md-4 mb-2">{title}!</h4>
                  </Col>
                </Row>
                <form onSubmit={onSubmit}>
                  <div className="m-md-3 mb-md-4 mb-2">
                    <input
                      type="email"
                      className="form-control"
                      name="username"
                      placeholder={t("Email")}
                      value={username}
                      onChange={onInputChange}
                      required
                    />
                  </div>
                  <div className="m-md-3 mb-md-4 mb-2">
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      placeholder={t("Password")}
                      value={password}
                      onChange={onInputChange}
                      required
                    />
                  </div>
                  <div className="text-center mx-3">{currentButton}</div>
                </form>
                <p className="small fw-bold mt-0 pt-1 mb-0 ms-md-3">
                  <Link
                    to={(customerPath ? "/" + customerPath : "") + "/forgot"}
                    className="link-danger"
                  >
                    {t("Forgot Password?")}
                  </Link>
                  <br />
                  {t("Don't have an account?")}{" "}
                  <Link
                    to={(customerPath ? "/" + customerPath : "") + "/register"}
                    className="link-danger"
                  >
                    {t("Register")}
                  </Link>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default LoginView;
