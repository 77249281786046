import { useState } from "react";
import { Image } from "components/Image";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import ShareButtons from "components/ShareButtons";
import { Button, ButtonGroup } from "react-bootstrap";
import { LoadingSpinner } from "components/LoadingSpinner";
import { ProductPrice } from "components/client/ProductPrice";
import { ProductModal } from "components/client/ProductModal";
import { handleRemoveFromCart, handleUpdateCartQty } from "helpers/CartHelper";

const CartItem = ({ item, refetch, customer, customerPath }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [deleteBtn, setDeleteBtn] = useState("Delete");
  const [showImages, setShowImages] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const hostUrl = window.location.href.replace(location.pathname, "");

  const closeTime = process.env.REACT_APP_CLOSE_TIME
    ? process.env.REACT_APP_CLOSE_TIME
    : 1500;

  const handleShow = () => {
    setShowImages(true);
  };

  const handleHide = () => {
    setShowImages(false);
  };

  const handleUpdateQty = (itemId, quantity) => {
    setLoading(true);
    handleUpdateCartQty(itemId, quantity, t, closeTime).then((result) => {
      if (result && refetch) refetch();
      setLoading(false);
    });
  };

  const handleRemove = () => {
    handleRemoveFromCart(item.id).then((result) => {
      if (result && refetch) refetch();
      else setLoading(false);
    });
    setDeleteBtn(<LoadingSpinner />);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  let image;
  let name;
  if (item.product) {
    if (
      item.product.images &&
      item.product.images.length > 0 &&
      item.product.images[0]
    ) {
      image = item.product.images[0].url;
    }
    name = item.product.title;
  }

  return (
    <>
      <div className="d-md-flex align-items-center h-100 m-3 border rounded">
        <div className="first-cart-step">
          <div className="text-start d-block d-sm-none">
            <ShareButtons
              position="relative"
              top={180}
              left={0}
              url={`${hostUrl}/${customerPath}/products/${item.product?.id}`}
              cardTitle={t("Share this Product")}
              title={item.product?.title}
            />
          </div>
          <div className="d-none d-sm-block">
            <ShareButtons
              position="absolut"
              url={`${hostUrl}/${customerPath}/products/${item.product?.id}`}
              cardTitle={t("Share this Product")}
              title={item.product?.title}
            />
          </div>
        </div>
        {image && (
          <Image
            className="rounded"
            style={{
              border: isHovered ? "1px solid #ced4da" : "0",
              transform: isHovered ? "scale(1.2)" : "scale(1)",
              transition: "all .2s ease-in-out",
              cursor: "pointer",
              height: 300,
            }}
            src={image}
            alt={name}
            onClick={handleShow}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        )}
        <div className="flex-grow-1 text-center">
          <h5 style={{ textShadow: "1px 1px 2px white" }}>{name}</h5>
          <div className="text-center">
            <ButtonGroup className="second-cart-step">
              <Button
                className="fw-bold"
                onClick={() =>
                  item.quantity > 1
                    ? handleUpdateQty(item.id, item.quantity - 1)
                    : handleRemove()
                }
              >
                -
              </Button>
              {loading ? (
                <LoadingSpinner />
              ) : (
                <p className="mx-3 my-auto fw-bold">{item.quantity}</p>
              )}
              <Button
                className="fw-bold"
                onClick={() => handleUpdateQty(item.id, item.quantity + 1)}
              >
                +
              </Button>
            </ButtonGroup>
          </div>
          <ProductPrice product={item.product} />
          <Button
            variant="danger"
            onClick={handleRemove}
            className="third-cart-step"
          >
            {deleteBtn}
          </Button>
        </div>
      </div>
      <ProductModal
        product={item.product}
        customer={customer}
        customerPath={customerPath}
        handleHide={handleHide}
        show={showImages}
      />
    </>
  );
};

export default CartItem;
