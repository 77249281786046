import { useEffect, useState } from "react";

export const Image = (props) => {
  const [source, setSource] = useState(props.src);
  const [hasError, sethasError] = useState(false);
  const handleError = () => {
    sethasError(true);
    setSource("/images/no-image-available.webp");
  };
  useEffect(() => {
    if (!hasError && props.src) {
      setSource(props.src);
    }
  }, [props.src, hasError]);
  return (
    <img
      {...props}
      src={source}
      title={props.title ?? props.alt ?? source}
      alt={props.alt ?? props.title ?? source}
      onError={handleError}
      width={props.width ?? "auto"}
      height={props.height ?? "auto"}
      loading={props.loading ?? "eager"}
    />
  );
};
