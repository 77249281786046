import { Rating } from "@mui/material";
import { Link } from "react-router-dom";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { UserData } from "helpers/ClientHelper";
import { useCallback, useEffect, useState } from "react";
import { LoadingSpinner } from "components/LoadingSpinner";
import { Card, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const AdminProfile = () => {
  const { data: user } = UserData();
  const { t } = useTranslation();
  const [content, setContent] = useState(<LoadingSpinner />);
  const [loaded, setLoaded] = useState(false);

  const loadContent = useCallback(() => {
    setContent(
      <Card className="my-5 w-50 mx-auto">
        <Card.Body className="text-start">
          <Card.Title>
            <Row>
              <Col>{t("User Profile")}</Col>
              <Col className="text-end">
                {user.data.validated === 1 ? (
                  <>
                    {t("Verified")}{" "}
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="mx-2 text-primary"
                    />
                  </>
                ) : (
                  <>
                    {t("Not Verified")}{" "}
                    <FontAwesomeIcon
                      icon={faCircleXmark}
                      className="mx-2 text-danger"
                    />
                    <Link to="/admin/verify" className="btn btn-primary">
                      {t("Verify")}
                    </Link>
                  </>
                )}
              </Col>
            </Row>
          </Card.Title>
          <hr />
          <Card.Text>
            <Row>
              <Col>
                <strong>{t("Rating")}:&nbsp;</strong>
                <span>
                  {Number(user.data.rating)}/5&nbsp;
                  <Rating
                    readOnly
                    defaultValue={user.data.rating}
                    size="large"
                  />
                </span>
                <br />
                <strong>{t("Visitors")}:&nbsp;</strong>{" "}
                {user?.data?.data?.visitors?.length ?? 0}
                <div className="py-3 mt-1"></div>
                <strong>{t("Name")}:&nbsp;</strong> {user.data.name}
                <br />
                <strong>{t("Email")}:&nbsp;</strong>
                {user.data.email}
              </Col>
              <Col className="text-end">
                <Link
                  className="btn btn-warning mb-3"
                  to="/admin/password/change"
                >
                  {t("Change password")}
                </Link>
                <br />
                <Link className="btn btn-success mb-3" to="/admin/complain">
                  {t("Complain")}
                </Link>
                <br />
                <Link className="btn btn-secondary" to="/admin/name">
                  {t("Change Name")}
                </Link>
              </Col>
            </Row>
          </Card.Text>
          <Container className="ms-3"></Container>
        </Card.Body>
      </Card>
    );
  }, [user, t]);

  useEffect(() => {
    if (!loaded && user && user.data && user.data.name) {
      setLoaded(true);
      loadContent();
    }
  }, [loaded, user, loadContent]);

  return <>{content}</>;
};
