import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import { Purchase, UserData } from "helpers/ClientHelper";
import { LoadingSpinner } from "components/LoadingSpinner";
import { AddressItem } from "components/address/AddressItem";

export const OrderShow = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [content, setContent] = useState(<LoadingSpinner />);
  const { data: order } = Purchase(id);
  const unitPrice = process.env.REACT_APP_UNIT_PRICE
    ? process.env.REACT_APP_UNIT_PRICE
    : "$";
  const { data: customer } = UserData();
  const customerPath = customer?.data?.name?.toLowerCase().replace(/ /g, "_");

  useEffect(() => {
    if (order) {
      let shipment = <></>;
      let products = [];
      if (order.shipment) {
        shipment = (
          <>
            <hr />
            <h5 className="text-center">{t("Shipment")}:</h5>
            <hr className="mx-auto w-50" />
            <p>
              <strong>{t("Status")}:</strong> {order.shipment.status} <br />
              {order.shipment.data?.message && (
                <>
                  <strong>{t("Message")}:</strong>{" "}
                  {order.shipment.data?.message}
                  <br />
                </>
              )}
              {order.shipment.data?.tracking && (
                <>
                  <strong>{t("Tracking")}:</strong>{" "}
                  <a
                    href={order.shipment.data?.tracking}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {order.shipment.data?.tracking}
                  </a>
                  <br />
                </>
              )}
            </p>
            <hr className="mx-auto w-50" />
            <h5 className="text-center m-3 mb-4">{t("Address")}:</h5>
            <AddressItem item={order.shipment.address} />
          </>
        );
      }
      if (order.cart?.data?.length) {
        for (const element of order.cart.data) {
          products.push(
            <div key={element.product.code}>
              <hr className="mx-auto w-50" />
              <p>
                <strong>{t("Code")}:</strong> {element.product.code} <br />
                <strong>{t("Title")}:</strong> {element.product.title} <br />
                <strong>{t("Price")}:</strong> {element.product.price_unit}
                {element.product.price} <br />
                <strong>{t("Quantity")}:</strong> {element.quantity} <br />
                <Link
                  className="btn btn-primary mx-auto"
                  to={`/${customerPath}/products/${element.product.id}`}
                >
                  {t("Show")}
                </Link>
              </p>
            </div>
          );
        }
      }
      setContent(
        <Row className="justify-content-center">
          <Col md="6">
            <div className="card">
              <div className="card-body m-3 text-start">
                <h4 className="text-center m-3 mb-4">{t("Order")}</h4>
                <div>
                  <strong>{t("Status")}: </strong>
                  {order.status ? order.status : "-"}
                </div>
                <div>
                  <strong>{t("User Name")}: </strong>
                  {order.user_name}
                </div>
                <div>
                  <strong>{t("User Email")}: </strong>
                  {order.user_email}
                </div>
                {shipment}
                {products && (
                  <>
                    <hr />
                    <h5 className="text-center">{t("Products")}:</h5>
                    {products}
                    <hr />
                  </>
                )}

                {order.cart?.total && (
                  <div className="text-center">
                    <strong>{t("Total")}: </strong>
                    {unitPrice}
                    {order.cart.total}
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      );
    }
  }, [id, t, order, customerPath, unitPrice]);
  return (
    <Container fluid className="mt-5 text-center">
      {content}
    </Container>
  );
};
